import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useQueryParams } from 'hooks/useQueryParams'
import { StoreItemsSummaryQuery } from 'queries/storeItems.gql'
import {
  List,
  Badge,
  Button,
  Colors,
  Text,
  Dropdown,
  Flex,
  Container,
  Sizes,
} from 'ui-kit'
import PageHeader from 'components/pageHeader/PageHeader'
import { STORE_ITEM_CATEGORIES } from 'common/constants/storeItemCategories'
import { STORE_ITEM_GENDERS } from 'common/constants/storeItemGenders'
import { generateUrl } from 'common/utils/generateUrl'

const Store = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { queryParams, setQueryParams } = useQueryParams()

  const category = queryParams.category
  const gender = queryParams.gender
  const queryVariables = {
    category,
    gender,
  }

  const { data, loading } = useQuery(StoreItemsSummaryQuery, { variables: queryVariables })
  const storeItems = data?.storeItemsSummary || []

  const handleChangeFilter = (param, value) => {
    setQueryParams({ [param]: value })
  }

  const headerActions = () => {
    return (
      [
        <Button
          color={Colors.PRIMARY}
          iconName="plus"
          key="add-store-item"
          label={<Text capitalize>{t('addNew')}</Text>}
          onClick={() => navigate('/admin/store-items/new')}
        />,
      ]
    )
  }

  const categoryOptions = () => {
    const renderedOptions = STORE_ITEM_CATEGORIES.map((itemCategory) => (
      {
        key: itemCategory,
        title: t(`storeItemCategory.${itemCategory}`),
        handler: () => handleChangeFilter('category', itemCategory),
      }
    ))

    renderedOptions.splice(
      0, 0,
      {
        key: 'emptyCategory',
        title: t('storeItemCategory.cancelCategory'),
        handler: () => handleChangeFilter('category', ''),
      },
    )

    return renderedOptions
  }

  const genderOptions = () => {
    const renderedOptions = STORE_ITEM_GENDERS.map((itemGender) => (
      {
        key: itemGender,
        title: t(`storeItemGender.${itemGender}`),
        handler: () => handleChangeFilter('gender', itemGender),
      }
    ))

    renderedOptions.splice(
      0, 0,
      {
        key: 'emptyCategory',
        title: t('storeItemGender.cancelGender'),
        handler: () => handleChangeFilter('gender', ''),
      },
    )

    return renderedOptions
  }

  const listData = () => (
    storeItems.map((item) => (
      <>
        <Link className="w-100" to={generateUrl('/admin/store-items', { gender, title: item.title })}>
          <Flex alignItems="center" justifyContent="between">
            <Text>{item.title}</Text>
            <Flex alignItems="center" justifyContent="end">
              <Badge className="me-2" color={Colors.SUCCESS}>{item.count}</Badge>
              <Badge color={Colors.SECONDARY}>{item.countPerRequest}</Badge>
            </Flex>
          </Flex>
        </Link>
      </>
    ))
  )

  const categoryFilterTitle = category ? t(`storeItemCategory.${category}`) : t('category')
  const genderFilterTitle = gender ? t(`storeItemGender.${gender}`) : t('gender')

  return (
    <>
      <PageHeader actions={headerActions()} label={t('store')} />
      <Flex className="mb-4">
        <Dropdown className="me-2" options={categoryOptions()}>
          <Button
            color={Colors.PRIMARY}
            iconName="adjustments"
            label={<Text capitalize>{categoryFilterTitle}</Text>}
            outline
          />
        </Dropdown>
        <Dropdown className="me-2" options={genderOptions()}>
          <Button
            color={Colors.PRIMARY}
            iconName="adjustments"
            label={<Text capitalize>{genderFilterTitle}</Text>}
            outline
          />
        </Dropdown>
      </Flex>
      <Container bgColor={Colors.INFO} padding={Sizes.NORMAL} formContainer withBorder>
        <Text>{t('hint.storeItemsCounts')}</Text>
      </Container>
      <List data={listData()} loading={loading} />
    </>
  )
}

export default Store
