/* eslint-disable max-len */
import React from 'react'
import PT from 'prop-types'

const FacebookIcon = ({ className, style }) => (
  <svg
    className={className}
    fill="currentColor"
    height="48"
    style={style}
    version="1.1"
    viewBox="-1 -1 50 50"
    width="48"
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <path
      clipRule="evenodd"
      d="M0 24.1506C0 36.0826 8.666 46.0046 20 48.0166V30.6826H14V24.0166H20V18.6826C20 12.6826 23.866 9.3506 29.334 9.3506C31.066 9.3506 32.934 9.6166 34.666 9.8826V16.0166H31.6C28.666 16.0166 28 17.4826 28 19.3506V24.0166H34.4L33.334 30.6826H28V48.0166C39.334 46.0046 48 36.0846 48 24.1506C48 10.8766 37.2 0.0166016 24 0.0166016C10.8 0.0166016 0 10.8766 0 24.1506Z"
      fillRule="evenodd"
    />
  </svg>
)

FacebookIcon.propTypes = {
  className: PT.string,
  style: PT.shape({}),
}

FacebookIcon.defaultProps = {
  className: '',
  style: {},
}

export default FacebookIcon
