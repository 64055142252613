import appConfig from 'configs/appConfig'

export const PAYPAL_EMAIL = 'Alexandra.ask@gmail.com'
export const UAH_IBAN = 'UA183220010000026000080000809'
export const USD_IBAN = 'UA883220010000026000080000810'
export const EUR_IBAN = 'UA443220010000026009080000811'
export const ANY_CARD_LINK = appConfig.ANY_CARD_DONATE

export const monthlyDonates = (t) => (
  [
    {
      image: 'https://assets.armiyavolonteriv.com/production/monthly_donates/1.png',
      link: appConfig.MONTHLY_100_DONATE,
      title: t('landing.donatePage.monthly.1.title'),
      buttonLabel: t('landing.donatePage.monthly.1.buttonLabel'),
    },
    {
      image: 'https://assets.armiyavolonteriv.com/production/monthly_donates/2.png',
      link: appConfig.MONTHLY_250_DONATE,
      title: t('landing.donatePage.monthly.2.title'),
      buttonLabel: t('landing.donatePage.monthly.2.buttonLabel'),
    },
    {
      image: 'https://assets.armiyavolonteriv.com/production/monthly_donates/3.png',
      link: appConfig.MONTHLY_1000_DONATE,
      title: t('landing.donatePage.monthly.3.title'),
      buttonLabel: t('landing.donatePage.monthly.3.buttonLabel'),
    },
    {
      image: 'https://assets.armiyavolonteriv.com/production/monthly_donates/4.png',
      link: appConfig.MONTHLY_OTHER_DONATE,
      title: t('landing.donatePage.monthly.4.title'),
      buttonLabel: t('landing.donatePage.monthly.4.buttonLabel'),
    },
  ]
)
