import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import { Flex, Text, LoadingSpinner } from 'ui-kit'
import PageHeader from 'components/pageHeader/PageHeader'
import StoreItemForm from './components/StoreItemForm'
import { useToaster } from 'hooks/useToaster'
import { generateUrl } from 'common/utils/generateUrl'
import { StoreItemQuery, UpdateStoreItemMutation } from 'queries/storeItems.gql'

const StoreItemEdit = () => {
  const { t } = useTranslation()
  const { showToaster, toasterTypes } = useToaster()
  const navigate = useNavigate()
  const { storeItemId } = useParams()

  const { data, loading } = useQuery(StoreItemQuery, {
    variables: { id: storeItemId },
  })

  const [updateStoreItem, { loading: isSubmitting }] = useMutation(UpdateStoreItemMutation, {
    onCompleted: (data) => {
      if (data.updateStoreItem.entity) {
        navigate(generateUrl('/admin/store-items', { title: data.updateStoreItem.entity.title }))
        showToaster({
          content: <Text>{t('storeItem.storeItemSuccessfullyUpdated')}</Text>,
          type: toasterTypes.SUCCESS,
        })
      } else if (data.updateStoreItem.errors?.length) {
        showToaster({
          content: (<Flex column>
            {data.updateStoreItem.errors.map(({ message }) => <Text key={message}>{message}</Text>)}
          </Flex>),
          type: toasterTypes.DANGER,
        })
      }
    },
  })

  const storeItem = data?.storeItem || {}

  const handleSubmit = async (values) => {
    const variables = {
      id: storeItemId,
      data: {
        title: values.title,
        category: values.category.value,
        gender: values.gender.value,
        description: values.description,
        priceCents: parseFloat(values.priceCents) * 100,
      },
    }
    await updateStoreItem({ variables })
  }

  if (loading) return <LoadingSpinner marginTop />

  return (
    <>
      <PageHeader label={t('storeItem.updateStoreItem')} />
      <StoreItemForm
        isLoading={loading}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
        storeItem={storeItem}
      />
    </>
  )
}

export default StoreItemEdit
