import React from 'react'
import PT from 'prop-types'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Icon,
  Colors,
  Sizes,
  Flex,
  Button,
  Dropdown,
  Text,
} from 'ui-kit'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { applyUserSignOut } from 'store/actions/auth'
import styles from './Header.module.scss'

const Header = ({ onSidebarToggle }) => {
  const { t, i18n } = useTranslation()
  const { currentUser } = useCurrentUser()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSignOut = () => {
    dispatch(applyUserSignOut())
    navigate('/admin/login')
  }

  const handleLanguageChange = async (lang) => {
    await i18n.changeLanguage(lang)
    await localStorage.setItem('lang', lang)
  }

  const renderLangDropdown = () => {
    const options = [
      {
        key: 'ua',
        title: 'UA',
        handler: () => handleLanguageChange('ua'),
      },
      {
        key: 'en',
        title: 'EN',
        handler: () => handleLanguageChange('en'),
      },
    ]

    return (
      <Dropdown className="me-2" options={options}>
        <Button color={Colors.PRIMARY} label={<Text uppercase>{i18n.language}</Text>} />
      </Dropdown>
    )
  }

  const renderUserMobileDropdown = () => {
    const options = [
      {
        key: 'profile',
        title: t('userProfile'),
        handler: () => navigate(`admin/users/${currentUser.id}/edit`),
        icon: 'user',
      },
      {
        key: 'signOut',
        title: t('signOut'),
        handler: () => handleSignOut(),
        icon: 'logout',
      },
    ]

    return (
      <Dropdown options={options}>
        <Button
          className={styles.dropdownButton}
          color={Colors.PRIMARY}
          iconName="chevronDown"
          label={`${currentUser.firstName} ${currentUser.lastName}`}
          outline
        />
      </Dropdown>
    )
  }

  return (
    <div className={styles.header}>
      <Icon
        className={styles.navMenu}
        color={Colors.PRIMARY}
        name="menu"
        onClick={onSidebarToggle}
        size={Sizes.LARGE}
      />
      <Flex alignItems="center" className="ms-auto">
        {renderLangDropdown()}
        <div className={styles.userDesktopInfo}>
          <Text className="me-4" color={Colors.PRIMARY}>{`${currentUser.firstName} ${currentUser.lastName}`}</Text>
          <Button
            color={Colors.PRIMARY}
            iconName="logout"
            label={<Text capitalize>{t('signOut')}</Text>}
            onClick={handleSignOut}
            outline
          />
        </div>
        <div className={styles.userMobileInfo}>
          {renderUserMobileDropdown()}
        </div>
      </Flex>
    </div>
  )
}

Header.propTypes = {
  onSidebarToggle: PT.func.isRequired,
}

export default Header
