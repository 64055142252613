import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import Tab from './Tab'
import styles from './Tabs.module.scss'

const Tabs = ({ data, className }) => {
  const classes = cx(styles.tabs, 'nav-fill', className)

  return (
    <ul className={classes}>
      {data.map(item => <Tab key={item.label} {...item} />)}
    </ul>
  )
}

Tabs.propTypes = {
  data: PT.arrayOf(PT.shape({
    label: PT.string.isRequired,
    disabled: PT.bool,
    handler: PT.func,
    active: PT.bool,
  })).isRequired,
  className: PT.string,
}

Tabs.defaultProps = {
  className: '',
}

export default Tabs
