import React from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Flex,
  Colors,
  Text,
} from 'ui-kit'

const InfoListItem = ({ name }) => {
  const { t } = useTranslation()

  return (
    <Flex className="mb-2" style={{ borderBottom: '2px solid aliceblue' }} column>
      <Text color={Colors.LANDING_GREY}>{t(`landing.donatePage.swiftInfo.titles.${name}`)}</Text>
      <Text landingText>{t(`landing.donatePage.swiftInfo.text.${name}`)}</Text>
    </Flex>
  )
}

InfoListItem.propTypes = {
  name: PT.string.isRequired,
}

export default InfoListItem
