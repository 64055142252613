import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import Icon from '../icons/Icon'
import { Colors } from '../../colors'
import { Sizes } from '../../sizes'
import styles from './LoadingSpinner.module.scss'

const LoadingSpinner = ({ size, color, className, marginTop, style }) => {
  const classes = cx(
    styles.loadingSpinner,
    {
      [styles.marginTop]: marginTop,
    },
    className,
  )

  return (
    <div className={classes} style={style}>
      <Icon color={color} name="loading" size={size} noBorder />
    </div>
  )
}

LoadingSpinner.propTypes = {
  className: PT.string,
  color: PT.oneOf([
    Colors.SUCCESS,
    Colors.WARNING,
    Colors.DANGER,
    Colors.DARK,
  ]),
  size: PT.oneOf([Sizes.XSMALL, Sizes.SMALL, Sizes.NORMAL, Sizes.LARGE]),
  marginTop: PT.bool,
  style: PT.shape({}),
}

LoadingSpinner.defaultProps = {
  className: '',
  color: Colors.DARK,
  size: Sizes.LARGE,
  marginTop: false,
  style: {},
}

export default LoadingSpinner
