import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import Select from 'react-select'
import InputWrapper from '../InputWrapper'
// import Icon from '../../icon/Icon'
// import Colors from '../../../colors'
import styles from './SelectInput.module.scss'

const SelectInput = ({
  id,
  className,
  placeholder,
  // rightIcon,
  name,
  options,
  onChange,
  error,
  required,
  disabled,
  label,
  value,
  noWrapper,
  transparent,
  fullwidth,
}) => {
  const classes = cx(styles.selectInput, className, {
    [styles.error]: error,
    [styles.disabled]: disabled,
    [styles.transparent]: transparent,
    [styles.fullwidth]: fullwidth,
  })

  // const DropdownIndicator = (props) => {
  //   return (
  //     <components.DropdownIndicator {...props}>
  //       <Icon color={Colors.DARK} name={rightIcon} size={Sizes.SMALL} variant="outline" noBorder />
  //     </components.DropdownIndicator>
  //   )
  // }

  const renderSelect = () => (
    <Select
      className={classes}
      // components={{
      //   DropdownIndicator,
      // }}
      defaultValue={value}
      isDisabled={disabled}
      name={name}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      value={value}
    />
  )

  if (noWrapper) {
    return (
      renderSelect()
    )
  }

  return (
    <InputWrapper
      error={error}
      id={id}
      label={label}
      required={required}
    >
      {renderSelect()}
    </InputWrapper>
  )
}

SelectInput.propTypes = {
  id: PT.string.isRequired,
  className: PT.string,
  placeholder: PT.string,
  options: PT.arrayOf(PT.shape({
    label: PT.oneOfType([PT.string, PT.element]),
    value: PT.oneOfType([PT.string, PT.number]),
  })),
  value: PT.shape({
    label: PT.oneOfType([PT.string, PT.element]),
    value: PT.oneOfType([PT.string, PT.number]),
  }),
  name: PT.string,
  onChange: PT.func.isRequired,
  error: PT.string,
  rightIcon: PT.string,
  required: PT.bool,
  label: PT.string,
  disabled: PT.bool,
  noWrapper: PT.bool,
  transparent: PT.bool,
  fullwidth: PT.bool,
}

SelectInput.defaultProps = {
  className: '',
  placeholder: '',
  options: [],
  value: null,
  name: '',
  error: '',
  rightIcon: 'chevronDown',
  required: false,
  label: '',
  disabled: false,
  noWrapper: false,
  transparent: false,
  fullwidth: false,
}

export default SelectInput
