import React from 'react'
import PT from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Icon,
  Flex,
  Text,
  Colors,
  Sizes,
} from 'ui-kit'

import styles from './Navigation.module.scss'

const NavItemDesktop = ({ navList }) => {

  const navColor = (isActive) => isActive ? Colors.WHITE : Colors.INFO

  return (
    <ul className={styles.navDesktop}>
      {navList.map(({ icon, label, link, isActive }) => (
        <li className={styles.navDesktopItem} key={label}>
          <Link to={link}>
            <Flex alignItems="center" column>
              <Icon color={navColor(isActive)} name={icon} size={Sizes.NORMAL}/>
              <Text
                align="center"
                className={styles.navDesktopLabel}
                color={navColor(isActive)}
                capitalize>{label}</Text>
            </Flex>
          </Link>
        </li>
          ),
          )}
    </ul>
  )
}

NavItemDesktop.propTypes = {
  navList: PT.array,
}

export default NavItemDesktop
