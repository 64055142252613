/* eslint-disable max-len */
import React from 'react'
import PT from 'prop-types'

const LoadingIcon = ({ className, style }) => (
  <svg
    className={className}
    fill="currentColor"
    id="L9"
    style={style}
    version="1.1"
    viewBox="20 20 60 60"
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <path
      d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
      stroke="currentColor"
      strokeWidth="1px"
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        dur="1s"
        from="0 50 50"
        repeatCount="indefinite"
        to="360 50 50"
        type="rotate"
      />
    </path>
  </svg>
)

LoadingIcon.propTypes = {
  className: PT.string,
  style: PT.shape({}),
}

LoadingIcon.defaultProps = {
  className: '',
  style: {},
}

export default LoadingIcon
