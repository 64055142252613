import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import styles from './TwoColContainer.module.scss'

const TwoColContainer = ({ children, className, style }) => {
  const classNames = cx(styles.twoColContainer, className)

  return (
    <div className={classNames} style={style}>
      {children[0]}
      {children[1]}
    </div>
  )
}

TwoColContainer.propTypes = {
  children: PT.arrayOf(PT.element).isRequired,
  className: PT.string,
  style: PT.shape({}),
}

TwoColContainer.defaultProps = {
  className: '',
  style: {},
}

export default TwoColContainer
