import React, { useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { applyUserSignIn } from 'store/actions/auth'
import {
  Container,
  TextInput,
  Button,
  Margin,
  Sizes,
  Colors,
} from 'ui-kit'

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = async () => {
    try {
      setError('')
      const { authToken, user } = await axios
        .post('/login', { email, password })
        .catch()
      dispatch(applyUserSignIn(authToken, user))
      navigate('/admin')
    } catch (error) {
      if (error?.response?.status === 422 || error?.response?.status === 401) {
        setError('Не правильний логін або пароль')
      }
    }
  }

  return (
    <Container className="col-12 col-md-8 col-lg-6 mx-auto">
      <Margin top={Sizes.NORMAL}>
        <TextInput
          error={error}
          id="email"
          label="Імейл"
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          placeholder="введіть імейл"
          value={email}
          required
        />
      </Margin>

      <Margin top={Sizes.NORMAL}>
        <TextInput
          error={error}
          id="password"
          label="Пароль"
          name="password"
          onChange={({ target }) => setPassword(target.value)}
          placeholder="введіть пароль"
          type="password"
          value={password}
          required
        />
      </Margin>

      <Margin className="d-flex" top={Sizes.LARGE}>
        <Button color={Colors.PRIMARY} label="Увійти" onClick={handleSubmit} fullwidth />
      </Margin>
    </Container>
  )
}

export default Login
