/* eslint-disable max-len, react/no-unknown-property */
import React from 'react'
import PT from 'prop-types'

const UAflagIcon = ({ className, style }) => (
  <svg
    className={className}
    style={style}
    version="1.1"
    viewBox="0 0 1200 800"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="#005BBB" height="800" width="1200"/>
    <rect fill="#FFD500" height="400" width="1200" y="400"/>
  </svg>
)

UAflagIcon.propTypes = {
  className: PT.string,
  style: PT.shape({}),
}

UAflagIcon.defaultProps = {
  className: '',
  style: {},
}

export default UAflagIcon
