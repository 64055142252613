import React, { isValidElement } from 'react'
import PT from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Icon, Sizes, Colors, Flex } from 'ui-kit'
import styles from './PageHeader.module.scss'

const PageHeader = ({ label, actions, backTo }) => {
  const navigate = useNavigate()

  const renderLabel = () => {
    if (isValidElement(label)) {
      return label
    }

    return (
      <h3 className="text-capitalize">{label}</h3>
    )
  }
  return (
    <div className={styles.pageHeader}>
      <Flex alignItems="center" className="me-auto my-3">
        {backTo && (
          <Icon
            className={styles.backTo}
            color={Colors.PRIMARY}
            name="arrowCircleLeft"
            onClick={() => navigate(backTo)}
            size={Sizes.XLARGE}
          />
        )}
        {renderLabel()}
      </Flex>
      <div className={styles.actions}>
        {actions.map(action => action)}
      </div>
    </div>
  )
}

PageHeader.propTypes = {
  label: PT.oneOfType([PT.string, PT.element]).isRequired,
  actions: PT.arrayOf(PT.element),
  backTo: PT.oneOfType([PT.string, PT.number]),
}

PageHeader.defaultProps = {
  actions: [],
  backTo: -1,
}

export default PageHeader
