import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import {
  Flex,
  Icon,
  Colors,
  Sizes,
} from 'ui-kit'
import styles from './DonateCopyButton.module.scss'

const DonateCopyButton = ({ children, onClick, leftIcon, leftImage, className, linkTo }) => {
  const renderLeftIcon = () => {
    if (leftIcon) {
      return (
        <Icon className={styles.leftIcon} name={leftIcon} />
      )
    } else if (leftImage) {
      return (
        <img
          alt={leftImage}
          className={styles.leftIcon}
          src={`https://assets.armiyavolonteriv.com/production/icons/${leftImage}`}
        />
      )
    } else {
      return null
    }
  }

  const rightIconName = () => linkTo ? 'externalLink' : 'duplicate'

  const renderContent = () => (
    <Flex alignItems="center" gap="1rem" justifyContent="between">
      {renderLeftIcon()}
      <Flex alignItems="start" className={styles.content} grow={1} column>
        {children}
      </Flex>
      <Icon className={styles.rightIcon} color={Colors.DARK} name={rightIconName()} size={Sizes.XLARGE} />
    </Flex>
  )

  if (linkTo) {
    return (
      <a className={cx(styles.donateCopyButton, className)} href={linkTo} rel="noreferrer" target="_blank">
        {renderContent()}
      </a>
    )
  }

  return (
    <button className={cx(styles.donateCopyButton, className)} onClick={onClick}>
      {renderContent()}
    </button>
  )
}

DonateCopyButton.propTypes = {
  children: PT.node.isRequired,
  onClick: PT.func,
  leftIcon: PT.string,
  leftImage: PT.string,
  linkTo: PT.string,
  className: PT.string,
}

DonateCopyButton.defaultProps = {
  className: '',
  leftIcon: '',
  leftImage: '',
  linkTo: null,
  onClick: null,
}

export default DonateCopyButton
