export const NONE = 'none'
export const MICRO = 'micro'
export const XSMALL = 'xsmall'
export const XXSMALL = 'xxsmall'
export const SMALL = 'small'
export const NORMAL = 'normal'
export const LARGE = 'large'
export const XLARGE = 'xlarge'
export const XXLARGE = 'xxlarge'
export const XXXLARGE = 'xxxlarge'
export const SECONDARY = 'secondary'
export const DESCRIPTION = 'description'

export const Sizes = {
  NONE,
  MICRO,
  XXSMALL,
  XSMALL,
  SMALL,
  NORMAL,
  LARGE,
  XLARGE,
  XXLARGE,
  XXXLARGE,
  DESCRIPTION,
  SECONDARY,
}

export default Sizes
