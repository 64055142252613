import React from 'react'
import { useFormik } from 'formik'
import { useMutation } from '@apollo/client'
import { pickBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  Container,
  Checkbox,
  Datepicker,
  Text,
  Sizes,
  Button,
} from 'ui-kit'
import { validateRequiredField, validateOneOfRequiredFields } from 'common/utils/validators'
import { ExportNewRequestsMutation } from 'queries/export.gql'
import { downloadCSV } from 'common/utils/downloadFile'
import { generateNewRequestsExportName } from '../utils/generateFileName'

const ExportRequestsForm = () => {
  const { t } = useTranslation()

  const [generate, { loading }] = useMutation(ExportNewRequestsMutation, {
    onCompleted: (data) => {
      const csvData = data.exportNewRequests.entity
      const name = generateNewRequestsExportName(formik.values)
      downloadCSV(csvData, name)
    },
  })

  const validate = (values) => {
    const errors = {}
    errors.needCheck = validateOneOfRequiredFields(values.needCheck, values.initial, t)
    errors.initial = validateOneOfRequiredFields(values.needCheck, values.initial, t)
    errors.startDate = validateRequiredField(values.startDate, t)
    errors.endDate = validateRequiredField(values.endDate, t)

    return pickBy(errors)
  }

  const handleSubmit = async (values) => {
    let statuses = []
    if (values.initial) {
      statuses = [...statuses, 'initial']
    }
    if (values.needCheck) {
      statuses = [...statuses, 'need_check']
    }
    const validValues = {
      statuses,
      startDate: values.startDate.toString(),
      endDate: values.endDate.toString(),
    }
    const variables = {
      filters: validValues,
    }
    await generate({ variables })
  }

  const formik = useFormik({
    initialValues: {
      needCheck: false,
      initial: false,
      startDate: null,
      endDate: null,
    },
    validate,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  })

  const renderError = (name) => (formik.touched[name] && formik.errors[name])

  return (
    <Container formContainer>
      <Checkbox
        checked={formik.values.initial}
        error={renderError('initial')}
        id="initial"
        label={t('exportPage.formLabels.initial')}
        labelWrapper={t('exportPage.formLabels.statusesWrapper')}
        name="initial"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
      />
      <Checkbox
        checked={formik.values.needCheck}
        error={renderError('needCheck')}
        id="needCheck"
        label={t('exportPage.formLabels.needCheck')}
        name="needCheck"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
      />
      <Datepicker
        dateFormat="P p"
        error={renderError('startDate')}
        id="startDate"
        label={t('exportPage.formLabels.startDate')}
        maxDate={new Date()}
        name="startDate"
        onBlur={formik.handleBlur}
        onChange={(value) => formik.setFieldValue('startDate', value)}
        placeholder={t('chooseDate')}
        placement="bottom-end"
        rightIcon="calendar"
        value={formik.values.startDate}
        required
        showMonthDropdown
        showTime
        showYearDropdown
      />
      <Datepicker
        dateFormat="P p"
        error={renderError('endDate')}
        id="endDate"
        label={t('exportPage.formLabels.endDate')}
        maxDate={new Date()}
        name="endDate"
        onBlur={formik.handleBlur}
        onChange={(value) => formik.setFieldValue('endDate', value)}
        placeholder={t('chooseDate')}
        placement="bottom-end"
        rightIcon="calendar"
        value={formik.values.endDate}
        required
        showMonthDropdown
        showTime
        showYearDropdown
      />
      <Button
        label={<Text capitalize>{t('export')}</Text>}
        loading={loading}
        onClick={formik.handleSubmit}
        size={Sizes.LARGE}
        fullwidth
      />
    </Container>
  )
}

export default ExportRequestsForm
