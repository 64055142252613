import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import LinkButton from '../components/LinkButton'
import {
  Section,
  Text,
  Colors,
  Flex,
} from 'ui-kit'
import styles from './HelpMessage.module.scss'

const HelpMessage = () => {
  const { t } = useTranslation()

  return (
    <Section className={styles.helpMessage} id="help-us-message" noContainer>
      <Flex
        alignItems="center"
        className={cx('container', styles.helpMessageContainer)}
        gap="2rem"
        justifyContent="between"
      >
        <Text color={Colors.WHITE} landingText>{t('landing.sectionsText.helpMessage')}</Text>
        <LinkButton className={styles.action} label={t('landing.donateButton')} to="/donate" />
      </Flex>
    </Section>
  )
}

export default HelpMessage
