import { DateTime } from 'luxon'

const formatTime = date => DateTime.fromJSDate(date).toFormat('dd-LL-yyyy')

export const generateNewRequestsExportName = (values) => {
  let status = ''
  if (values.initial && values.needCheck) {
    status = 'new_need_check'
  } else if (values.needCheck) {
    status = 'need_check'
  } else {
    status = 'new'
  }
  const fromDate = formatTime(values.startDate)
  const toDate = formatTime(values.endDate)
  return `${status}_requests_${fromDate}_${toDate}`
}
