import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import styles from './Container.module.scss'
import { Sizes } from '../../../sizes'
import Colors from '../../../colors'

const Container = ({
  children,
  className,
  style,
  withBorder,
  withShadow,
  padding,
  formContainer,
  donateContainer,
  bgColor,
}) => {
  return (
    <div className={cx(styles.container, {
      [styles.withShadow]: withShadow,
      [styles.withBorder]: withBorder,
      [styles.formContainer]: formContainer,
      [styles.donateContainer]: donateContainer,
      [styles.paddingXLarge]: padding === Sizes.XLARGE,
      [styles.paddingLarge]: padding === Sizes.LARGE,
      [styles.paddingNormal]: padding === Sizes.NORMAL,
      [`text-bg-${bgColor}`]: !!bgColor,
    }, className)}
      style={style}>
      {children}
    </div>
  )
}

Container.propTypes = {
  children: PT.node.isRequired,
  padding: PT.oneOf([
    Sizes.XLARGE,
    Sizes.LARGE,
    Sizes.NORMAL,
  ]),
  withBorder: PT.bool,
  withShadow: PT.bool,
  formContainer: PT.bool,
  donateContainer: PT.bool,
  bgColor: PT.oneOf([...Object.values(Colors)]),
  className: PT.string,
  style: PT.shape({}),
}

Container.defaultProps = {
  padding: Sizes.LARGE,
  withBorder: false,
  withShadow: true,
  formContainer: false,
  donateContainer: false,
  bgColor: Colors.WHITE,
  className: '',
}

export default Container
