import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import cx from 'classnames'
import { isEmpty } from 'lodash'
import { useQueryParams } from 'hooks/useQueryParams'
import { useToaster } from 'hooks/useToaster'
import {
  StoreItemsQuery,
  ArchiveStoreItemMutation,
  RestoreStoreItemMutation,
  BulkArchiveStoreItemsMutation,
} from 'queries/storeItems.gql'
import { getPaginationData } from 'common/utils/getPaginationData'
import {
  Button,
  Text,
  Table,
  Pagination,
  Colors,
  Dropdown,
  Flex,
  Icon,
  Sizes,
  ConfirmModal,
  Badge,
} from 'ui-kit'
import PageHeader from 'components/pageHeader/PageHeader'
import { STORE_ITEM_GENDERS } from 'common/constants/storeItemGenders'
import { generateUrl } from 'common/utils/generateUrl'

const StoreItems = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { queryParams, setQueryParams } = useQueryParams()
  const { showToaster, toasterTypes } = useToaster()

  const category = queryParams.category
  const gender = queryParams.gender
  const title = queryParams.title
  const page = parseInt(queryParams.page) || 1
  const queryVariables = {
    category,
    gender,
    title,
    page,
  }

  const { data, loading } = useQuery(StoreItemsQuery, { variables: queryVariables })
  const [archive] = useMutation(ArchiveStoreItemMutation, {
    refetchQueries: [StoreItemsQuery],
  })
  const [bulkArchive] = useMutation(BulkArchiveStoreItemsMutation)
  const [restore] = useMutation(RestoreStoreItemMutation, {
    refetchQueries: [StoreItemsQuery],
  })

  const storeItems = data?.storeItems?.data || []
  const paginationData = getPaginationData(data, 'storeItems')
  const metaData = data ? JSON.parse(data.storeItems.meta) : {}

  const handleChangeFilter = (param, value) => {
    setQueryParams({ [param]: value })
  }

  const handleArchive = async (id) => {
    const variables = { id }
    await archive({ variables })
    showToaster({
      content: <Text>{t('storeItem.storeItemSuccessfullyArchived')}</Text>,
      type: toasterTypes.SUCCESS,
      action: {
        onClick: () => { handleRestore(id) },
        text: t('undo'),
      },
    })
  }

  const handleBulkArchive = async () => {
    const variables = { title }
    await bulkArchive({ variables })
    showToaster({
      content: <Text>{t('storeItem.storeItemsSuccessfullyArchived')}</Text>,
      type: toasterTypes.SUCCESS,
    })
    navigate('/admin/store')
  }

  const handleRestore = async (id) => {
    const variables = { id }
    await restore({ variables })
    showToaster({
      content: <Text>{t('storeItem.storeItemSuccessfullyRestored')}</Text>,
      type: toasterTypes.SUCCESS,
      action: {
        onClick: () => { handleArchive(id) },
        text: t('undo'),
      },
    })
  }

  const handleNewClick = () => {
    navigate(
      generateUrl(
        '/admin/store-items/new',
        {
          title: storeItems[0]?.title,
          category: storeItems[0]?.category,
          gender: storeItems[0]?.gender,
        },
      ),
    )
  }

  const headerActions = () => {
    return (
      [
        <ConfirmModal
          buttonLabel={t('delete')}
          key="bulk-delete-store-items"
          onConfirm={handleBulkArchive}
          title={t('confirmModal.deleteStoreItems')}
        >
          {(handleClick) => (
            <Button
              className="me-2"
              color={Colors.DANGER}
              iconName="trash"
              label={t('bulkArchive')}
              onClick={handleClick}
            />
          )}
        </ConfirmModal>,
        <Button
          color={Colors.PRIMARY}
          iconName="plus"
          key="create-store-item"
          label={<Text capitalize>{t('addNew')}</Text>}
          onClick={handleNewClick}
        />,
      ]
    )
  }

  const decorateTitles = () => (
    [
      { name: 'id', label: <Text uppercase>{t('id')}</Text> },
      { name: 'title', label: <Text capitalize>{t('title')}</Text> },
      { name: 'category', label: <Text capitalize>{t('category')}</Text> },
      { name: 'gender', label: <Text capitalize>{t('gender')}</Text> },
      { name: 'price', label: <Text capitalize>{t('price')}</Text> },
      { name: 'description', label: <Text capitalize>{t('description')}</Text> },
      { name: 'requestId', label: <Text capitalize>{t('requestId')}</Text> },
      { name: 'actions', label: '' },
    ]
  )

  const mutedTextForBooked = (text, requestId) => {
    if (requestId) {
      return (
        <Text color={Colors.SECONDARY}>{text || '-'}</Text>
      )
    } else {
      return text || '-'
    }
  }

  const linkToRequest = (requestId) => {
    if (requestId) {
      return (
        <Link to={`/admin/requests/${requestId}`}>
          {requestId}
        </Link>
      )
    } else {
      return null
    }
  }

  const decorateData = () => (
    storeItems.map((item) => (
      {
        id: item.id,
        cells: [
          { name: 'id', content: mutedTextForBooked(item.id, item.requestId) },
          { name: 'title', content: mutedTextForBooked(item.title, item.requestId) },
          { name: 'category', content: mutedTextForBooked(t(`storeItemCategory.${item.category}`), item.requestId) },
          { name: 'gender', content: mutedTextForBooked(t(`storeItemGender.${item.gender}`), item.requestId) },
          { name: 'price', content: mutedTextForBooked(item.humanizedPrice, item.requestId) },
          { name: 'description', content: mutedTextForBooked(item.description, item.requestId), width: '23rem' },
          { name: 'request_id', content: linkToRequest(item.requestId) },
          { name: 'actions', content: renderActions(item.id) },
        ],
      }
    ))
  )

  const renderActions = (id) => {
    return (
      <>
        <Flex className={cx('w-100', 'mobileActions')}>
          <Button
            className="me-1"
            color={Colors.WARNING}
            iconName="pencil"
            label={t('edit')}
            onClick={() => navigate(`${id}/edit`)}
            fullwidth
          />
          <ConfirmModal
            buttonLabel={t('delete')}
            onConfirm={() => handleArchive(id)}
            title={t('confirmModal.deleteStoreItem')}
          >
            {(handleClick) => (
              <Button
                className="ms-1"
                color={Colors.DANGER}
                iconName="trash"
                label={t('delete')}
                onClick={handleClick}
                fullwidth
              />
            )}
          </ConfirmModal>

        </Flex>
        <Flex className={cx('desktopActions')}>
          <Icon
            className="me-4"
            color={Colors.WARNING}
            name="pencil"
            onClick={() => navigate(`${id}/edit`)}
            size={Sizes.LARGE}
          />
          <ConfirmModal
            buttonLabel={t('delete')}
            onConfirm={() => handleArchive(id)}
            title={t('confirmModal.deleteStoreItem')}
          >
            {(handleClick) => (
              <Icon
                color={Colors.DANGER}
                name="trash"
                onClick={handleClick}
                size={Sizes.LARGE}
              />
          )}
          </ConfirmModal>
        </Flex>
      </>
    )
  }

  const genderOptions = () => {
    const renderedOptions = STORE_ITEM_GENDERS.map((itemGender) => (
      {
        key: itemGender,
        title: t(`storeItemGender.${itemGender}`),
        handler: () => handleChangeFilter('gender', itemGender),
      }
    ))

    renderedOptions.splice(
      0, 0,
      {
        key: 'emptyCategory',
        title: t('storeItemGender.cancelGender'),
        handler: () => handleChangeFilter('gender', ''),
      },
    )

    return renderedOptions
  }

  const genderFilterTitle = gender ? t(`storeItemGender.${gender}`) : t('gender')

  const headerTitle = () => {
    const headerTitle = <h3 className="text-capitalize mb-0">{title}</h3>
    if (isEmpty(metaData)) return headerTitle

    return(
      <Flex alignItems="center">
        {headerTitle}
        <Badge className="me-2 ms-2" color={Colors.SUCCESS}>{metaData.free_count}</Badge>
        <Badge color={Colors.SECONDARY}>{metaData.used_count}</Badge>
      </Flex>
    )
  }

  return (
    <>
      <PageHeader actions={headerActions()} label={headerTitle()} />
      <Flex className="mb-4">
        <Dropdown className="me-2" options={genderOptions()}>
          <Button
            color={Colors.PRIMARY}
            iconName="adjustments"
            label={<Text capitalize>{genderFilterTitle}</Text>}
            outline
          />
        </Dropdown>
      </Flex>
      <Table data={decorateData()} loading={loading} titles={decorateTitles()} />
      <Pagination
        {...paginationData}
        loading={loading}
        withResults
      />
    </>
  )
}

export default StoreItems
