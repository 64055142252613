import { get } from 'lodash'

export const getPaginationData = (data, collectionKey) => {
	const totalCount = get(data, `${collectionKey}.paginationData.totalCount`, 0)
	const totalPages = get(data, `${collectionKey}.paginationData.totalPages`, 0)
	const from = get(data, `${collectionKey}.paginationData.from`, 0)
	const to = get(data, `${collectionKey}.paginationData.to`, 0)

	return { totalCount, totalPages, from, to }
}
