import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import Colors from '../../colors'
import styles from './Badge.module.scss'

const Badge = ({ children, className, color, pill }) => {
  const classes = cx(styles.badge, 'my-auto', className, {
    [styles[`text-bg-${color}`]]: color,
    ['rounded-pill']: pill,
  })
  return (
    <span className={classes}>{children}</span>
  )
}

Badge.propTypes = {
  children: PT.oneOfType([PT.string, PT.number]).isRequired,
  color: PT.oneOf([...Object.values(Colors)]),
  pill: PT.bool,
  className: PT.string,
}

Badge.defaultProps = {
  color: Colors.PRIMARY,
  pill: false,
  className: '',
}

export default Badge
