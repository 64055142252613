import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, Section } from 'ui-kit'
import { teamMembers } from '../constants/teamMembers'
import TeamMember from '../components/TeamMember'
import styles from './OurTeam.module.scss'

const OurTeam = () => {
  const { t } = useTranslation()

  return (
    <Section className={styles.ourTeam} id="our-team">
      <h2>{t('landing.sectionsTitle.ourTeam')}</h2>
      <Text landingText paragraph>{t('landing.sectionsText.ourTeam.text')}</Text>
      <div className={styles.teamMembersContainer}>
        {teamMembers(t).map(({ firstName, lastName, photo }) =>
          <TeamMember
            firstName={firstName}
            key={`${firstName}-${lastName}`}
            lastName={lastName}
            photo={photo}
          />,
        )}
        <TeamMember firstName={t('landing.sectionsText.ourTeam.maybeYou')} joinUs />
      </div>
    </Section>
  )
}

export default OurTeam
