import React, { useContext, useRef, useCallback, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { LandingDataContext } from 'contexts'
import { Section } from 'ui-kit'
import OurMission from './sections/OurMission'
import AboutUs from './sections/AboutUs'
import OurProjects from './sections/OurProjects'
import HelpMessage from './sections/HelpMessage'
import Metrics from './sections/Metrics'
import HeadOfTeam from './sections/HeadOfTeam'
import OurTeam from './sections/OurTeam'
import MassMedia from './sections/MassMedia'
import OurPartners from './sections/OurPartners'

const Home = () => {
  const { t } = useTranslation()
  const { handleChangeActiveNav } = useContext(LandingDataContext)
  const ref = useRef()
  const { ref: aboutUsRef, inView: aboutUsInView } = useInView({ threshold: [0.3, 1] })
  const { ref: ourProjectsRef, inView: ourProjectsInView } = useInView({ threshold: [0.3, 1] })
  const { ref: teamRef, inView: teamInView } = useInView({ threshold: [0.3, 1] })
  const { ref: massMediaRef, inView: massMediaInView } = useInView({ threshold: [0.3, 1] })

  const observers = [aboutUsInView, ourProjectsInView, teamInView, massMediaInView]

  const getInViewSection = () => {
    const inViewSectionIdx = observers.findIndex(inView => inView)
    return inViewSectionIdx >= 0 ? inViewSectionIdx : false
  }

  useEffect(
    () => {
      if (observers.filter(obs => obs === true).length === 1) {
        const timer = setTimeout(() => {
          const inViewSectionIdx = getInViewSection()
          handleChangeActiveNav(inViewSectionIdx)
        }, 500)
        return () => clearTimeout(timer)
      }
    },
    observers,
  )

  const aboutUsObserver = useCallback(
    (node) => {
      ref.current = node
      aboutUsRef(node)
    },
    [aboutUsRef],
  )
  const ourProjectsObserver = useCallback(
    (node) => {
      ref.current = node
      ourProjectsRef(node)
    },
    [ourProjectsRef],
  )
  const teamObserver = useCallback(
    (node) => {
      ref.current = node
      teamRef(node)
    },
    [teamRef],
  )
  const massMediaObserver = useCallback(
    (node) => {
      ref.current = node
      massMediaRef(node)
    },
    [massMediaRef],
  )

  return (
    <>
      <Helmet>
        <title>{t('landing.homePageTitle')}</title>
        <meta
          content={t('landing.metaDescription.homePage')}
          name="description"
        />
      </Helmet>
      <OurMission />
      <AboutUs observer={aboutUsObserver} />
      <HelpMessage />
      <OurProjects observer={ourProjectsObserver} />
      <Metrics />
      <Section id="team" observer={teamObserver}>
        <HeadOfTeam />
        <OurTeam />
      </Section>
      <MassMedia observer={massMediaObserver} />
      <OurPartners />
      <h1 className="container mainH1 text-center">{t('landing.h1Text')}</h1>
    </>
  )
}

export default Home
