export const REQUEST_STATUSES = [
  'all',
  'need_check',
  'initial',
  'verifying',
  'collecting',
  'soon',
  'done',
  'rejected',
  'on_stop',
]
