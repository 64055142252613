import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import styles from './Image.module.scss'

const Image = ({ src, alt, width, loading, className, style }) => {
  const classes = cx(styles.image, className)

  return (
    <img
      alt={alt}
      className={classes}
      loading={loading}
      src={src}
      style={style}
      width={width}
    />
  )
}

Image.propTypes = {
  src: PT.string.isRequired,
  alt: PT.string.isRequired,
  width: PT.string,
  loading: PT.string,
  className: PT.string,
  style: PT.shape({}),
}

Image.defaultProps = {
  width: '100%',
  loading: 'lazy',
  className: '',
  style: {},
}

export default Image
