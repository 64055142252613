import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import InputWrapper from '../InputWrapper'
import Icon from '../../icons/Icon'
import Colors from '../../../colors'
import styles from './Checkbox.module.scss'

const Checkbox = ({
  label,
  name,
  labelWrapper,
  checked,
  style,
  color,
  disabled,
  error,
  className,
  onChange,
  onBlur,
  required,
  noWrapper,
}) => {
  const iconClasses = cx(styles.checkbox, className, {
    [styles.disabled]: disabled,
    [styles.checkIconSuccess]: color === Colors.SUCCESS,
    [styles.checkIconDanger]: color === Colors.DANGER,
  })

  const renderCheckbox = () => (
    <div className={styles.checkboxWrapper} style={style}>
      <label className={styles.label}>
        <input
          checked={checked}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          type="checkbox"
        />
        <Icon className={iconClasses} name="check" type="solid" />
        {label}{required && <span className={styles.required} style={{ marginLeft: '4px' }}>*</span>}
      </label>
    </div>
  )

  if (noWrapper) {
    return renderCheckbox()
  }

  return (
    <InputWrapper error={error} id={name} label={labelWrapper}>
      {renderCheckbox()}
    </InputWrapper>
  )
}

Checkbox.propTypes = {
  name: PT.string.isRequired,
  className: PT.string,
  error: PT.string,
  checked: PT.bool,
  required: PT.bool,
  labelWrapper: PT.string,
  label: PT.oneOfType([PT.string, PT.element]),
  disabled: PT.bool,
  onChange: PT.func,
  onBlur: PT.func,
  noWrapper: PT.bool,
  color: PT.oneOf([
    Colors.SUCCESS,
    Colors.DANGER,
  ]),
  style: PT.shape({}),
}

Checkbox.defaultProps = {
  color: Colors.SUCCESS,
  style: {},
}

export default Checkbox
