import React, { useEffect } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { useQueryParams } from 'hooks/useQueryParams'
import { formatDate } from 'common/utils/datetime'
import { getPaginationData } from 'common/utils/getPaginationData'
import { ContactsQuery, ArchiveContactMutation, RestoreContactMutation } from 'queries/contacts.gql'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { useToaster } from 'hooks/useToaster'
import {
  Table,
  Flex,
  Button,
  Colors,
  Icon,
  Sizes,
  Pagination,
  Text,
  ConfirmModal,
} from 'ui-kit'
import PageHeader from 'components/pageHeader/PageHeader'
import SearchInput from 'components/SearchInput'

const Contacts = () => {
  const { isSuperadmin, isAdmin } = useCurrentUser()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { queryParams } = useQueryParams()
  const { showToaster, toasterTypes } = useToaster()

  useEffect(() => {
    if (!isSuperadmin && !isAdmin) {
      navigate('/admin/requests')
    }
  }, [])

  const sort = queryParams.sort
  const page = parseInt(queryParams.page) || 1
  const search = queryParams.search
  const queryVariables = {
    sort,
    page,
    search,
  }

  const { data, loading } = useQuery(ContactsQuery, { variables: queryVariables })
  const [archive] = useMutation(ArchiveContactMutation, {
    refetchQueries: [ContactsQuery],
  })
  const [restore] = useMutation(RestoreContactMutation, {
    refetchQueries: [ContactsQuery],
  })

  const contacts = data?.contacts?.data || []
  const paginationData = getPaginationData(data, 'contacts')

  const handleArchive = async (id) => {
    const variables = { id }
    await archive({ variables })
    showToaster({
      content: <Text>{t('contactToasts.contactSuccessfullyDeleted')}</Text>,
      type: toasterTypes.SUCCESS,
      action: {
        onClick: () => { handleRestore(id) },
        text: t('undo'),
      },
    })
  }

  const handleRestore = async (id) => {
    const variables = { id }
    await restore({ variables })
    showToaster({
      content: <Text>{t('contactToasts.contactSuccessfullyRestored')}</Text>,
      type: toasterTypes.SUCCESS,
      action: {
        onClick: () => { handleArchive(id) },
        text: t('undo'),
      },
    })
  }

  const contactActions = (id) => {
    return (
      <>
        <Flex className={cx('w-100', 'mobileActions')}>
          <Button
            className="me-1"
            color={Colors.WARNING}
            iconName="pencil"
            label={t('edit')}
            onClick={() => navigate(`${id}/edit`)}
            fullwidth
          />
          <ConfirmModal
            buttonLabel={t('delete')}
            onConfirm={() => handleArchive(id)}
            title={t('confirmModal.deleteContact')}
          >
            {(handleClick) => (
              <Button
                className="ms-1"
                color={Colors.DANGER}
                iconName="trash"
                label={t('delete')}
                onClick={handleClick}
                fullwidth
              />
            )}
          </ConfirmModal>
        </Flex>
        <Flex className={cx('desktopActions')}>
          <Icon
            className="me-4"
            color={Colors.WARNING}
            name="pencil"
            onClick={() => navigate(`${id}/edit`)}
            size={Sizes.LARGE}
          />
          <ConfirmModal
            buttonLabel={t('delete')}
            onConfirm={() => handleArchive(id)}
            title={t('confirmModal.deleteContact')}
          >
            {(handleClick) => (
              <Icon
                color={Colors.DANGER}
                name="trash"
                onClick={handleClick}
                size={Sizes.LARGE}
              />
            )}
          </ConfirmModal>
        </Flex>
      </>
    )
  }

  const decorateTitles = () => (
    [
      { name: 'id', label: <Text uppercase>{t('id')}</Text>, sorting: true },
      { name: 'full_name', label: <Text capitalize>{t('name')}</Text>, sorting: true },
      { name: 'phone_number', label: <Text capitalize>{t('phoneNumber')}</Text> },
      { name: 'email', label: <Text capitalize>{t('email')}</Text>, sorting: true },
      { name: 'telegram', label: <Text capitalize>{t('telegram')}</Text>, sorting: true },
      { name: 'created_at', label: <Text capitalize>{t('createdAt')}</Text> },
      { name: 'actions', label: '' },
    ]
  )

  const decorateData = () => (
    contacts.map((contact) => (
      {
        id: contact.id,
        cells: [
          { name: 'id', content: contact.id },
          { name: 'fullName', content: contact.fullName },
          { name: 'phoneNumber', content: contact.formattedPhoneNumber },
          { name: 'email', content: contact.email },
          { name: 'telegram', content: contact.telegram },
          { name: 'createdAt', content: formatDate(contact.createdAt) },
          { name: 'actions', content: contactActions(contact.id) },
        ],
      }
    ))
  )

  const headerActions = () => {
    return (
      [
        <Button
          color={Colors.PRIMARY}
          iconName="plus"
          key="create-contact"
          label={<Text capitalize>{t('addNew')}</Text>}
          onClick={() => navigate('new')}
        />,
      ]
    )
  }

  return (
    <>
      <PageHeader actions={headerActions()} label={t('contacts')} />
      <SearchInput key="search" />
      <Table data={decorateData()} loading={loading} titles={decorateTitles()} />
      <Pagination
        {...paginationData}
        loading={loading}
        withResults
      />
    </>
  )
}

export default Contacts
