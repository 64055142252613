/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import InputWrapper from '../InputWrapper'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import Icon from '../../icons/Icon'
import Flex from '../../spacing/Flex'
import Colors from '../../../colors'
import Sizes from '../../../sizes'
import styles from './DatePicker.module.scss'
import en from 'date-fns/locale/en-US'
import ua from 'date-fns/locale/uk'

registerLocale('en', en)
registerLocale('ua', ua)

const DatePicker = ({
  id,
  className,
  placeholder,
  disabled,
  rightIcon,
  onChange,
  onBlur,
  error,
  required,
  label,
  range,
  maxDate,
  minDate,
  dateFormat,
  placement,
  showMonthDropdown,
  showYearDropdown,
  showTime,
  value,
  isClearable,
  withPortal,
  style,
}) => {
  const lng = localStorage.getItem('lang') || 'ua'

  const classes = cx(styles.datePicker, className, {
    [styles.error]: error,
    [styles.disabled]: disabled,
  })

  const renderIcon =() => {
    if (!rightIcon) return null

    return <Icon color={Colors.DARK} name={rightIcon} size={Sizes.NORMAL} type="outline" noBorder />
  }

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div onClick={onClick} ref={ref}>
      <Flex alignItems="center" className={styles.inputWrapper} justifyContent="between" row>
        {value ? value : <span className={styles.placeholder}>{placeholder}</span>}
        {renderIcon()}
      </Flex>
    </div>
  ))

  const renderDatePicker = () => (
    <ReactDatePicker
      closeOnScroll={true}
      customInput={<CustomInput />}
      dateFormat={dateFormat}
      disabled={disabled}
      dropdownMode="select"
      isClearable={isClearable}
      locale={lng}
      maxDate={maxDate}
      minDate={minDate}
      onBlur={onBlur}
      onChange={onChange}
      popperPlacement={placement}
      selected={value}
      selectsRange={range}
      showMonthDropdown={showMonthDropdown}
      showPopperArrow={false}
      showTimeSelect={showTime}
      showYearDropdown={showYearDropdown}
      style={style}
      timeFormat="HH:mm"
      timeIntervals={15}
      withPortal={withPortal}
      wrapperClassName={classes}
    />
  )


  return (
    <InputWrapper
      error={error}
      id={id}
      label={label}
      required={required}
    >
      {renderDatePicker()}
    </InputWrapper>
  )

}

DatePicker.propTypes = {
  id: PT.string.isRequired,
  className: PT.string,
  placeholder: PT.string,
  onChange: PT.func.isRequired,
  onBlur: PT.func,
  error: PT.string,
  rightIcon: PT.string,
  required: PT.bool,
  label: PT.string,
  disabled: PT.bool,
  minDate: PT.object,
  maxDate: PT.object,
  dateFormat: PT.string,
  range: PT.bool,
  value: PT.instanceOf(Date),
  placement: PT.oneOf([
    'bottom-start',
    'bottom',
    'bottom-end',
    'top-start',
    'top',
    'top-end',
]),
  onClick: PT.func,
  showMonthDropdown: PT.bool,
  showYearDropdown: PT.bool,
  showTime: PT.bool,
  withPortal: PT.bool,
  isClearable: PT.bool,
  style: PT.shape({}),
}

DatePicker.defaultProps = {
  dateFormat: 'P',
  range: false,
  showMonthDropdown: false,
  showYearDropdown: false,
  showTime: false,
  isClearable: false,
  rightIcon: 'calendar',
  value: null,
  style: {},
}

export default DatePicker
