import React from 'react'
import { Flex, Button, Colors, Weights } from 'ui-kit'
import { useTranslation } from 'react-i18next'

const LangSwitcher = () => {
  const { i18n } = useTranslation()

  const handleLanguageChange = async (lang) => {
    await i18n.changeLanguage(lang)
    await localStorage.setItem('lang', lang)
  }

  const isCurrent = (btnLang) => i18n.language === btnLang

  const buttonStyles = (btnLang) => (
    {
      color: isCurrent(btnLang) ? Colors.LANDING_PRIMARY : Colors.LANDING_BLACK,
      weight: isCurrent(btnLang) ? Weights.SEMI : Weights.NORMAL,
    }
  )

  return (
    <Flex>
      <Button
        color={buttonStyles('ua').color}
        hidden={!isCurrent('ua')}
        label="UA"
        onClick={() => handleLanguageChange('en')}
        style={{ width: '47px' }}
        weight={buttonStyles('ua').weight}
        noBorder
      />
      <Button
        color={buttonStyles('en').color}
        hidden={!isCurrent('en')}
        label="EN"
        onClick={() => handleLanguageChange('ua')}
        style={{ width: '47px' }}
        weight={buttonStyles('en').weight}
        noBorder
      />
    </Flex>
  )
}

export default LangSwitcher
