import React from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Text,
  Section,
  Image,
} from 'ui-kit'

const AboutUs = ({ observer }) => {
  const { t } = useTranslation()

  return (
    <Section id="about-us" observer={observer}>
      <h2>{t('landing.sectionsTitle.aboutUs')}</h2>
      <Text landingText paragraph>{t('landing.sectionsText.aboutUs.1')}</Text>
      <Text landingText paragraph>{t('landing.sectionsText.aboutUs.2')}</Text>
      <Text landingText paragraph>{t('landing.sectionsText.aboutUs.3')}</Text>
      <Text landingText paragraph>{t('landing.sectionsText.aboutUs.4')}</Text>
      <Text className="mb-5" landingText paragraph>{t('landing.sectionsText.aboutUs.5')}</Text>
      <Image
        alt="team photo"
        src="https://assets.armiyavolonteriv.com/production/main/team_photo.webp"
      />
    </Section>
  )
}

AboutUs.propTypes = {
  observer: PT.func.isRequired,
}

export default AboutUs
