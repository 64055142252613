import React from 'react'
import PT from 'prop-types'
import { Text, Sizes } from 'ui-kit'
import styles from './MetricCard.module.scss'

const MetricCard = ({ number, title }) => {
  return (
    <div className={styles.metricCard}>
      <h3>{number}</h3>
      <Text size={Sizes.XLARGE}>{title}</Text>
    </div>
  )
}

MetricCard.propTypes = {
  number: PT.number.isRequired,
  title: PT.string.isRequired,
}

export default MetricCard
