import React, { useMemo } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { isEmpty, pickBy } from 'lodash'
import { useFormik } from 'formik'
import { useQueryParams } from 'hooks/useQueryParams'
import {
  TextInput,
  TextAreaInput,
  SelectInput,
  Container,
  Sizes,
  Button,
  Flex,
  Colors,
  Text,
  LoadingSpinner,
} from 'ui-kit'
import { validateRequiredField } from 'common/utils/validators'
import { STORE_ITEM_CATEGORIES } from 'common/constants/storeItemCategories'
import { STORE_ITEM_GENDERS } from 'common/constants/storeItemGenders'

const StoreItemForm = ({ storeItem, onSubmit, isLoading, isSubmitting }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { queryParams } = useQueryParams()

  const validate = (values) => {
    const errors = {}
    errors.title = validateRequiredField(values.title, t)
    errors.category = validateRequiredField(values.category, t)
    errors.gender = validateRequiredField(values.gender, t)

    return pickBy(errors)
  }

  const categoryDefaultOption = () => {
    if (storeItem?.category) {
      return (
        { label: t(`storeItemCategory.${storeItem.category}`), value: storeItem.category }
      )
    } else if (queryParams.category) {
      return (
        { label: t(`storeItemCategory.${queryParams.category}`), value: queryParams.category }
      )
    } else {
      return null
    }
  }

  const genderDefaultOption = () => {
    if (storeItem?.gender) {
      return (
        { label: t(`storeItemGender.${storeItem.gender}`), value: storeItem.gender }
      )
    } else if (queryParams.gender) {
      return (
        { label: t(`storeItemGender.${queryParams.gender}`), value: queryParams.gender }
      )
    } else {
      return null
    }
  }

  const formik = useFormik({
    initialValues: {
      title: storeItem.title || queryParams.title || '',
      category: categoryDefaultOption(),
      gender: genderDefaultOption(),
      description: storeItem.description || '',
      priceCents: storeItem.priceCents ? (storeItem.priceCents / 100) : undefined,
      amount: 1,
    },
    validate,
    onSubmit,
    enableReinitialize: true,
  })

  const categoryOptions = useMemo(() => (
    STORE_ITEM_CATEGORIES.map((value) => ({
      label: t(`storeItemCategory.${value}`),
      value,
    }))
  ), [])

  const genderOptions = useMemo(() => (
    STORE_ITEM_GENDERS.map((value) => ({
      label: t(`storeItemGender.${value}`),
      value,
    }))
  ), [])

  const renderError = (name) => (formik.touched[name] && formik.errors[name])

  const renderAmount = () => {
    if (!isEmpty(storeItem)) return null

    return (
      <TextInput
        error={renderError('amount')}
        id="amount"
        label={t('amount')}
        name="amount"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder={t('amount')}
        type="number"
        value={formik.values.amount}
      />
    )
  }

  if (isLoading) return <LoadingSpinner marginTop />

  return (
    <Container formContainer>
      <Flex column>
        <TextInput
          error={renderError('title')}
          id="title"
          label={t('title')}
          name="title"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('title')}
          value={formik.values.title}
          required
        />
        <SelectInput
          error={renderError('category')}
          id="category"
          label={t('category')}
          name="category"
          onChange={(value) => formik.setFieldValue('category', value)}
          options={categoryOptions}
          value={formik.values.category}
          fullwidth
          required
        />
        <SelectInput
          error={renderError('gender')}
          id="gender"
          label={t('gender')}
          name="gender"
          onChange={(value) => formik.setFieldValue('gender', value)}
          options={genderOptions}
          value={formik.values.gender}
          fullwidth
          required
        />
        <TextInput
          error={renderError('priceCents')}
          id="priceCents"
          label={t('price')}
          name="priceCents"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('price')}
          type="number"
          value={formik.values.priceCents}
        />
        <TextAreaInput
          error={renderError('description')}
          id="description"
          label={t('description')}
          name="description"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('description')}
          value={formik.values.description}
        />
        {renderAmount()}
        <Flex>
          <Button
            className="me-4"
            color={Colors.WARNING}
            label={<Text capitalize>{t('cancel')}</Text>}
            loading={isSubmitting}
            onClick={() => navigate('/admin/users')}
            size={Sizes.LARGE}
            fullwidth
            outline
          />
          <Button
            label={<Text capitalize>{t('save')}</Text>}
            loading={isSubmitting}
            onClick={formik.handleSubmit}
            size={Sizes.LARGE}
            fullwidth
          />
        </Flex>
      </Flex>
    </Container>
  )
}

StoreItemForm.propTypes = {
  storeItem: PT.shape({
    title: PT.string,
    category: PT.string,
    gender: PT.string,
    description: PT.string,
    priceCents: PT.number,
  }),
  onSubmit: PT.func.isRequired,
  isSubmitting: PT.bool,
  isLoading: PT.bool,
}

StoreItemForm.defaultProps = {
  storeItem: {},
  isSubmitting: false,
  isLoading: false,
}

export default StoreItemForm
