import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { DateTime } from 'luxon'
import { Flex, Text, Colors, Icon, Sizes } from 'ui-kit'
import FacebookLink from 'pages/landing/components/socialLinks/FacebookLink'
import InstagramLink from 'pages/landing/components/socialLinks/InstagramLink'
import LinkedinLink from 'pages/landing/components/socialLinks/LinkedinLink'
import styles from './Footer.module.scss'
import LinkButton from 'pages/landing/components/LinkButton'

const Footer = () => {
  const { t } = useTranslation()
  const currentYear = DateTime.now().year

  return (
    <footer className={styles.footer}>
      <Flex className={cx('container', styles.container)}>
        <Flex alignItems="center" className={styles.logo}>
          <Icon name="logo" />
        </Flex>
        <Flex alignItems="center" column>
          <a
            className="text-white"
            href="mailto:arm.volonteriv@gmail.com"
            rel="noreferrer"
            target="_blank"
          >
            arm.volonteriv@gmail.com
          </a>
          <Text></Text>
        </Flex>
        <Flex alignItems="center" column>
          <Flex gap="1rem">
            <FacebookLink />
            <InstagramLink />
            <LinkedinLink />
          </Flex>
        </Flex>
        <Flex alignItems="center" className={styles.actions} column>
          <LinkButton
            className={styles.action}
            color={Colors.PRIMARY}
            label={t('landing.requestFormButton')}
            to="/request-form"
          />
          <LinkButton className={styles.action} label={t('landing.donateButton')} to="/donate" />
          <a
            className="text-light"
            href="https://assets.armiyavolonteriv.com/production/main/public_policy.pdf"
            rel="noreferrer"
            target="_blank"
          >
            {t('landing.publicPolicy')}
          </a>
        </Flex>
      </Flex>
      <div className={styles.footerBottom}>
        <Text size={Sizes.XSMALL}>2022-{currentYear} &copy; {t('landing.fundName')}</Text>
      </div>
    </footer>
  )
}

export default Footer
