import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Flex, Text } from 'ui-kit'
import PageHeader from 'components/pageHeader/PageHeader'
import StoreItemForm from './components/StoreItemForm'
import { useToaster } from 'hooks/useToaster'
import { CreateStoreItemMutation } from 'queries/storeItems.gql'

const StoreItemNew = () => {
  const { t } = useTranslation()
  const { showToaster, toasterTypes } = useToaster()
  const navigate = useNavigate()

  const [createStoreItem, { loading: isSubmitting }] = useMutation(CreateStoreItemMutation, {
    onCompleted: (data) => {
      if (data.createStoreItem.entity) {
        navigate('/admin/store')
        showToaster({
          content: <Text>{t('storeItem.storeItemSuccessfullyAdded')}</Text>,
          type: toasterTypes.SUCCESS,
        })
      } else if (data.createStoreItem.errors?.length) {
        showToaster({
          content: (<Flex column>
            {data.createStoreItem.errors.map(({ message }) => <Text key={message}>{message}</Text>)}
          </Flex>),
          type: toasterTypes.DANGER,
        })
      }
    },
  })

  const handleSubmit = async (values) => {
    const variables = {
      data: {
        title: values.title,
        category: values.category.value,
        gender: values.gender.value,
        description: values.description,
        priceCents: parseFloat(values.priceCents) * 100,
      },
      amount: parseInt(values.amount),
    }
    await createStoreItem({ variables })
  }

  return (
    <>
      <PageHeader label={t('storeItem.addStoreItem')} />
      <StoreItemForm
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
      />
    </>
  )
}

export default StoreItemNew
