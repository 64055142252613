import React, { useState } from 'react'
import PT from 'prop-types'
import {
  Container,
  LoadingSpinner,
} from 'ui-kit'

import RequestFormSettingsEdit from './RequestFormSettingsEdit'
import RequestFormSettingsView from './RequestFormSettingsView'

const RequestFormSettings = ({ requestFormEnabled, requestsLimit, loading }) => {
  const [editMode, setEditMode] = useState(false)

  const renderContent = () => {
    if (loading && !requestFormEnabled && !requestsLimit) return <LoadingSpinner marginTop />

    if (editMode) return (
      <RequestFormSettingsEdit
        handleEditMode={setEditMode}
        requestFormEnabled={requestFormEnabled}
        requestsLimit={requestsLimit}
      />
    )

    return (
      <RequestFormSettingsView
        handleEditMode={setEditMode}
        requestFormEnabled={requestFormEnabled}
        requestsLimit={requestsLimit}
      />
    )
  }

  return (
    <Container formContainer>
      {renderContent()}
    </Container>
  )
}

RequestFormSettings.propTypes = {
  requestFormEnabled: PT.bool.isRequired,
  requestsLimit: PT.number.isRequired,
  loading: PT.bool,
}

RequestFormSettings.defaultProps = {
  loading: false,
}

export default RequestFormSettings
