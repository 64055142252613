import React from 'react'
import PT from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Icon,
  Flex,
  Text,
  Colors,
  Sizes,
} from 'ui-kit'

import styles from './Navigation.module.scss'

const NavListMobile = ({ onSidebarToggle, navList }) => {

  const navColor = (isActive) => isActive ? Colors.WHITE : Colors.INFO

  return (
    <ul className={styles.navMobile}>
      {navList.map(({ icon, label, link, isActive }) => (
        <li className={styles.navMobileItem} key={label}>
          <Link onClick={onSidebarToggle} to={link}>
            <Flex alignItems="center" column>
              <Icon color={navColor(isActive)} name={icon} size={Sizes.XLARGE}/>
              <Text className={styles.navMobileLabel} color={navColor(isActive)} capitalize>{label}</Text>
            </Flex>
          </Link>
        </li>
      ),
    )}
    </ul>
  )
}

NavListMobile.propTypes = {
  onSidebarToggle: PT.func,
  navList: PT.array,
}

export default NavListMobile
