import React from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Image, Flex, Text, Sizes } from 'ui-kit'
import styles from './TeamMember.module.scss'
import FacebookLink from './socialLinks/FacebookLink'
import InstagramLink from './socialLinks/InstagramLink'
import LinkedinLink from './socialLinks/LinkedinLink'

const TeamMember = ({ firstName, lastName, photo, joinUs }) => {
  const { t } = useTranslation()

  const renderImage = () => {
    if (joinUs) {
      return (
        <Flex alignItems="center" className={styles.joinUs} justifyContent="center" column fullwidth>
          <Text className="mb-3" landingText semiBold>{t('landing.sectionsText.ourTeam.joinUs')}</Text>
          <Flex alignItems="center" justifyContent="center" wrap>
            <FacebookLink className="mx-2" />
            <InstagramLink className="mx-2" />
            <LinkedinLink className="mx-2" />
          </Flex>
        </Flex>
      )
    } else {
      return (
        <Image alt={`${firstName} ${lastName}`} src={photo} />
      )
    }
  }

  return (
    <Flex alignContent="start" className={styles.teamMember} column>
      <Flex className={styles.imageContainer}>
        {renderImage()}
      </Flex>
      <Text size={Sizes.XLARGE} semiBold>{firstName}</Text>
      <Text size={Sizes.XLARGE} semiBold>{lastName}</Text>
    </Flex>
  )
}

TeamMember.propTypes = {
  firstName: PT.string.isRequired,
  lastName: PT.string,
  photo: PT.string,
  joinUs: PT.bool,
}

TeamMember.defaultProps = {
  photo: '',
  lastName: '',
  joinUs: false,
}

export default TeamMember
