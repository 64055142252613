import React from 'react'
import PT from 'prop-types'

const Video = ({ src, title }) => {
  return (
    <iframe
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      height="315"
      src={src}
      title={title}
      width="560"
      allowFullScreen
    />
  )
}

Video.propTypes = {
  src: PT.string.isRequired,
  title: PT.string.isRequired,
}

export default Video
