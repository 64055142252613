import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Text,
  Image,
  Flex,
  Section,
} from 'ui-kit'
import styles from './HeadOfTeam.module.scss'

const HeadOfTeam = () => {
  const { t } = useTranslation()

  return (
    <Section id="head-of-team">
      <h2>{t('landing.sectionsTitle.headOfTeam')}</h2>
      <Flex alignItems="start" className={styles.headOfTeam} gap="3rem" justifyContent="between">
        <Flex className={styles.text} column>
          <h3 className="mb-3">{t('landing.sectionsText.headOfTeam.name')}</h3>
          <Text landingText paragraph>{t('landing.sectionsText.headOfTeam.info.1')}</Text>
          <Text landingText paragraph>{t('landing.sectionsText.headOfTeam.info.2')}</Text>
          <Text landingText paragraph>{t('landing.sectionsText.headOfTeam.info.3')}</Text>
          <Text landingText paragraph>{t('landing.sectionsText.headOfTeam.info.4')}</Text>
        </Flex>
        <Flex className={styles.image}>
          <Image
            alt="head of team"
            src="https://assets.armiyavolonteriv.com/production/team_members/oleksandra_arnautova.webp"
          />
        </Flex>
      </Flex>
    </Section>
  )
}

export default HeadOfTeam
