import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import MobileNavigation from './MobileNavigation'
import styles from './MobileSidebar.module.scss'

const MobileSidebar = ({ isOpened, onNavItemClick }) => {
  const mobileSidebarClasses = cx(styles.mobileSidebar, {
    [styles.opened]: isOpened,
  })

  return (
    <div className={mobileSidebarClasses}>
      <MobileNavigation onNavItemClick={onNavItemClick} />
    </div>
  )
}

MobileSidebar.propTypes = {
  isOpened: PT.bool,
  onNavItemClick: PT.func.isRequired,
}

MobileSidebar.defaultProps = {
  isOpened: false,
}

export default MobileSidebar
