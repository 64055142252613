import React from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { useQueryParams } from 'hooks/useQueryParams'
import {
  Modal,
  Container,
  List,
  Text,
  Badge,
  Flex,
  Button,
  Sizes,
  Dropdown,
  Colors,
} from 'ui-kit'
import {
  StoreItemsSummaryQuery,
  AddToRequestStoreItemMutation,
  RemoveFromRequestStoreItemMutation,
} from 'queries/storeItems.gql'

import { STORE_ITEM_CATEGORIES } from 'common/constants/storeItemCategories'
import { STORE_ITEM_GENDERS } from 'common/constants/storeItemGenders'

const AddStoreItemModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const { requestId } = useParams()
  const { queryParams, setQueryParams } = useQueryParams()
  const category = queryParams.category
  const gender = queryParams.gender

  const { data, loading } = useQuery(StoreItemsSummaryQuery, {
    variables: { requestId, category, gender },
  })
  const [add, { loading: isAdding }] = useMutation(AddToRequestStoreItemMutation, {
    refetchQueries: [StoreItemsSummaryQuery],
  })
  const [remove, { loading: isRemoving }] = useMutation(RemoveFromRequestStoreItemMutation, {
    refetchQueries: [StoreItemsSummaryQuery],
  })

  const storeItems = data?.storeItemsSummary || []

  const handleAdd = async (title) => {
    const variables = { title, requestId }
    await add({ variables })
  }

  const handleRemove = async (title) => {
    const variables = { title, requestId }
    await remove({ variables })
  }

  const handleChangeFilter = (param, value) => {
    setQueryParams({ [param]: value })
  }

  const categoryOptions = () => {
    const renderedOptions = STORE_ITEM_CATEGORIES.map((itemCategory) => (
      {
        key: itemCategory,
        title: t(`storeItemCategory.${itemCategory}`),
        handler: () => handleChangeFilter('category', itemCategory),
      }
    ))

    renderedOptions.splice(
      0, 0,
      {
        key: 'emptyCategory',
        title: t('storeItemCategory.cancelCategory'),
        handler: () => handleChangeFilter('category', ''),
      },
    )

    return renderedOptions
  }

  const genderOptions = () => {
    const renderedOptions = STORE_ITEM_GENDERS.map((itemGender) => (
      {
        key: itemGender,
        title: t(`storeItemGender.${itemGender}`),
        handler: () => handleChangeFilter('gender', itemGender),
      }
    ))

    renderedOptions.splice(
      0, 0,
      {
        key: 'emptyCategory',
        title: t('storeItemGender.cancelGender'),
        handler: () => handleChangeFilter('gender', ''),
      },
    )

    return renderedOptions
  }

  const categoryFilterTitle = category ? t(`storeItemCategory.${category}`) : t('category')
  const genderFilterTitle = gender ? t(`storeItemGender.${gender}`) : t('gender')

  const listData = () => (
    storeItems.map((item) => (
      <Flex alignItems="center" justifyContent="between" key={item.title} fullwidth>
        <Flex>
          <Flex alignItems="center" style={{ width: '2.5rem' }}>
            <Badge>{item.count}</Badge>
          </Flex>
          <Text className="mx-2">{item.title}</Text>
        </Flex>
        <Flex>
          <Button
            disabled={item.countPerRequest <= 0}
            iconName="minusSmall"
            loading={isRemoving}
            onClick={() => handleRemove(item.title)}
            size={Sizes.SMALL}
          />
          <Flex alignItems="center">
            <Text className="mx-2">{item.countPerRequest}</Text>
          </Flex>
          <Button
            disabled={item.count <= 0}
            iconName="plus"
            loading={isAdding}
            onClick={() => handleAdd(item.title)}
            size={Sizes.SMALL}
          />
        </Flex>
      </Flex>
    ))
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton
    >
      <Container withShadow={false} formContainer>
        <h3>Товари на складі</h3>
        <Flex className="mb-4">
          <Dropdown className="me-2" options={categoryOptions()}>
            <Button
              color={Colors.PRIMARY}
              iconName="adjustments"
              label={<Text capitalize>{categoryFilterTitle}</Text>}
              outline
            />
          </Dropdown>
          <Dropdown className="me-2" options={genderOptions()}>
            <Button
              color={Colors.PRIMARY}
              iconName="adjustments"
              label={<Text capitalize>{genderFilterTitle}</Text>}
              outline
            />
          </Dropdown>
        </Flex>
        <List data={listData()} loading={loading} />
      </Container>
    </Modal>
  )
}

AddStoreItemModal.propTypes = {
  isOpen: PT.bool.isRequired,
  onClose: PT.func.isRequired,
}

AddStoreItemModal.defaultProps = {}

export default AddStoreItemModal
