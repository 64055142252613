import React, { useState } from 'react'
import PT from 'prop-types'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useMutation, useQuery } from '@apollo/client'
import { UsersQuery } from 'queries/users.gql'
import { RequestQuery, UpdateRequestMutation } from 'queries/requests.gql'
import { RemoveFromRequestStoreItemMutation } from 'queries/storeItems.gql'
import {
  LoadingSpinner,
  SelectInput,
  TextAreaInput,
  Text,
  Sizes,
  Colors,
  Flex,
  Button,
  InputWrapper,
  List,
  Badge,
  Datepicker,
} from 'ui-kit'
import AddStoreItemModal from './AddStoreItemModal'

import { REQUEST_STATUS_MAPPING } from 'common/constants/requestStatusMapping'

const RequestForm = ({ request, handleEditMode, refetchRequest }) => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false)

  const { data: usersData, loading } = useQuery(UsersQuery, {
    variables: { sort: 'first_name.asc', perPage: 100, scope: 'active' },
  })

  const [update, { loading: isSubmitting }] = useMutation(UpdateRequestMutation, {
    refetchQueries: [RequestQuery],
  })

  const [remove, { loading: isRemoving }] = useMutation(RemoveFromRequestStoreItemMutation, {
    onCompleted: () => refetchRequest(),
  })

  const formik = useFormik({
    initialValues: {
      assigneeId: request.assignee?.id,
      notes: request.notes || '',
      status: request.status,
      additionalItems: request.additionalItems || '',
      pickUpTime: request.pickUpTime ? DateTime.fromISO(request.pickUpTime).toJSDate() : null,
    },
    onSubmit: (values) => handleSubmit(values),
    enableReinitialize: true,
  })

  const users = usersData?.users?.data || []

  const statusOptions = () => (
    REQUEST_STATUS_MAPPING[request.status].map(status => ({
      label: t(`requestStatus.${status}`),
      value: status,
    }))
  )

  const assigneeOptions = () => {
    const usersOptions = users.map(user => ({
      label: user.fullName,
      value: user.id,
    }))
    const emptyOption = {
      label: t('nobody'),
      value: null,
    }
    return [emptyOption, ...usersOptions]
  }

  const handleSubmit = async (values) => {
    const variables = {
      id: request.id,
      data: {
        assigneeId: values.assigneeId,
        notes: values.notes,
        additionalItems: values.additionalItems,
        pickUpTime: values.pickUpTime ? values.pickUpTime.toString() : null,
      },
      ...(request.status !== values.status && { newStatus: values.status }),
    }
    await update({ variables })
    handleEditMode(false)
  }

  const handleRemove = async (title) => {
    const variables = { title, requestId: request.id, removeAll: true }
    await remove({ variables })
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    refetchRequest()
  }

  const selectedStatus = () => {
    return (
      {
        label: t(`requestStatus.${formik.values.status}`),
        value: formik.values.status,
      }
    )
  }

  const selectedAssignee = () => {
    const selectedUser = users.find(user => user.id === formik.values.assigneeId)

    return (
      {
        label: selectedUser?.fullName || t('nobody'),
        value: selectedUser?.id || '',
      }
    )
  }

  const itemsFromStoreList = () => (
    request.storeItemsSummary.map((item) => (
      <Flex alignItems="center" justifyContent="between" key={item.id} fullwidth>
        <Text>{item.title}</Text>
        <Flex>
          <Badge>{item.count}</Badge>
          <Button
            className="ms-2"
            color={Colors.DANGER}
            iconName="x"
            loading={isRemoving}
            onClick={() => handleRemove(item.title)}
            size={Sizes.SMALL}
          />
        </Flex>
      </Flex>
    ))
  )

  if (loading) {
    return (
      <LoadingSpinner />
    )
  }

  return (
    <>
      {openModal && (
        <AddStoreItemModal formik={formik} isOpen={openModal} onClose={handleCloseModal} />
      )}
      <SelectInput
        id="status"
        label={t('requestFieldLabel.status')}
        name="status"
        onChange={({ value }) => formik.setFieldValue('status', value)}
        options={statusOptions()}
        value={selectedStatus()}
        fullwidth
        required
      />
      <SelectInput
        id="assignee"
        label={t('requestFieldLabel.assignee')}
        name="assignee"
        onChange={({ value }) => formik.setFieldValue('assigneeId', value)}
        options={assigneeOptions()}
        value={selectedAssignee()}
        fullwidth
        required
      />
      <InputWrapper id="storeItems" label={t('requestFieldLabel.storeItems')}>
        <List className="mb-2" data={itemsFromStoreList()} />
        <Button
          color={Colors.PRIMARY}
          iconName="plus"
          label={t('storeItem.attachItem')}
          onClick={() => setOpenModal(true)}
        />
      </InputWrapper>
      <TextAreaInput
        id="additionalItems"
        label={t('requestFieldLabel.additionalItems')}
        name="additionalItems"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder={t('requestFieldLabel.additionalItems')}
        value={formik.values.additionalItems}
      />
      <TextAreaInput
        id="notes"
        label={t('requestFieldLabel.notes')}
        name="notes"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder={t('requestFieldLabel.notes')}
        value={formik.values.notes}
      />

      <Datepicker
        dateFormat="P p"
        id="pickUpTime"
        label={t('requestFieldLabel.pickUpTime')}
        name="pickUpTime"
        onBlur={formik.handleBlur}
        onChange={(value) => formik.setFieldValue('pickUpTime', value)}
        placeholder={t('chooseDate')}
        placement="bottom-end"
        rightIcon="calendar"
        value={formik.values.pickUpTime}
        isClearable
        showMonthDropdown
        showTime
      />

      <Flex className="mt-4">
        <Button
          className="me-4"
          color={Colors.WARNING}
          label={<Text capitalize>{t('cancel')}</Text>}
          loading={isSubmitting}
          onClick={() => handleEditMode(false)}
          size={Sizes.LARGE}
          fullwidth
          outline
        />
        <Button
          label={<Text capitalize>{t('save')}</Text>}
          loading={isSubmitting}
          onClick={formik.handleSubmit}
          size={Sizes.LARGE}
          fullwidth
        />
      </Flex>
    </>
  )
}

RequestForm.propTypes = {
  request: PT.shape({
    id: PT.oneOfType([PT.string, PT.number]).isRequired,
    assignee: PT.shape({
      id: PT.oneOfType([PT.string, PT.number]),
    }),
    notes: PT.string,
    additionalItems: PT.string,
    status: PT.string,
    pickUpTime: PT.string,
    storeItemsSummary: PT.arrayOf(PT.shape({
      id: PT.string,
      title: PT.string,
      count: PT.number,
    })),
  }).isRequired,
  handleEditMode: PT.func.isRequired,
  refetchRequest: PT.func.isRequired,
}

export default RequestForm
