import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import { Colors } from '../../colors'
import { Sizes } from '../../sizes'
import styles from './Text.module.scss'

const Text = ({
  children,
  paragraph,
  className,
  color,
  size,
  bold,
  semiBold,
  align,
  landingText,
  uppercase,
  capitalize,
  nowrap,
  truncated,
  style,
}) => {
  const Element = paragraph ? 'p' : 'span'

  const classes = cx(className, {
    [`text-${color}`]: !!color,
    [styles.normal]: size === Sizes.NORMAL,
    [styles.secondary]: size === Sizes.SECONDARY,
    [styles.xsmall]: size === Sizes.XSMALL,
    [styles.large]: size === Sizes.LARGE,
    [styles.xlarge]: size === Sizes.XLARGE,
    [styles.xxlarge]: size === Sizes.XXLARGE,
    [styles.landingText]: landingText,
    [`text-${align}`]: !!align,
    ['fw-semibold']: semiBold,
    ['fw-bold']: bold,
    [styles.uppercase]: uppercase,
    [styles.capitalize]: capitalize,
    ['text-nowrap']: nowrap,
    [styles.truncated]: !!truncated,
  })

  return <Element className={classes} style={style}>{children}</Element>
}

Text.propTypes = {
  children: PT.node.isRequired,
  className: PT.string,
  paragraph: PT.bool,
  truncated: PT.bool,
  color: PT.oneOf([
    '',
    Colors.WHITE,
    Colors.DARK,
    Colors.PRIMARY,
    Colors.SECONDARY,
    Colors.INFO,
    Colors.SUCCESS,
    Colors.WARNING,
    Colors.DANGER,
    Colors.LIGHT,
    Colors.LANDING_GREY,
  ]),
  size: PT.oneOf([
    Sizes.NORMAL,
    Sizes.SECONDARY,
    Sizes.DESCRIPTION,
    Sizes.XSMALL,
    Sizes.LARGE,
    Sizes.XLARGE,
    Sizes.XXLARGE,
  ]),
  landingText: PT.bool,
  semiBold: PT.bool,
  bold: PT.bool,
  align: PT.oneOf(['start', 'center', 'end']),
  uppercase: PT.bool,
  nowrap: PT.bool,
  capitalize: PT.bool,
  style: PT.shape({}),
}

Text.defaultProps = {
  className: '',
  paragraph: false,
  color: '',
  truncated: false,
  size: Sizes.NORMAL,
  semiBold: false,
  bold: false,
  align: 'start',
  landingText: false,
  uppercase: false,
  capitalize: false,
  nowrap: false,
  style: {},
}

export default Text
