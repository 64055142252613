import React from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { pickBy } from 'lodash'
import { useFormik } from 'formik'
import {
  TextInput,
  Container,
  Sizes,
  Button,
  Flex,
  Colors,
  Text,
  LoadingSpinner,
} from 'ui-kit'
import { validateEmail, validateRequiredField } from 'common/utils/validators'

const ContactForm = ({ contact, onSubmit, isLoading, isSubmitting }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const validate = (values) => {
    const errors = {}
    errors.email = validateEmail(values.email, t)
    errors.firstName = validateRequiredField(values.firstName, t)
    errors.lastName = validateRequiredField(values.lastName, t)
    errors.phoneNumber = validateRequiredField(values.phoneNumber, t)

    return pickBy(errors)
  }

  const formik = useFormik({
    initialValues: {
      email: contact?.email || '',
      firstName: contact?.firstName || '',
      lastName: contact?.lastName || '',
      phoneNumber: contact?.phoneNumber || '',
      telegram: contact?.telegram || '',
    },
    validate,
    onSubmit,
    enableReinitialize: true,
  })

  const renderError = (name) => (formik.touched[name] && formik.errors[name])

  if (isLoading) return <LoadingSpinner marginTop />

  return (
    <Container formContainer>
      <Flex column>
        <TextInput
          error={renderError('email')}
          id="email"
          label={t('email')}
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('email')}
          value={formik.values.email}
        />
        <TextInput
          error={renderError('firstName')}
          id="firstName"
          label={t('firstName')}
          name="firstName"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('firstName')}
          value={formik.values.firstName}
          required
        />
        <TextInput
          error={renderError('lastName')}
          id="lastName"
          label={t('lastName')}
          name="lastName"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('lastName')}
          value={formik.values.lastName}
          required
        />
        <TextInput
          error={renderError('phoneNumber')}
          id="phoneNumber"
          label={t('phoneNumber')}
          name="phoneNumber"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('phoneNumber')}
          value={formik.values.phoneNumber}
          required
        />
        <TextInput
          id="telegram"
          label={t('telegram')}
          name="telegram"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('telegram')}
          value={formik.values.telegram}
        />
        <Flex>
          <Button
            className="me-4"
            color={Colors.WARNING}
            label={<Text capitalize>{t('cancel')}</Text>}
            loading={isSubmitting}
            onClick={() => navigate('/admin/contacts')}
            size={Sizes.LARGE}
            fullwidth
            outline
          />
          <Button
            label={<Text capitalize>{t('save')}</Text>}
            loading={isSubmitting}
            onClick={formik.handleSubmit}
            size={Sizes.LARGE}
            fullwidth
          />
        </Flex>
      </Flex>
    </Container>
  )
}

ContactForm.propTypes = {
  contact: PT.shape({
    email: PT.string,
    firstName: PT.string,
    lastName: PT.string,
    phoneNumber: PT.string,
    telegram: PT.string,
  }),
  onSubmit: PT.func.isRequired,
  isSubmitting: PT.bool,
  isLoading: PT.bool,
}

ContactForm.defaultProps = {
  user: {},
  isSubmitting: false,
  isLoading: false,
}

export default ContactForm
