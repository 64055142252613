import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from './components/Sidebar'
import Header from './components/Header'

import { Flex } from 'ui-kit'

import styles from './Layout.module.scss'

const Layout = () => {
  const [isSidebarOpened, setIsSidebarOpened] = useState(false)

  const handleSidebarToggle = () => setIsSidebarOpened(!isSidebarOpened)

  return (
    <div className={styles.adminLayout}>
      <Sidebar isOpened={isSidebarOpened} onSidebarToggle={handleSidebarToggle} />
      <Flex grow={1} shrink={0} column>
        <Header onSidebarToggle={handleSidebarToggle}/>
        <div className="container pb-4">
          <Outlet />
        </div>
      </Flex>
    </div>
  )
}

export default Layout
