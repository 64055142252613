import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import classes from './Flex.module.scss'

const Flex = ({
  row,
  column,
  justifyContent,
  alignItems,
  alignContent,
  flex,
  grow,
  shrink,
  basis,
  gap,
  wrap,
  nowrap,
  className,
  children,
  tag,
  id,
  fullwidth,
  hidden,
  style,
}) => {
  const flexClassnames = cx({
    [classes.flex]: flex,
    [classes.flexRow]: row,
    [classes.flexCol]: column,
    [classes.wrap]: wrap,
    [classes.nowrap]: nowrap,
    [classes.flexGrowZero]: grow === 0,
    [classes.flexGrowOne]: grow === 1,
    [classes.flexShrinkZero]: shrink === 0,
    [classes.flexShrinkOne]: shrink === 1,
    [classes[`justifyContent-${justifyContent}`]]: !!justifyContent,
    [classes[`alignContent-${alignContent}`]]: !!alignContent,
    [classes[`alignItems-${alignItems}`]]: !!alignItems,
    ['w-100']: fullwidth,
    [classes.hidden]: hidden,
    [className]: !!className,
  })

  const styles = {
    flexBasis: basis,
    ...gap && { gap },
    ...style,
  }

  const Element = tag

  return (
    <Element className={flexClassnames} id={id} style={styles}>
      {children}
    </Element>
  )
}

Flex.propTypes = {
  alignContent: PT.oneOf(['start', 'end', 'center', 'baseline', 'stretch', 'between', 'around', 'evenly']),
  alignItems: PT.oneOf(['start', 'end', 'center', 'baseline', 'stretch']),
  basis: PT.string,
  children: PT.node.isRequired,
  className: PT.string,
  column: PT.bool,
  flex: PT.bool,
  gap: PT.string,
  grow: PT.number,
  justifyContent: PT.oneOf(['start', 'end', 'center', 'between', 'around', 'evenly']),
  nowrap: PT.bool,
  row: PT.bool,
  shrink: PT.number,
  tag: PT.string,
  wrap: PT.bool,
  id: PT.string,
  fullwidth: PT.bool,
  hidden: PT.bool,
  style: PT.shape({}),
}

Flex.defaultProps = {
  alignContent: null,
  alignItems: null,
  basis: 'auto',
  className: null,
  column: false,
  flex: true,
  gap: null,
  grow: null,
  justifyContent: null,
  nowrap: false,
  row: false,
  shrink: null,
  tag: 'div',
  wrap: false,
  id: '',
  fullwidth: false,
  hidden: false,
  style: {},
}

export default Flex
