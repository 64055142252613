import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import { Colors } from '../../colors'
import { Sizes } from '../../sizes'
import { Weights } from '../../weights'
import Icon from '../icons/Icon'
import Flex from '../spacing/Flex'
import Margin from '../spacing/Margin'
import styles from './Button.module.scss'

const Button = ({
  label,
  toLink,
  outline,
  disabled,
  loading,
  iconName,
  color,
  size,
  fullwidth,
  type,
  onClick,
  weight,
  className,
  style,
  noBorder,
  hidden,
}) => {
  const Element = toLink ? Link : 'button'

  const buttonClass = `btn${outline ? '-outline' : ''}-${color}`

  const classes = cx(
    styles.button, styles[buttonClass], className, {
    [styles.noBorder]: noBorder,
    [styles.fullwidth]: fullwidth,
    [styles.hidden]: hidden,
    ['btn-sm']: size === Sizes.SMALL,
    ['btn-lg']: size === Sizes.LARGE,
    [styles[`btn-${weight}`]]: !!weight,
  })

  const renderIcon = () => {
    const icon = loading ? 'loading' : iconName
    if (!icon) return null

    if (!label) {
      return <Icon name={icon} size={size} />
    }
    return (
      <Margin className="d-flex" right={Sizes.SMALL}>
        <Icon name={icon} size={size} />
      </Margin>
    )
  }

  return (
    <Element
      className={classes}
      onClick={onClick}
      type={type}
      {...(toLink && { to: toLink })}
      disabled={disabled}
      style={style}
    >
      <Flex alignItems="center" justifyContent="center">
        {renderIcon()}
        {label}
      </Flex>
    </Element>
  )
}

Button.propTypes = {
  label: PT.oneOfType([PT.string, PT.element]),
  disabled: PT.bool,
  outline: PT.bool,
  loading: PT.bool,
  iconName: PT.string,
  toLink: PT.string,
  color: PT.oneOf([...Object.values(Colors)]),
  size: PT.oneOf([Sizes.NORMAL, Sizes.LARGE, Sizes.SMALL]),
  noBorder: PT.bool,
  fullwidth: PT.bool,
  type: PT.oneOf(['button', 'reset', 'submit']),
  onClick: PT.func,
  weight: PT.string,
  hidden: PT.bool,
  className: PT.string,
  style: PT.shape({}),
}

Button.defaultProps = {
  label: '',
  toLink: '',
  disabled: false,
  outline: false,
  loading: false,
  iconName: '',
  onClick: null,
  color: Colors.SUCCESS,
  size: Sizes.NORMAL,
  noBorder: false,
  fullwidth: false,
  type: 'button',
  weight: Weights.NORMAL,
  className: '',
  style: {},
  hidden: false,
}

export default Button
