import React from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import { keys } from 'lodash'
import { formatDateTime } from 'common/utils/datetime'

import { Badge, Button, Flex, List, Sizes, Text } from 'ui-kit'
import TextWithLink from 'components/TextWithLink'

const RequestInfoView = ({ request, handleEditMode }) => {
  const { t } = useTranslation()

  const renderNotes = (text) => {
    if (!text ) return '-'

    return (
      <div>
        <TextWithLink regex={/(?:#|№)\d+/g} to="/admin/requests/:match">{text}</TextWithLink>
      </div>
    )
  }

  const renderStoreItems = () => {
    if (!request.storeItemsSummary.length) return '-'

    const list = request.storeItemsSummary.map(item => (
      <>
        <Text className="mx-2">{item.title}</Text>
        <Badge>{item.count}</Badge>
      </>
    ))
    return (
      <List data={list} />
    )
  }

  const listData = () => {
    const requestListData = {
      assignee: request.assignee?.fullName || '-',
      storeItems: renderStoreItems(),
      additionalItems: request.additionalItems || '-',
      notes: renderNotes(request.notes),
      systemNotes: renderNotes(request.systemNotes),
      pickUpTime: request.pickUpTime ? formatDateTime(request.pickUpTime) : '-',
    }

    return (
      keys(requestListData).map(key => (
        <Flex key={key} column fullwidth>
          <Text bold>{t(`requestFieldLabel.${key}`)}</Text>
          {requestListData[key]}
        </Flex>
      ))
    )
  }

  return (
    <>
      <List data={listData()} />
      <Button
        className="mt-4"
        label={<Text capitalize>{t('edit')}</Text>}
        onClick={() => handleEditMode(true)}
        size={Sizes.LARGE}
        fullwidth
      />
    </>
  )
}

RequestInfoView.propTypes = {
  request: PT.shape({
    id: PT.oneOfType([PT.string, PT.number]).isRequired,
    assignee: PT.shape({
      id: PT.oneOfType([PT.string, PT.number]),
      fullName: PT.string,
    }),
    notes: PT.string,
    systemNotes: PT.string,
    additionalItems: PT.string,
    pickUpTime: PT.string,
    storeItemsSummary: PT.arrayOf(PT.shape({
      id: PT.string,
      title: PT.string,
      count: PT.number,
    })),
  }).isRequired,
  handleEditMode: PT.func.isRequired,
}

export default RequestInfoView
