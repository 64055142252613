import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { pickBy, omit } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useQueryParams } from 'hooks/useQueryParams'
import { useToaster } from 'hooks/useToaster'
import { LandingDataContext } from 'contexts'
import { CreateRequestMutation } from 'queries/requests.gql'
import { validateRequiredField, validatePhoneNumberField, validateMinimumFieldLength } from 'common/utils/validators'
import {
  TextInput,
  Container,
  Flex,
  Checkbox,
  TextAreaInput,
  Datepicker,
  PhoneNumberInput,
  GoogleAutocompleteInput,
  Text,
  Button,
  Sizes,
  Colors,
} from 'ui-kit'

// import { KYIV_BOUNDS, WAR_ZONE_BOUNDS } from 'common/constants/googleAutocompletInputBounds'

const RequestForm = () => {
  const { t } = useTranslation()
  const { queryParams } = useQueryParams()
  const { requestFormEnabled } = useContext(LandingDataContext)
  const dateOfInvasion = new Date(2022, 1, 24)
  const navigate = useNavigate()
  const { showToaster, toasterTypes } = useToaster(7000)

  const token = queryParams.token

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  const redirectToMain = () => {
    showToaster({
      content: (
        <>
          <Text color={Colors.LIGHT} paragraph>{t('landing.toast.requestFormDisabled.1')}</Text>
          <Text color={Colors.LIGHT} paragraph>{t('landing.toast.requestFormDisabled.2')}</Text>
          <Text color={Colors.LIGHT} paragraph>{t('landing.toast.requestFormDisabled.3')}</Text>
        </>
      ),
      type: toasterTypes.DANGER,
    })
    navigate('/')
  }

  const checkRequestFormAvailability = () => {
    if (requestFormEnabled) return true

    if (requestFormEnabled === false && !!token) {
      axios.get('/request_form_token_check', { params: { token } })
        .then(token => {
          if (token.status === 'invalid') {
            redirectToMain()
          } else {
            return true
          }
        })
    } else if (requestFormEnabled === false) {
      redirectToMain()
    }
  }

  useEffect(() => {
    checkRequestFormAvailability()
  }, [requestFormEnabled])

  const [create, { loading }] = useMutation(CreateRequestMutation, {
    onCompleted: (data) => {
      if (data.createRequest.entity) {
        navigate('/#our-mission')
        showToaster({
          content: <Text>{t('landing.toast.requestSubmitSuccess')}</Text>,
          type: toasterTypes.SUCCESS,
        })
      } else if (data.createRequest.errors?.length) {
        showToaster({
          content: (
            <Flex column>
              {data.createRequest.errors.map(({ message }) => <Text key={message}>{message}</Text>)}
            </Flex>
          ),
          type: toasterTypes.DANGER,
        })
      }
    },
  })

  const validate = (values) => {
    const errors = {}
    errors.firstName = validateRequiredField(values.firstName.replaceAll(' ', ''), t)
    errors.lastName = validateRequiredField(values.lastName.replaceAll(' ', ''), t)
    errors.middleName = validateRequiredField(values.middleName.replaceAll(' ', ''), t)
    errors.dateOfBirth = validateRequiredField(values.dateOfBirth, t)
    errors.phoneNumber = validatePhoneNumberField(values.phoneNumber, t)
    errors.dateOfLeavingHome = validateRequiredField(values.dateOfLeavingHome, t)
    errors.vpoStartDate = validateRequiredField(values.vpoStartDate, t)
    errors.registeredLocation = validateMinimumFieldLength(values.registeredLocation.replaceAll(' ', ''), t, 10)
    errors.currentLocation = validateMinimumFieldLength(values.currentLocation.replaceAll(' ', ''), t, 10)
    errors.publicPolicy = validateRequiredField(values.publicPolicy, t)

    return pickBy(errors)
  }

  const handleSubmit = async (values) => {
    const validValues = {
      ...values,
      lastName: values.lastName.trim(),
      dateOfBirth: values.dateOfBirth.toDateString(),
      dateOfLeavingHome: values.dateOfLeavingHome.toDateString(),
      vpoStartDate: values.vpoStartDate.toDateString(),
    }
    const variables = {
      token,
      data: { ...omit(pickBy(validValues), ['publicPolicy']) },
    }
    await create({ variables })
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      middleName: '',
      dateOfBirth: null,
      phoneNumber: '',
      dateOfLeavingHome: null,
      vpoStartDate: null,
      registeredLocation: '',
      currentLocation: '',
      isVpoWithBabies: false,
      isVpoWithChildren: false,
      isClothesNeeded: false,
      isHygieneNeeded: false,
      otherIsNeeded: '',
      childrenInfo: '',
      otherIsNeededForChildren: '',
      clothesInfo: '',
      additionalInfo: '',
      publicPolicy: false,
    },
    validate,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  })

  const renderError = (name) => (formik.touched[name] && formik.errors[name])

  const isChildrenBlockShown = () => formik.values.isVpoWithBabies || formik.values.isVpoWithChildren
  const isClothesBlockShown = () => formik.values.isClothesNeeded

  const renderPublicPolicyLabel = () => {
    return (
      <>
        {t('landing.requestForm.publicPolicy.1')}
        <a
          href="https://assets.armiyavolonteriv.com/production/main/public_policy.pdf"
          rel="noreferrer"
          target="_blank"
        >
          {t('landing.requestForm.publicPolicy.linkName')}
        </a>
        {t('landing.requestForm.publicPolicy.2')}
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>{t('landing.requestFormPageTitle')}</title>
        <meta
          content={t('landing.metaDescription.requestFormPage')}
          name="description"
        />
      </Helmet>
      <Flex alignItems="center" style={{ marginTop: '7rem' }} column>
        <Flex alignItems="start" className="container" column>
          <h1>{t('landing.requestForm.title')}</h1>
          <Text landingText paragraph>{t('landing.requestForm.text.1')}</Text>
          <Text landingText paragraph>{t('landing.requestForm.text.2')}</Text>
          <Text landingText paragraph>{t('landing.requestForm.text.3')}</Text>
          <Text landingText paragraph>{t('landing.requestForm.text.4')}</Text>
          <Text landingText>{t('landing.requestForm.text.5')}</Text>
          <ul>
            <li>{t('landing.requestForm.text.6')}</li>
            <li>{t('landing.requestForm.text.7')}</li>
            <li>{t('landing.requestForm.text.8')}</li>
          </ul>
          <Text landingText paragraph>{t('landing.requestForm.text.9')}</Text>
          <Text landingText paragraph>{t('landing.requestForm.text.10')}</Text>
          <Text landingText paragraph>{t('landing.requestForm.text.11')}</Text>
          <Text landingText>{t('landing.requestForm.text.12')}</Text>
          <Text landingText paragraph>{t('landing.requestForm.text.13')}</Text>
        </Flex>

        <Flex alignItems="center" className="container" column>
          <Container formContainer>
            <Flex column>
              <TextInput
                error={renderError('lastName')}
                id="lastName"
                label={t('landing.requestForm.lastName')}
                name="lastName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder={t('lastName')}
                value={formik.values.lastName}
                required
              />
              <TextInput
                error={renderError('firstName')}
                id="firstName"
                label={t('landing.requestForm.firstName')}
                name="firstName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder={t('firstName')}
                value={formik.values.firstName}
                required
              />
              <TextInput
                error={renderError('middleName')}
                id="middleName"
                label={t('landing.requestForm.middleName')}
                name="middleName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder={t('middleName')}
                value={formik.values.middleName}
                required
              />
              <Datepicker
                error={renderError('dateOfBirth')}
                id="dateOfBirth"
                label={t('landing.requestForm.dateOfBirth')}
                maxDate={new Date()}
                name="dateOfBirth"
                onBlur={formik.handleBlur}
                onChange={(value) => formik.setFieldValue('dateOfBirth', value)}
                placeholder={t('chooseDate')}
                placement="bottom-end"
                rightIcon="calendar"
                value={formik.values.dateOfBirth}
                required
                showMonthDropdown
                showYearDropdown
              />
              <PhoneNumberInput
                error={renderError('phoneNumber')}
                id="phoneNumber"
                label={t('landing.requestForm.phoneNumber')}
                name="phoneNumber"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder={t('phoneNumber')}
                value={formik.values.phoneNumber}
                required
              />
            </Flex>
          </Container>
          <Container formContainer>
            <Flex column>
              <Datepicker
                error={renderError('dateOfLeavingHome')}
                id="dateOfLeavingHome"
                label={t('landing.requestForm.dateOfLeavingHome')}
                maxDate={new Date()}
                minDate={dateOfInvasion}
                name="dateOfLeavingHome"
                onBlur={formik.handleBlur}
                onChange={(value) => formik.setFieldValue('dateOfLeavingHome', value)}
                placeholder={t('chooseDate')}
                placement="bottom-end"
                rightIcon="calendar"
                value={formik.values.dateOfLeavingHome}
                required
                showMonthDropdown
                showYearDropdown
              />
              <Datepicker
                error={renderError('vpoStartDate')}
                id="vpoStartDate"
                label={t('landing.requestForm.vpoStartDate')}
                maxDate={new Date()}
                minDate={dateOfInvasion}
                name="vpoStartDate"
                onBlur={formik.handleBlur}
                onChange={(value) => formik.setFieldValue('vpoStartDate', value)}
                placeholder={t('chooseDate')}
                placement="bottom-end"
                rightIcon="calendar"
                value={formik.values.vpoStartDate}
                required
                showMonthDropdown
                showYearDropdown
              />
              <GoogleAutocompleteInput
                // bounds={WAR_ZONE_BOUNDS}
                error={renderError('registeredLocation')}
                id="registeredLocation"
                label={t('landing.requestForm.registeredLocation')}
                name="registeredLocation"
                onBlur={formik.handleBlur}
                onChange={(value) => formik.setFieldValue('registeredLocation', value)}
                placeholder={t('fillAddress')}
                value={formik.values.registeredLocation}
                required
              />
              <GoogleAutocompleteInput
                // bounds={KYIV_BOUNDS}
                error={renderError('currentLocation')}
                id="currentLocation"
                label={t('landing.requestForm.currentLocation')}
                name="currentLocation"
                onBlur={formik.handleBlur}
                onChange={(value) => formik.setFieldValue('currentLocation', value)}
                placeholder={t('fillAddress')}
                value={formik.values.currentLocation}
                required
              />
            </Flex>
          </Container>
          <Container formContainer>
            <Flex column>
              <Checkbox
                checked={formik.values.isVpoWithBabies}
                error={renderError('isVpoWithBabies')}
                id="isVpoWithBabies"
                label={t('landing.requestForm.isVpoWithBabies')}
                labelWrapper={t('landing.requestForm.whatYouNeed')}
                name="isVpoWithBabies"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <Checkbox
                checked={formik.values.isVpoWithChildren}
                error={renderError('isVpoWithChildren')}
                id="isVpoWithChildren"
                label={t('landing.requestForm.isVpoWithChildren')}
                name="isVpoWithChildren"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <Checkbox
                checked={formik.values.isClothesNeeded}
                error={renderError('isClothesNeeded')}
                id="clothes"
                label={t('landing.requestForm.isClothesNeeded')}
                name="isClothesNeeded"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <Checkbox
                checked={formik.values.isHygieneNeeded}
                error={renderError('isHygieneNeeded')}
                id="isHygieneNeeded"
                label={t('landing.requestForm.isHygieneNeeded')}
                name="isHygieneNeeded"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <TextAreaInput
                error={renderError('otherIsNeeded')}
                id="otherIsNeeded"
                label={t('landing.requestForm.otherIsNeeded')}
                name="otherIsNeeded"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder={t('specifyWhatOther')}
                value={formik.values.otherIsNeeded}
              />
            </Flex>
          </Container>
          {isChildrenBlockShown() &&
            <Container formContainer>
              <Flex column>
                <TextAreaInput
                  error={renderError('childrenInfo')}
                  id="childrenInfo"
                  label={t('landing.requestForm.childrenInfo')}
                  name="childrenInfo"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.childrenInfo}
                />
                <TextAreaInput
                  error={renderError('otherIsNeededForChildren')}
                  id="otherIsNeededForChildren"
                  label={t('landing.requestForm.otherIsNeededForChildren')}
                  name="otherIsNeededForChildren"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.otherIsNeededForChildren}
                />
              </Flex>
            </Container>
          }
          {isClothesBlockShown() &&
            <Container formContainer>
              <Flex column>
                <TextAreaInput
                  error={renderError('clothesInfo')}
                  id="clothesInfo"
                  label={t('landing.requestForm.clothesInfo')}
                  name="clothesInfo"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.clothesInfo}
                />
              </Flex>
            </Container>
          }
          <Container formContainer>
            <Flex column>
              <TextAreaInput
                error={renderError('additionalInfo')}
                id="additionalInfo"
                label={t('landing.requestForm.additionalInfo')}
                name="additionalInfo"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.additionalInfo}
              />
              <Checkbox
                checked={formik.values.publicPolicy}
                error={renderError('publicPolicy')}
                id="publicPolicy"
                label={renderPublicPolicyLabel()}
                name="publicPolicy"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <Button
                label={<Text capitalize>{t('save')}</Text>}
                loading={loading}
                onClick={formik.handleSubmit}
                size={Sizes.LARGE}
                fullwidth
              />
            </Flex>
          </Container>
        </Flex>
      </Flex>
    </>
  )
}

export default RequestForm
