import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Section,
  Text,
  Flex,
  Container,
  Sizes,
  Spacer,
  DonateCopyButton,
} from 'ui-kit'
import { useToaster } from 'hooks/useToaster'
import {
  PAYPAL_EMAIL,
  UAH_IBAN,
  USD_IBAN,
  EUR_IBAN,
  monthlyDonates,
} from './constants/payments'
import DonateCard from './components/DonateCard'
import InfoListItem from './components/InfoListItem'
import styles from './Donate.module.scss'
import { Helmet } from 'react-helmet'

const Donate = () => {
  const { t } = useTranslation()
  const { showToaster, toasterTypes } = useToaster(3000)

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  const handleCopyButtonClick = async (copyContent) => {
    await navigator.clipboard.writeText(copyContent)

    showToaster({
      content: <Text>{t('copied')}: {copyContent}</Text>,
      type: toasterTypes.SUCCESS,
    })
  }

  return (
    <>
      <Helmet>
        <title>{t('landing.donatePageTitle')}</title>
        <meta
          content={t('landing.metaDescription.donatePage')}
          name="description"
        />
      </Helmet>
      <Section className={styles.donate} id="donate">
        <h1>{t('landing.donatePage.titles.main')}</h1>
        <Text className="mb-5" landingText paragraph>{t('landing.donatePage.text')}</Text>
        <h3>{t('landing.donatePage.titles.monthly')}</h3>
        <div className={styles.monthlyDonateContainer}>
          {monthlyDonates(t).map(({ image, link, title, buttonLabel }) => (
            <DonateCard
              buttonLabel={buttonLabel}
              image={image}
              key={title}
              link={link}
              title={title}
            />
          ))}
        </div>
        <h3>{t('landing.donatePage.titles.oneTime')}</h3>
        <Flex className={styles.container} justifyContent="between">
          <Container donateContainer>
            <h4>{t('landing.donatePage.containersTitle.monobank')}</h4>
            <DonateCopyButton
              leftImage="monobank_jar.png"
              linkTo="https://send.monobank.ua/jar/76E4cXW2Cm"
            >
              <h5 className="my-3">{t('landing.donatePage.containersTitle.jar')}</h5>
            </DonateCopyButton>
            <Spacer vertical={Sizes.XLARGE} />
            <h4>{t('landing.donatePage.containersTitle.paypal')}</h4>
            <DonateCopyButton
              leftIcon="paypal"
              onClick={() => handleCopyButtonClick(PAYPAL_EMAIL)}
            >
              <h5>PayPal</h5>
              <Text landingText truncated>{PAYPAL_EMAIL}</Text>
            </DonateCopyButton>
          </Container>
          <Container donateContainer>
            <h4>{t('landing.donatePage.containersTitle.swift')}</h4>
            <InfoListItem name="beneficiary" />
            <InfoListItem name="companyAddress" />
            <InfoListItem name="edrpou" />
            <InfoListItem name="beneficiaryBank" />
            <InfoListItem name="swiftCode" />
            <InfoListItem name="paymentDetails" />
            <DonateCopyButton
              leftIcon="UAflag"
              onClick={() => handleCopyButtonClick(UAH_IBAN)}
            >
              <h5>UAH</h5>
              <Text landingText truncated>{UAH_IBAN}</Text>
            </DonateCopyButton>
            <Spacer vertical={Sizes.XLARGE} />
            <DonateCopyButton
              leftIcon="USAflag"
              onClick={() => handleCopyButtonClick(USD_IBAN)}
            >
              <h5>USD</h5>
              <Text landingText truncated>{USD_IBAN}</Text>
            </DonateCopyButton>
            <Spacer vertical={Sizes.XLARGE} />
            <DonateCopyButton
              leftIcon="EUflag"
              onClick={() => handleCopyButtonClick(EUR_IBAN)}
            >
              <h5>EUR</h5>
              <Text landingText truncated>{EUR_IBAN}</Text>
            </DonateCopyButton>
          </Container>
        </Flex>
        <Container>
          <Text landingText paragraph>{t('landing.donatePage.info.1')}</Text>
          <Text landingText paragraph>{t('landing.donatePage.info.2')}</Text>
          <Text landingText paragraph>{t('landing.donatePage.info.3')}</Text>
        </Container>
      </Section>
    </>
  )
}

Donate.propTypes = {}

Donate.defaultProps = {}

export default Donate
