import axios from 'axios'
import { merge } from 'lodash'
import { camelizeKeys } from 'humps'
import appConfig from './appConfig'
import { useToaster } from 'hooks/useToaster'

const interceptRequests = () => {
  axios.defaults.baseURL = appConfig.API_URL
  axios.interceptors.request.use(
    (config) =>
      merge({}, config, {
        headers: merge({}, config.headers, {
          // Authorization: `Bearer ${selectToken(state, 'Auth')}`,
        }),
      }),

    (error) => {
      console.error('API Request Error: --------------------- v')
      return Promise.reject(error)
    },
  )
}

const interceptResponses = () => {
  const { showToaster, toasterTypes } = useToaster()

  axios.interceptors.response.use(
    (response) => {
      return camelizeKeys(response.data)
    },
    (error) => {
      showToaster({
        content: <span>{error.response.data.error}</span>,
        type: toasterTypes.DANGER,
      })
    },
  )
}

const enableAxiosInterceptors = () => {
  interceptRequests()
  interceptResponses()
}

export default enableAxiosInterceptors
