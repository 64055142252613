import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useToaster } from 'hooks/useToaster'
import {
  Button,
  Colors,
  Flex,
  Text,
  DonateCopyButton,
} from 'ui-kit'
import { GenerateFormTokenMutation } from 'queries/tokens.gql'
import appConfig from 'configs/appConfig'

const OneTimeFormLinkGenerator = () => {
  const [token, setToken] = useState('')
  const { t } = useTranslation()
  const { showToaster, toasterTypes } = useToaster(3000)
  const [generate, { loading }] = useMutation(GenerateFormTokenMutation, {
    onCompleted: (data) => {
      if (data.generateFormToken.entity) {
        setToken(data.generateFormToken.entity.hashString)
      }
    },
  })

  const handleCopyButtonClick = async (copyContent) => {
    await navigator.clipboard.writeText(copyContent)

    showToaster({
      content: <Text>{t('copied')}: {copyContent}</Text>,
      type: toasterTypes.SUCCESS,
    })
  }

  const renderLinkContainer = () => {
    if (token) {
      const link = `${appConfig.APP_URL}/request-form?token=${token}`

      return (
        <Flex className="mb-3" style={{ maxWidth: '45rem' }} column>
          <DonateCopyButton onClick={() => handleCopyButtonClick(link)}>
            <Text truncated>{link}</Text>
          </DonateCopyButton>
        </Flex>
      )
    }
  }

  return (
    <>
      {renderLinkContainer()}
      <Button
        color={Colors.PRIMARY}
        label={t('settingsPage.generateToken')}
        loading={loading}
        onClick={generate}
        style={{ maxWidth: '25rem' }}
      />
    </>
  )
}

export default OneTimeFormLinkGenerator
