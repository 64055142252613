import React from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Text } from 'ui-kit'
import { navList } from './navList'
import styles from './MobileNavigation.module.scss'

const MobileNavigation = ({ onNavItemClick }) => {
  const { t } = useTranslation()

  return (
    <ul className={styles.mobileNavList}>
      {navList(t).map(({ label, href }) => (
        <li className={styles.mobileNavItem} key={label}>
          <a href={`/${href}`} onClick={onNavItemClick}>
            <Text>{label}</Text>
          </a>
        </li>
      ))}
    </ul>
  )
}

MobileNavigation.propTypes = {
  onNavItemClick: PT.func.isRequired,
}

export default MobileNavigation
