import React from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Flex, LoadingSpinner } from 'ui-kit'
import PageHeader from 'components/pageHeader/PageHeader'
import { SystemConfigurationQuery } from 'queries/systemConfigurations.gql'
import RequestFormSettings from './components/RequestFormSettings'
import OneTimeFormLinkGenerator from './components/OneTimeFormLinkGenerator'

const Settings = () => {
  const { t } = useTranslation()
  const { data, loading } = useQuery(SystemConfigurationQuery)

  if (loading) return <LoadingSpinner />

  const settings = data?.systemConfiguration || {}

  return (
    <>
      <PageHeader label={t('settingsPage.heading')} />
      <Flex column>
        <h3>{t('settingsPage.requestFormHeading')}:</h3>
        <RequestFormSettings
          loading={loading}
          requestFormEnabled={settings.requestFormEnabled}
          requestsLimit={settings.requestsLimit}
        />
      </Flex>
      <Flex column>
        <h3>{t('settingsPage.oneTimeFormLink')}:</h3>
        <OneTimeFormLinkGenerator />
      </Flex>
    </>
  )
}

export default Settings
