import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import LinkedinIcon from 'ui-kit/components/icons/customIcons/LinkedinIcon'
import styles from './SocialLinks.module.scss'

const LinkedinLink = ({ className, style }) => {
  return (
    <a
      aria-label="linkedin link"
      className={cx(styles.socialLink, className)}
      /* eslint-disable-next-line max-len */
      href="https://www.linkedin.com/company/%D0%B0%D1%80%D0%BC%D1%96%D1%8F-%D0%B2%D0%BE%D0%BB%D0%BE%D0%BD%D1%82%D0%B5%D1%80%D1%96%D0%B2"
      rel="noreferrer"
      style={style}
      target="_blank"
    >
      <LinkedinIcon />
    </a>
  )
}

LinkedinLink.propTypes = {
  className: PT.string,
  style: PT.shape({}),
}

LinkedinLink.defaultProps = {
  className: '',
  style: {},
}

export default LinkedinLink
