import React from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import {
  SwitchInput,
  TextInput,
  Button,
  Colors,
  Flex,
  Text,
  Sizes,
} from 'ui-kit'
import { UpdateSystemConfigurationMutation } from 'queries/systemConfigurations.gql'

const RequestFormSettingsEdit = ({ handleEditMode, requestFormEnabled, requestsLimit }) => {
  const { t } = useTranslation()
  const [update, { loading: isSubmitting }] = useMutation(UpdateSystemConfigurationMutation)

  const formik = useFormik({
    initialValues: {
      requestFormEnabled,
      requestsLimit,
    },
    onSubmit: (values) => handleSubmit(values),
  })

  const handleSubmit = async (values) => {
    const variables = { data: values }
    await update({ variables })
    handleEditMode(false)
  }

  return (
    <>
      <SwitchInput
        checked={formik.values.requestFormEnabled}
        id="requestFormEnabled"
        label={t('settingsPage.requestFormEnabled')}
        name="requestFormEnabled"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
      />
      <TextInput
        id="requestsLimit"
        label={t('settingsPage.requestsLimit')}
        name="requestsLimit"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder={t('settingsPage.requestsLimit')}
        type="number"
        value={formik.values.requestsLimit}
      />
      <Flex className="mt-4">
        <Button
          className="me-4"
          color={Colors.WARNING}
          label={<Text capitalize>{t('cancel')}</Text>}
          loading={isSubmitting}
          onClick={() => handleEditMode(false)}
          size={Sizes.LARGE}
          fullwidth
          outline
        />
        <Button
          label={<Text capitalize>{t('save')}</Text>}
          loading={isSubmitting}
          onClick={formik.handleSubmit}
          size={Sizes.LARGE}
          fullwidth
        />
      </Flex>
    </>
  )
}

RequestFormSettingsEdit.propTypes = {
  handleEditMode: PT.func.isRequired,
  requestFormEnabled: PT.bool.isRequired,
  requestsLimit: PT.number.isRequired,
}

export default RequestFormSettingsEdit
