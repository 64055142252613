import React, { useState } from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import InputWrapper from '../InputWrapper'
import styles from './TextInput.module.scss'

const TextInput = ({
  id,
  placeholder,
  name,
  type,
  onChange,
  onBlur,
  error,
  required,
  disabled,
  label,
  value,
  noWrapper,
  style,
  margin,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState(value)

  const handleChange = (e) => {
    if (onChange) {
      onChange(e)
    } else {
      setInputValue(e.target.value)
    }
  }

  const renderInput = () => (
    <input
      className={cx(styles.textInput, {
        [styles.error]: error,
        [styles.disabled]: disabled,
      })}
      disabled={disabled}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      placeholder={placeholder}
      style={style}
      type={type}
      value={onChange ? value : inputValue}
      {...rest}
    />
  )

  if (noWrapper) {
    return (
      renderInput()
    )
  }

  return (
    <InputWrapper
      error={error}
      id={id}
      label={label}
      margin={margin}
      required={required}
    >
      {renderInput()}
    </InputWrapper>
  )
}

TextInput.propTypes = {
  id: PT.string.isRequired,
  placeholder: PT.string,
  value: PT.oneOfType([PT.number, PT.string]),
  name: PT.string.isRequired,
  type: PT.oneOf(['hidden', 'text', 'password', 'number']),
  margin: PT.string,
  onChange: PT.func,
  onBlur: PT.func,
  error: PT.string,
  required: PT.bool,
  label: PT.string,
  style: PT.shape({}),
  noWrapper: PT.bool,
  disabled: PT.bool,
}

TextInput.defaultProps = {
  placeholder: '',
  value: '',
  type: 'text',
  margin: undefined,
  onChange: null,
  onBlur: null,
  error: '',
  required: false,
  label: '',
  noWrapper: false,
  disabled: false,
  style: {},
}

export default TextInput































