import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { keys } from 'lodash'
import { RequestQuery } from 'queries/requests.gql'
import {
  List,
  Text,
  Flex,
  Badge,
  LoadingSpinner,
  TwoColContainer,
} from 'ui-kit'
import { formatDate, formatDateTime } from 'common/utils/datetime'
import { REQUEST_STATUS_COLOR } from 'common/constants/requestStatusColor'
import PageHeader from 'components/pageHeader/PageHeader'
import RequestCustomInfo from './components/RequestCustomInfo'

const Request = () => {
  const { t } = useTranslation()
  const { requestId } = useParams()

  const { data: requestData, loading } = useQuery(RequestQuery, {
    variables: { id: requestId },
  })

  const request = requestData?.request || {}

  const listData = () => {
    const requestListData = {
      fullName: request.fullName,
      formattedPhoneNumber: request.formattedPhoneNumber,
      dateOfBirth: formatDate(request.dateOfBirth),
      dateOfLeavingHome: formatDate(request.dateOfLeavingHome),
      vpoStartDate: formatDate(request.vpoStartDate),
      registeredLocation: request.registeredLocation,
      currentLocation: request.currentLocation,
      isVpoWithBabies: t(`boolean.${request.isVpoWithBabies}`),
      isVpoWithChildren: t(`boolean.${request.isVpoWithChildren}`),
      isClothesNeeded: t(`boolean.${request.isClothesNeeded}`),
      isHygieneNeeded: t(`boolean.${request.isHygieneNeeded}`),
      otherIsNeeded: request.otherIsNeeded,
      childrenInfo: request.childrenInfo,
      otherIsNeededForChildren: request.otherIsNeededForChildren,
      clothesInfo: request.clothesInfo,
      additionalInfo: request.additionalInfo,
    }

    return (
      keys(requestListData).map(key => (
        <Flex key={key} column>
          <Text bold>{t(`requestFieldLabel.${key}`)}</Text>
          <Text>{requestListData[key]}</Text>
        </Flex>
      ))
    )
  }

  const logData = () => (
    request.requestEvents.map(event => (
      <Flex className="w-100" key={event.id} column>
        <Text bold>{formatDateTime(event.createdAt)}</Text>
        <Text>
          <Text>{t('requestEvent.statusChangedFrom')}</Text>
          <Text bold>&nbsp;&quot;{t(`requestStatus.${event.prevStatus}`)}&quot;</Text>
          <Text>&nbsp;{t('requestEvent.to')}&nbsp;</Text>
          <Text bold>&quot;{t(`requestStatus.${event.currentStatus}`)}&quot;</Text>
        </Text>
        <Text>
          <Text>{t('requestEvent.changedBy')}&nbsp;</Text>
          <Text bold>{event.createdBy || t('requestEvent.system')}</Text>
        </Text>
      </Flex>
    ))
  )

  const renderHeaderTitle = () => {
    if (loading) {
      return `${t('request')} #${requestId}`
    }

    return (
      <Flex className="me-auto" column>
        <Flex alignItems="start">
          <h3 className="text-capitalize me-2">{`${t('request')} #${requestId}`}</h3>
          <Badge
            color={REQUEST_STATUS_COLOR[request.status]}
          >
            {t(`requestStatus.${request.status}`)}
          </Badge>
        </Flex>
        <Text>{formatDateTime(request.createdAt)}</Text>
      </Flex>
    )
  }

  const renderContent = () => {
    if (loading) {
      return (
        <LoadingSpinner marginTop />
      )
    }

    return (
      <TwoColContainer>
        <Flex column>
          <h3>{t('requestPage.editHeading')}:</h3>
          <RequestCustomInfo />
        </Flex>
        <Flex column>
          <h3>{t('requestPage.dataHeading')}:</h3>
          <List data={listData()} style={{ maxWidth: '100%' }} />
          <h3 className="mt-4">{t('requestPage.historyHeading')}:</h3>
          <List data={logData()} style={{ maxWidth: '100%' }} />
        </Flex>
      </TwoColContainer>
    )
  }

  return (
    <>
      <PageHeader label={renderHeaderTitle()} />
      {renderContent()}
    </>
  )
}

export default Request
