import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import appConfig from './appConfig'
import { applyUserSignOut } from '../store/actions/auth'
import { useToaster } from 'hooks/useToaster'
import store from '../store'
import React from 'react'

const httpLink = createHttpLink({
  uri: `${appConfig.API_URL}/graphql`,
})

export const getAuthToken = () => {
  const redux = localStorage.getItem('redux')

  if (redux) {
    const reduxData = JSON.parse(redux)

    return reduxData.auth.tokens.auth
  }
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from redux if it exists
  const token = getAuthToken()
  // return the headers to the context so httpLink can read them

  const authorization = token ? `Bearer ${token}` : 'public'

  return {
    headers: {
      ...headers,
      authorization,
    },
  }
})

// Catch any network or server errors that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  const { showToaster, toasterTypes } = useToaster()

  if (graphQLErrors) {
    showToaster({
      content: <span>{graphQLErrors[0].message}</span>,
      type: toasterTypes.DANGER,
    })
  }
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`)
    if (networkError.statusCode === 419) {
      store.dispatch(applyUserSignOut())
    } else {
      showToaster({
        content: <span>{networkError.result.error}</span>,
        type: toasterTypes.DANGER,
      })
    }
  }
})

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
}

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions,
})

export { client }
