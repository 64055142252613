export const PRIMARY = 'primary'
export const SECONDARY = 'secondary'
export const SUCCESS = 'success'
export const DANGER = 'danger'
export const WARNING = 'warning'
export const INFO = 'info'
export const LIGHT = 'light'
export const DARK = 'dark'
export const WHITE = 'white'
export const YELLOW = 'yellow'
export const LANDING_PRIMARY = 'landingPrimary'
export const LANDING_BLACK = 'landingBlack'
export const LANDING_GREY = 'landingGrey'

export const Colors = {
  PRIMARY,
  SECONDARY,
  SUCCESS,
  DANGER,
  WARNING,
  INFO,
  LIGHT,
  DARK,
  WHITE,
  YELLOW,
  LANDING_PRIMARY,
  LANDING_BLACK,
  LANDING_GREY,
}

export default Colors
