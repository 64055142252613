import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from '@apollo/client'
import { pickBy } from 'lodash'
import { Flex, Text, LoadingSpinner } from 'ui-kit'
import UserForm from './components/UserForm'
import { useToaster } from 'hooks/useToaster'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { UserQuery, UpdateUserMutation } from 'queries/users.gql'

const UserEdit = () => {
  const { t } = useTranslation()
  const { currentUser, isSuperadmin, isAdmin } = useCurrentUser()
  const { showToaster, toasterTypes } = useToaster()
  const navigate = useNavigate()
  const { userId } = useParams()

  useEffect(() => {
    if (!isSuperadmin && !isAdmin && currentUser.id !== userId) {
      navigate('/admin/users')
    }
  }, [userId])

  const { data, loading } = useQuery(UserQuery, {
    variables: { id: userId },
  })

  const [updateUser, { loading: isSubmitting }] = useMutation(UpdateUserMutation, {
    onCompleted: (data) => {
      if (data.updateUser.entity) {
        navigate('/admin/users')
        showToaster({
          content: <Text>{t('userToasts.userSuccessfullyUpdated')}</Text>,
          type: toasterTypes.SUCCESS,
        })
      } else if (data.updateUser.errors?.length) {
        showToaster({
          content: (<Flex column>
            {data.updateUser.errors.map(({ message }) => <Text key={message}>{message}</Text>)}
          </Flex>),
          type: toasterTypes.DANGER,
        })
      }
    },
  })

  const user = data?.user || {}

  const handleSubmit = async (values) => {
    const variables = {
      id: userId,
      data: { ...pickBy(values), role: values.role.value },
    }
    await updateUser({ variables })
  }

  if (loading) return <LoadingSpinner marginTop />

  return (
    <>
      <h1>{t('updateUser')}</h1>
      <UserForm
        isLoading={loading}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
        user={user}
      />
    </>
  )
}

UserEdit.propTypes = {}

UserEdit.defaultProps = {}

export default UserEdit
