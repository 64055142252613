import React, { useState } from 'react'
import PT from 'prop-types'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  Button,
  Text,
  Margin,
  Flex,
  Sizes,
  Spacer,
  Colors,
} from 'ui-kit'

const ConfirmModal = ({
  onConfirm,
  title,
  touched,
  style,
  children,
  buttonLabel,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const { t } = useTranslation()
  const windowWidth = window.innerWidth

  const renderModal = () => {
    if (touched && isEmpty(touched)) return onConfirm()

    return setOpenModal(true)
  }

  return (
    <>
      {openModal && (
        <Modal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          style={style}
          {...windowWidth > 767 && { width: '400px' }}
        >
          <Margin bottom={Sizes.XXLARGE}>
            <Flex
              justifyContent="center">
              <Text
                align="center"
                bold
            >
                {title}
              </Text>
            </Flex>
          </Margin>
          <Flex justifyContent="center" row>
            <Button
              color={Colors.SUCCESS}
              label={
                <Text capitalize>
                  {t('cancel')}
                </Text>}
              onClick={() => setOpenModal(false)}
              size={Sizes.LARGE}
              fullwidth
              outline
            />
            <Spacer horizontal={Sizes.SMALL} />
            <Button
              color={Colors.DANGER}
              label={
                <Text capitalize>
                  {buttonLabel}
                </Text>
                  }
              onClick={onConfirm}
              size={Sizes.LARGE}
              fullwidth
            />
          </Flex>
        </Modal>
      )}
      {children(renderModal)}
    </>
  )
}

ConfirmModal.propTypes = {
  touched: PT.shape(),
  children: PT.func.isRequired,
  onConfirm: PT.func,
  title: PT.string,
  buttonLabel: PT.string,
  style: PT.shape({}),
}

ConfirmModal.defaultProps = {
  style: {},
}

export default ConfirmModal
