import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { pickBy } from 'lodash'
import { Flex, Text } from 'ui-kit'
import UserForm from './components/UserForm'
import { useToaster } from 'hooks/useToaster'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { CreateUserMutation } from 'queries/users.gql'

const UserNew = () => {
  const { t } = useTranslation()
  const { isSuperadmin } = useCurrentUser()
  const { showToaster, toasterTypes } = useToaster()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isSuperadmin) {
      navigate('/admin/users')
    }
  }, [])

  const [createUser, { loading: isSubmitting }] = useMutation(CreateUserMutation, {
    onCompleted: (data) => {
      if (data.createUser.entity) {
        navigate('/admin/users')
        showToaster({
          content: <Text>{t('userToasts.userSuccessfullyCreated')}</Text>,
          type: toasterTypes.SUCCESS,
        })
      } else if (data.createUser.errors?.length) {
        showToaster({
          content: (<Flex column>
            {data.createUser.errors.map(({ message }) => <Text key={message}>{message}</Text>)}
          </Flex>),
          type: toasterTypes.DANGER,
        })
      }
    },
  })

  const handleSubmit = async (values) => {
    const variables = {
      data: { ...pickBy(values), role: values.role.value },
    }
    await createUser({ variables })
  }

  return (
    <>
      <h1>{t('createUser')}</h1>
      <UserForm
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
      />
    </>
  )
}

export default UserNew
