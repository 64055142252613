import React, { useMemo } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { pickBy, isEmpty } from 'lodash'
import { useFormik } from 'formik'
import {
  TextInput,
  SelectInput,
  Container,
  Sizes,
  Button,
  Flex,
  Colors,
  Text,
  LoadingSpinner,
} from 'ui-kit'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { USER_ROLES } from 'common/constants/userRoles'
import { validateEmail, validateRequiredField, validateEqualFields } from 'common/utils/validators'

const UserForm = ({ user, onSubmit, isLoading, isSubmitting }) => {
  const { t } = useTranslation()
  const { isSuperadmin } = useCurrentUser()
  const navigate = useNavigate()

  const validate = (values) => {
    const errors = {}
    errors.email = validateRequiredField(values.email, t) || validateEmail(values.email, t)
    errors.firstName = validateRequiredField(values.firstName, t)
    errors.lastName = validateRequiredField(values.lastName, t)
    errors.phoneNumber = validateRequiredField(values.phoneNumber, t)
    errors.password = isEmpty(user) ? validateRequiredField(values.password, t) : undefined
    errors.passwordConfirmation = isEmpty(user) ? validateRequiredField(values.passwordConfirmation, t) : undefined
    errors.password = validateEqualFields(
      values.password,
      values.passwordConfirmation,
      t('validationError.passwordNotEqual'),
    )
    errors.passwordConfirmation = validateEqualFields(
      values.password,
      values.passwordConfirmation,
      t('validationError.passwordNotEqual'),
    )
    errors.role = validateRequiredField(values.role, t)

    return pickBy(errors)
  }

  const formik = useFormik({
    initialValues: {
      email: user.email || '',
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      phoneNumber: user.phoneNumber || '',
      password: undefined,
      passwordConfirmation: undefined,
      role: user?.role ? { label: t(`userRole.${user.role}`), value: user.role } : null,
    },
    validate,
    onSubmit,
    enableReinitialize: true,
  })

  const rolesOptions = useMemo(() => (
    USER_ROLES.map((value) => ({
      label: t(`userRole.${value}`),
      value,
    }))
  ), [])

  const handleSelect = (value) => {
    formik.setFieldValue('role', value)
  }

  const renderError = (name) => (formik.touched[name] && formik.errors[name])

  if (isLoading) return <LoadingSpinner marginTop />

  return (
    <Container formContainer>
      <Flex column>
        <TextInput
          error={renderError('email')}
          id="email"
          label={t('email')}
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('email')}
          value={formik.values.email}
          required
        />
        <TextInput
          error={renderError('firstName')}
          id="firstName"
          label={t('firstName')}
          name="firstName"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('firstName')}
          value={formik.values.firstName}
          required
        />
        <TextInput
          error={renderError('lastName')}
          id="lastName"
          label={t('lastName')}
          name="lastName"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('lastName')}
          value={formik.values.lastName}
          required
        />
        <TextInput
          error={renderError('phoneNumber')}
          id="phoneNumber"
          label={t('phoneNumber')}
          name="phoneNumber"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('phoneNumber')}
          value={formik.values.phoneNumber}
          required
        />
        <TextInput
          autoComplete="new-password"
          error={formik.touched.password && formik.touched.passwordConfirmation && formik.errors.password}
          id="password"
          label={t('password')}
          name="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('password')}
          required={isEmpty(user)}
          type="password"
          value={formik.values.password}
        />
        <TextInput
          autoComplete="new-password"
          error={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
          id="passwordConfirmation"
          label={t('passwordConfirmation')}
          name="passwordConfirmation"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder={t('passwordConfirmation')}
          required={isEmpty(user)}
          type="password"
          value={formik.values.passwordConfirmation}
        />
        {isSuperadmin && <SelectInput
          error={renderError('role')}
          id="role"
          label={t('role')}
          name="role"
          onChange={handleSelect}
          options={rolesOptions}
          value={formik.values.role}
          fullwidth
          required
        /> }
        <Flex>
          <Button
            className="me-4"
            color={Colors.WARNING}
            label={<Text capitalize>{t('cancel')}</Text>}
            loading={isSubmitting}
            onClick={() => navigate('/admin/users')}
            size={Sizes.LARGE}
            fullwidth
            outline
          />
          <Button
            label={<Text capitalize>{t('save')}</Text>}
            loading={isSubmitting}
            onClick={formik.handleSubmit}
            size={Sizes.LARGE}
            fullwidth
          />
        </Flex>
      </Flex>
    </Container>
  )
}

UserForm.propTypes = {
  user: PT.shape({
    email: PT.string,
    firstName: PT.string,
    lastName: PT.string,
    phoneNumber: PT.string,
    role: PT.string,
  }),
  onSubmit: PT.func.isRequired,
  isSubmitting: PT.bool,
  isLoading: PT.bool,
}

UserForm.defaultProps = {
  user: {},
  isSubmitting: false,
  isLoading: false,
}

export default UserForm
