import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en from '../locales/en.json'
import ua from '../locales/ua.json'

const initLocales = () => {
  const lng = localStorage.getItem('lang') || 'ua'

  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      detection: {
        order: ['localStorage'],
        lookupLocalStorage: 'i18Lng',
      },
      resources: {
        en: {
          translation: en,
        },
        ua: {
          translation: ua,
        },
      },
      lng,
      fallbackLng: 'en',

      interpolation: {
        escapeValue: false,
      },
    })
}

export default initLocales
