import React from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { filter, find } from 'lodash'
import { RequestEventsQuery } from 'queries/requestEvents.gql'
import { UsersQuery } from 'queries/users.gql'
import { getPaginationData } from 'common/utils/getPaginationData'
import { formatDateTime } from 'common/utils/datetime'
import { useQueryParams } from 'hooks/useQueryParams'
import PageHeader from 'components/pageHeader/PageHeader'
import {
  Table,
  Pagination,
  Text,
  Dropdown,
  Flex,
  Button,
  Colors,
  SortDropdown,
} from 'ui-kit'

const RequestEvents = () => {
  const { t } = useTranslation()
  const { queryParams, setQueryParams, removeQueryParam } = useQueryParams()

  const sort = queryParams.sort
  const page = parseInt(queryParams.page) || 1
  const userId = queryParams.userId

  const queryVariables = {
    sort,
    page,
    userId,
  }

  const { data, loading } = useQuery(RequestEventsQuery, { variables: queryVariables })
  const { data: usersData } = useQuery(UsersQuery, { variables: { perPage: 100, sort: 'first_name.asc' } })

  const requestEvents = data?.requestEvents?.data || []
  const paginationData = getPaginationData(data, 'requestEvents')

  const usersOptions = () => {
    const users = usersData?.users?.data || []
    const renderedOptions = users.map((user) => (
      {
        key: user.id,
        title: user.fullName,
        handler: () => setQueryParams({ userId: user.id }),
      }
    ))

    renderedOptions.splice(
      0, 0,
      {
        key: 'emptyCreatedBy',
        title: t('requestEvent.all'),
        handler: () => removeQueryParam('userId'),
      },
    )

    return renderedOptions
  }

  const decorateTitles = () => (
    [
      { name: 'id', label: <Text uppercase>{t('id')}</Text> },
      { name: 'request_id', label: <Text capitalize>{t('request')}</Text>, sorting: true },
      { name: 'prevStatus', label: <Text capitalize>{t('requestEvent.prevStatus')}</Text> },
      { name: 'currentStatus', label: <Text capitalize>{t('requestEvent.currentStatus')}</Text> },
      { name: 'createdBy', label: <Text capitalize>{t('requestEvent.createdBy')}</Text> },
      { name: 'created_at', label: <Text capitalize>{t('createdAt')}</Text>, sorting: true },
    ]
  )

  const decorateData = () => (
    requestEvents.map((requestEvent) => (
      {
        id: requestEvent.id,
        cells: [
          { name: 'id', content: requestEvent.id },
          {
            name: 'requestId',
            content: (
              <Link to={`/admin/requests/${requestEvent.requestId}`}>
                #{requestEvent.requestId}
              </Link>
            ),
          },
          { name: 'prevStatus', content: t(`requestStatus.${requestEvent.prevStatus}`) },
          { name: 'currentStatus', content: t(`requestStatus.${requestEvent.currentStatus}`) },
          { name: 'createdBy', content: requestEvent.createdBy || t('requestEvent.system') },
          { name: 'createdAt', content: formatDateTime(requestEvent.createdAt) },
        ],
      }
    ))
  )

  const userFilterTitle = () => {
    if (!userId) return t('requestEvent.createdBy')

    const selectedOption = find(usersOptions(), (option) => option.key === userId)
    return selectedOption.title
  }

  const sortOptions = () => filter(decorateTitles(), (o) => !!o.sorting)

  return (
    <>
      <PageHeader label={t('requestEvents')} />
      <Flex className="mb-4">
        <Dropdown className="me-2" options={usersOptions()}>
          <Button
            className="px-5"
            color={Colors.PRIMARY}
            iconName="user"
            label={<Text capitalize>{userFilterTitle()}</Text>}
            outline
          />
        </Dropdown>
      </Flex>
      <SortDropdown className="my-3" options={sortOptions()} />
      <Table data={decorateData()} loading={loading} titles={decorateTitles()} />
      <Pagination
        {...paginationData}
        loading={loading}
        withResults
      />
    </>
  )
}

RequestEvents.propTypes = {}

RequestEvents.defaultProps = {}

export default RequestEvents
