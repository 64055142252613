import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import Text from '../text/Text'
import styles from './Tabs.module.scss'
const Tab = ({ label, disabled, handler, active, badge }) => {
  const classnames = cx(
    styles.tab,
    {
      [styles.active]: active,
      [styles.disabled]: disabled,
    },
  )

  return (
    <li className="nav-item" {...handler && { onClick: handler }}>
      <Text
        className={classnames}
      >
        {label}
        {badge}
      </Text>
    </li>
  )
}

Tab.propTypes = {
  label: PT.string.isRequired,
  disabled: PT.bool,
  handler: PT.func,
  active: PT.bool,
  badge: PT.element,
}

Tab.defaultProps = {
  disabled: false,
  handler: null,
  active: false,
  badge: null,
}

export default Tab
