import cx from 'classnames'
import classes from './Toaster.module.scss'

//////////////////
// available types: 'success', 'warning', 'danger', 'info', 'light'
// content: any node
// rest props you can checkout here: https://blueprintjs.com/docs/#core/components/toast.toast
//////////////////
export const toasterProps = ({ type, content, ...rest }) => {
  const toasterClassnames = cx(
    classes.toaster, classes[type],
  )

  return {
    className: toasterClassnames,
    message: content,
    ...rest,
  }
}
