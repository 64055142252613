import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import Header from './components/Header'
import Footer from './components/Footer'
import { Flex } from 'ui-kit'
import { LandingDataContext } from 'contexts'
import { LandingDataQuery } from 'queries/landingData.gql'
import { navList } from './components/navList'
import 'ui-kit/styles/landingCore.scss'

const Layout = () => {
  const [activeNav, setActiveNav] = useState(0)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data } = useQuery(LandingDataQuery)

  const handleChangeActiveNav = (newValue) => {
    // if currently there is no active tab
    if (newValue === false) {
      setActiveNav(newValue)
    } else {
      const sectionHref = navList(t)[newValue].href
      navigate(`/${sectionHref}`)
      setActiveNav(newValue)
    }
  }

  const { requestFormEnabled, closedRequests } = data?.landingData || { requestFormEnabled: null, closedRequests: 0 }

  return (
    <LandingDataContext.Provider
      value={{
        activeNav,
        requestFormEnabled,
        closedRequests,
        handleChangeActiveNav,
      }}
    >
      <Header />
      <Flex grow={1} shrink={0} column>
        <Outlet />
      </Flex>
      <Footer />
    </LandingDataContext.Provider>
  )
}

export default Layout
