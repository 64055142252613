import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import {
  Icon,
  Colors,
  Sizes,
} from 'ui-kit'
import Divider from './Divider'
import Navigation from './Navigation'
import styles from './Sidebar.module.scss'

const Sidebar = ({ isOpened, onSidebarToggle }) => {
  const containerClasses = cx(styles.sidebar, {
    [styles.isOpened]: isOpened,
  })
  return (
    <div className={containerClasses}>
      <Icon
        className={styles.iconMenu}
        color={Colors.WHITE}
        name="x"
        onClick={onSidebarToggle}
        size={Sizes.XLARGE}
      />
      <div className={styles.brand}>
        <Icon name="logo" size={Sizes.LARGE} style={{ width: '4rem', height: '3.5rem' }} />
      </div>
      <Divider />
      <Navigation onSidebarToggle={onSidebarToggle} />
    </div>
  )
}

Sidebar.propTypes = {
  isOpened: PT.bool,
  onSidebarToggle: PT.func,
}

Sidebar.defaultProps = {
  isOpened: false,
}

export default Sidebar
