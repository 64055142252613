import { createContext } from 'react'

const LandingDataContext = createContext({
  activeNav: 0,
  closedRequests: 0,
  requestFormEnabled: true,
  handleChangeActiveNav: () => {},
})

export default LandingDataContext
