import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'

import Sizes from '../../sizes'
import Colors from '../../colors'

import classes from './Icon.module.scss'

import outlineIconComponents from './outlineIconsList'
import solidIconComponents from './solidIconsList'

const ICON_TYPES = {
  solid: solidIconComponents,
  outline: outlineIconComponents,
}

const Icon = ({ type, name, className, color, size, onClick, style }) => {
  const iconClasses = cx(classes.icon, className, {
    [`text-${color}`]: color,
    [classes.xlarge]: size === Sizes.XLARGE,
    [classes.large]: size === Sizes.LARGE,
    [classes.normal]: size === Sizes.NORMAL,
    [classes.small]: size === Sizes.SMALL,
    [classes.xsmall]: size === Sizes.XSMALL,
    [classes.pointer]: !!onClick,
  })
  const Element = ICON_TYPES[type][name]

  return Element ? <Element className={iconClasses} onClick={onClick} style={style} /> : null
}

Icon.propTypes = {
  className: PT.string,
  color: PT.string,
  name: PT.string.isRequired,
  onClick: PT.func,
  size: PT.oneOf([Sizes.XSMALL, Sizes.SMALL, Sizes.NORMAL, Sizes.LARGE, Sizes.XLARGE]),
  style: PT.shape({}),
  type: PT.oneOf(['solid', 'outline']),
}

Icon.defaultProps = {
  className: '',
  color: Colors.PRIMARY,
  onClick: null,
  type: 'outline',
  size: Sizes.NORMAL,
  style: null,
}

export default Icon
