import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import InputWrapper from '../InputWrapper'
import styles from './TextAreaInput.module.scss'

const TextAreaInput = ({
  id,
  placeholder,
  name,
  onChange,
  onBlur,
  error,
  required,
  disabled,
  label,
  value,
  noWrapper,
  style,
}) => {
  const renderInput = () => (
    <textarea
      className={cx(styles.textAreaInput, {
        [styles.error]: error,
        [styles.disabled]: disabled,
      })}
      disabled={disabled}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      placeholder={placeholder}
      style={style}
      value={value}
    />
  )

  if (noWrapper) {
    return (
      renderInput()
    )
  }

  return (
    <InputWrapper
      error={error}
      id={id}
      label={label}
      required={required}
    >
      {renderInput()}
    </InputWrapper>
  )
}

TextAreaInput.propTypes = {
  id: PT.string.isRequired,
  placeholder: PT.string,
  value: PT.string,
  name: PT.string.isRequired,
  onChange: PT.func,
  onBlur: PT.func,
  error: PT.string,
  required: PT.bool,
  label: PT.string,
  style: PT.shape({}),
  noWrapper: PT.bool,
  disabled: PT.bool,
}

TextAreaInput.defaultProps = {
  placeholder: '',
  value: '',
  onChange: () => {},
  onBlur: () => {},
  error: '',
  required: false,
  label: '',
  noWrapper: false,
  disabled: false,
  style: {},
}

export default TextAreaInput
