import {
  AdjustmentsIcon,
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  InboxIcon,
  TrashIcon,
  UsersIcon,
  UserIcon,
  XIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PencilIcon,
  PhoneIcon,
  MenuIcon,
  RefreshIcon,
  PlusIcon,
  CollectionIcon,
  LogoutIcon,
  MinusSmIcon,
  CogIcon,
  DuplicateIcon,
  ExternalLinkIcon,
  ArrowCircleLeftIcon,
  ClockIcon,
  DocumentDownloadIcon,
} from '@heroicons/react/outline'

import LoadingIcon from './customIcons/LoadingIcon'
import LogoIcon from './customIcons/LogoIcon'
import MonoIcon from './customIcons/MonoIcon'
import PaypalIcon from './customIcons/PaypalIcon'
import UAflagIcon from './customIcons/UAflagIcon'
import USAflagIcon from './customIcons/USAflagIcon'
import EUflagIcon from './customIcons/EUflagIcon'

const outlineIconComponents = {
  x: XIcon,
  chevronRight: ChevronRightIcon,
  chevronLeft: ChevronLeftIcon,
  chevronDown: ChevronDownIcon,
  chevronUp: ChevronUpIcon,
  users: UsersIcon,
  user: UserIcon,
  inbox: InboxIcon,
  adjustments: AdjustmentsIcon,
  trash: TrashIcon,
  phone: PhoneIcon,
  calendar: CalendarIcon,
  check: CheckIcon,
  pencil: PencilIcon,
  menu: MenuIcon,
  refresh: RefreshIcon,
  plus: PlusIcon,
  loading: LoadingIcon,
  collection: CollectionIcon,
  logout: LogoutIcon,
  minusSmall: MinusSmIcon,
  settings: CogIcon,
  logo: LogoIcon,
  mono: MonoIcon,
  paypal: PaypalIcon,
  duplicate: DuplicateIcon,
  UAflag: UAflagIcon,
  USAflag: USAflagIcon,
  EUflag: EUflagIcon,
  externalLink: ExternalLinkIcon,
  arrowCircleLeft: ArrowCircleLeftIcon,
  clock: ClockIcon,
  documentDownload: DocumentDownloadIcon,
}

export default outlineIconComponents
