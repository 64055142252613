import { Colors } from '../../ui-kit'

export const REQUEST_STATUS_COLOR = {
  'all': Colors.LIGHT,
  'need_check': Colors.WARNING,
  'initial': Colors.LIGHT,
  'verifying': Colors.SUCCESS,
  'collecting': Colors.DANGER,
  'done': Colors.YELLOW,
  'rejected': Colors.PRIMARY,
  'on_stop': Colors.WARNING,
  'soon': Colors.INFO,
}
