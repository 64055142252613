import React from 'react'
import PT from 'prop-types'
import ImageGallery from 'react-image-gallery'

const PhotoGallery = ({ photos }) => {
  return (
    <ImageGallery
      items={photos}
      showPlayButton={false}
      showThumbnails={false}
      lazyLoad
      showBullets
    />
  )
}

PhotoGallery.propTypes = {
  photos: PT.arrayOf(PT.shape({
    original: PT.string.isRequired,
  })).isRequired,
}

PhotoGallery.defaultProps = {}

export default PhotoGallery
