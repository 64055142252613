import React from 'react'
import PT from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from 'hooks/useCurrentUser'
import NavListDesktop from './NavListDesktop'
import NavListMobile from './NavListMobile'

const Navigation = ({ onSidebarToggle }) => {
  const location = useLocation()
  const { t } = useTranslation()
  const { isSuperadmin, isAdmin } = useCurrentUser()

  const navList = [
    {
      icon: 'users',
      label: t('team'),
      link: '/admin/users',
      isActive: location.pathname.match('/users'),
    },
    {
      icon: 'inbox',
      label: t('requests'),
      link: '/admin/requests',
      isActive: location.pathname.match('/requests'),
    },
    {
      icon: 'collection',
      label: t('store'),
      link: '/admin/store',
      isActive: location.pathname.match('/store'),
    },
    {
      icon: 'clock',
      label: t('requestEvents'),
      link: '/admin/request-events',
      isActive: location.pathname.match('/request-events'),
    },
    ...(isSuperadmin || isAdmin) ? [
      {
        icon: 'phone',
        label: t('contacts'),
        link: '/admin/contacts',
        isActive: location.pathname.match('/contacts'),
      },
      {
        icon: 'documentDownload',
        label: t('export'),
        link: '/admin/export',
        isActive: location.pathname.match('/export'),
      },
      {
        icon: 'settings',
        label: t('settings'),
        link: '/admin/settings',
        isActive: location.pathname.match('/settings'),
      },
    ] : [],
  ]

  return (
    <>
      <NavListMobile navList={navList} onSidebarToggle={onSidebarToggle} />
      <NavListDesktop navList={navList} />
    </>
  )
}

Navigation.propTypes = {
  onSidebarToggle: PT.func,
}

export default Navigation
