export const validateRequiredField = (value, t) => {
  if ((!value && value !== 0) || value === '') {
    return t('validationError.required')
  } else return undefined
}

export const validatePhoneNumberField = (value, t) => {
  if ((!value && value !== 0) || value === '') {
    return t('validationError.required')
  } else if (value.replace(/\D/g, '').length !== 12) {
    return t('validationError.phoneNumber')
  } else return undefined
}

export const validateEmail = (email, t) => {
  if (email) {
    const validateEmail = email.toLowerCase().match(
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
    if (!validateEmail) {
      return t('validationError.email')
    }
  }
  return undefined
}

export const validateEqualFields = (value1, value2, errorMessage = '') => {
  if (!value1 && !value2) return undefined

  if (value1 !== value2) {
    return errorMessage
  } else return undefined
}

export const validateOneOfRequiredFields = (value1, value2, t) => {
  if (!value1 && !value2) {
    return t('validationError.requiredOne')
  } else return undefined
}

export const validateMinimumFieldLength = (value, t, length) => {
  if ((!value && value !== 0) || value === '') {
    return t('validationError.required')
  } else if (value.length < length) {
    return `${t('validationError.smallLength.1')} ${length} ${t('validationError.smallLength.2')}`
  } else return undefined
}
