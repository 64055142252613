import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { LandingDataContext } from 'contexts'
import { Flex, Icon } from 'ui-kit'
import { navList } from './navList'
import styles from './Navigation.module.scss'

const Navigation = () => {
  const { activeNav, handleChangeActiveNav } = useContext(LandingDataContext)
  const { t } = useTranslation()

  return (
    <Flex shrink={0}>
      <a className={styles.logo} href="/#our-mission">
        <Icon name="logo" />
      </a>
      <Tabs
        className={styles.navTabs}
        component="nav"
        onChange={(e, value) => handleChangeActiveNav(value)}
        value={activeNav}
      >
        {navList(t).map(({ label, href }) => (
          <Tab
            component="a"
            href={href}
            key={label}
            label={label}
          />
        ))}
      </Tabs>
    </Flex>
  )
}

export default Navigation
