export const teamMembers = (t) => (
  [
    {
      firstName: t('landing.team.iaroslav_gladilin.firstName'),
      lastName: t('landing.team.iaroslav_gladilin.lastName'),
      photo: 'https://assets.armiyavolonteriv.com/production/team_members/iaroslav_gladilin.webp',
    },
    {
      firstName: t('landing.team.natalya_timofeeva.firstName'),
      lastName: t('landing.team.natalya_timofeeva.lastName'),
      photo: 'https://assets.armiyavolonteriv.com/production/team_members/natalya_timofeeva.webp',
    },
    {
      firstName: t('landing.team.natalia_malashta.firstName'),
      lastName: t('landing.team.natalia_malashta.lastName'),
      photo: 'https://assets.armiyavolonteriv.com/production/team_members/natalia_malashta.webp',
    },
    {
      firstName: t('landing.team.mykhailo_cherkashyn.firstName'),
      lastName: t('landing.team.mykhailo_cherkashyn.lastName'),
      photo: 'https://assets.armiyavolonteriv.com/production/team_members/mykhailo_cherkashyn.webp',
    },
    {
      firstName: t('landing.team.kateryna_panasenkova.firstName'),
      lastName: t('landing.team.kateryna_panasenkova.lastName'),
      photo: 'https://assets.armiyavolonteriv.com/production/team_members/kateryna_panasenkova.webp',
    },
    {
      firstName: t('landing.team.ivan_holub.firstName'),
      lastName: t('landing.team.ivan_holub.lastName'),
      photo: 'https://assets.armiyavolonteriv.com/production/team_members/ivan_holub.webp',
    },
    {
      firstName: t('landing.team.olga_perekhrest.firstName'),
      lastName: t('landing.team.olga_perekhrest.lastName'),
      photo: 'https://assets.armiyavolonteriv.com/production/team_members/olga_perekhrest.webp',
    },
    {
      firstName: t('landing.team.olena_kerysh.firstName'),
      lastName: t('landing.team.olena_kerysh.lastName'),
      photo: 'https://assets.armiyavolonteriv.com/production/team_members/kerysh_olena.webp',
    },
  ]
)
