import { pickBy, isEmpty } from 'lodash'
export const generateUrl = (path, params) => {
  const nonEmptyParams = pickBy(params)
  if (isEmpty(nonEmptyParams)) {
    return path
  } else {
    let queryParams = ''

    Object.entries(nonEmptyParams).map(([name, value]) => {
      queryParams = queryParams + '&' + name + '=' + encodeURIComponent(value)
    })
    return `${path}?${queryParams}`
  }
}
