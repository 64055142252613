import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import {
  Container,
  LoadingSpinner,
} from 'ui-kit'
import { RequestQuery } from 'queries/requests.gql'

import RequestForm from './RequestForm'
import RequestInfoView from './RequestInfoView'

const RequestCustomInfo = () => {
  const { requestId } = useParams()

  const [editMode, setEditMode] = useState(false)
  const { data, loading, refetch } = useQuery(RequestQuery, {
    variables: { id: requestId },
  })

  const request = data?.request || {}

  const renderContent = () => {
    if (loading && !data) return <LoadingSpinner marginTop />

    if (editMode) return (
      <RequestForm
        handleEditMode={setEditMode}
        refetchRequest={refetch}
        request={request}
      />
    )

    return (
      <RequestInfoView
        handleEditMode={setEditMode}
        request={request}
      />
    )
  }

  return (
    <Container formContainer>
      {renderContent()}
    </Container>
  )
}

export default RequestCustomInfo
