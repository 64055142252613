import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import FacebookIcon from 'ui-kit/components/icons/customIcons/FacebookIcon'
import styles from './SocialLinks.module.scss'

const FacebookLink = ({ className, style }) => {
  return (
    <a
      aria-label="facebook link"
      className={cx(styles.socialLink, className)}
      href="https://www.facebook.com/ArmiyaVolonteriv"
      rel="noreferrer"
      style={style}
      target="_blank"
    >
      <FacebookIcon />
    </a>
  )
}

FacebookLink.propTypes = {
  className: PT.string,
  style: PT.shape({}),
}

FacebookLink.defaultProps = {
  className: '',
  style: {},
}

export default FacebookLink
