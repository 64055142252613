import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import { Flex, Text } from 'ui-kit'
import styles from './ProjectInfo.module.scss'
import PhotoGallery from './PhotoGallery'

const ProjectInfo = ({ title, text, photos, reversed }) => {
  const containerClasses = cx(styles.projectInfo, {
    [styles.reversed]: reversed,
  })
  return (
    <Flex alignItems="center" className={containerClasses} gap="3rem" justifyContent="between">
      <Flex className={styles.infoContainer}>
        <PhotoGallery photos={photos} />
      </Flex>
      <Flex className={styles.infoContainer} column>
        <h3>{title}</h3>
        <Text landingText>{text}</Text>
      </Flex>
    </Flex>
  )
}

ProjectInfo.propTypes = {
  reversed: PT.bool,
  title: PT.string.isRequired,
  text: PT.string.isRequired,
  photos: PT.arrayOf(PT.shape({
    original: PT.string.isRequired,
  })).isRequired,
}

ProjectInfo.defaultProps = {
  reversed: false,
}

export default ProjectInfo
