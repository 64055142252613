import React from 'react'
import PT from 'prop-types'
import styles from './PartnerLogo.module.scss'

const PartnerLogo = ({ src, alt }) => {
  return (
    <div className={styles.partnerLogo}>
      <img alt={alt} loading="lazy" src={src} />
    </div>
  )
}

PartnerLogo.propTypes = {
  src: PT.string.isRequired,
  alt: PT.string.isRequired,
}

export default PartnerLogo
