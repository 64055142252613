import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import styles from './Section.module.scss'

const Section = ({ children, id, className, style, tag, observer, noContainer }) => {
  const Element = tag
  const classes = cx(styles.section, className, {
    ['container']: !noContainer,
  })

  return (
    <Element className={classes} id={id} ref={observer} style={style}>
      {children}
    </Element>
  )
}

Section.propTypes = {
  children: PT.node.isRequired,
  id: PT.string.isRequired,
  tag: PT.string,
  observer: PT.func,
  noContainer: PT.bool,
  className: PT.string,
  style: PT.shape({}),
}

Section.defaultProps = {
  observer: null,
  tag: 'section',
  noContainer: false,
  className: '',
  style: {},
}

export default Section
