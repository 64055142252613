export const peopleAssistancePhotos = [
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/people_assistance/1pa.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/people_assistance/2pa.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/people_assistance/3pa.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/people_assistance/4pa.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/people_assistance/5pa.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/people_assistance/6pa.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/people_assistance/7pa.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/people_assistance/8pa.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/people_assistance/9pa.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/people_assistance/10pa.jpg',
  },
]

export const humanitarianAidPhotos = [
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/humanitarian_aid/1ha.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/humanitarian_aid/2ha.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/humanitarian_aid/3ha.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/humanitarian_aid/4ha.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/humanitarian_aid/5ha.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/humanitarian_aid/6ha.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/humanitarian_aid/7ha.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/humanitarian_aid/8ha.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/humanitarian_aid/9ha.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/humanitarian_aid/10ha.jpg',
  },
]

export const militaryAssistancePhotos = [
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/military_assistance/1ma.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/military_assistance/3ma.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/military_assistance/4ma.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/military_assistance/5ma.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/military_assistance/6ma.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/military_assistance/7ma.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/military_assistance/8ma.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/military_assistance/9ma.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/military_assistance/10ma.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/military_assistance/11ma.jpg',
  },
]

export const otherProjects = [
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/other_projects/13op.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/other_projects/1op.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/other_projects/2op.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/other_projects/3op.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/other_projects/4op.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/other_projects/5op.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/other_projects/6op.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/other_projects/7op.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/other_projects/8op.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/other_projects/9op.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/other_projects/10op.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/other_projects/11op.jpg',
  },
  {
    original: 'https://assets.armiyavolonteriv.com/production/our_projects/other_projects/12op.jpg',
  },
]
