import React from 'react'
import { keys } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  List,
  Badge,
  Colors,
  Flex,
  Text,
  Button,
  Sizes,
} from 'ui-kit'
import PT from 'prop-types'

const RequestFormSettingsView = ({ requestFormEnabled, requestsLimit, handleEditMode }) => {
  const { t } = useTranslation()
  const listData = () => {
    const settingsData = {
      requestFormEnabled: <Badge
        color={requestFormEnabled ? Colors.SUCCESS : Colors.DANGER}
        pill
      >
        {t(`boolean.${requestFormEnabled}`)}
      </Badge>,
      requestsLimit,
    }

    return (
      keys(settingsData).map(key => (
        <Flex key={key} column fullwidth>
          <Text bold>{t(`settingsPage.${key}`)}</Text>
          {settingsData[key]}
        </Flex>
      ))
    )
  }

  return (
    <>
      <List data={listData()}></List>
      <Button
        className="mt-4"
        label={<Text capitalize>{t('edit')}</Text>}
        onClick={() => handleEditMode(true)}
        size={Sizes.LARGE}
        fullwidth
        outline
      />
    </>
  )
}

RequestFormSettingsView.propTypes = {
  requestFormEnabled: PT.bool.isRequired,
  requestsLimit: PT.number.isRequired,
  handleEditMode: PT.func.isRequired,
}

export default RequestFormSettingsView
