import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import AuthLayout from 'components/layouts/auth/Layout'
import AdminLayout from 'components/layouts/admin/Layout'
import LandingLayout from 'components/layouts/landing/Layout'
import NotFound from 'pages/NotFound'
import Home from 'pages/landing/Home'
import LandingRequestFrom from 'pages/landing/RequestForm'
import Donate from 'pages/landing/Donate'
import Login from 'pages/auth/Login'
import Users from 'pages/users/Users'
import UserNew from 'pages/users/UserNew'
import UserEdit from 'pages/users/UserEdit'
import Contacts from './pages/contacts/Contacts'
import ContactEdit from './pages/contacts/ContactEdit'
import ContactNew from './pages/contacts/ContactNew'
import Requests from 'pages/requests/Requests'
import Request from 'pages/requests/Request'
import RequestEvents from 'pages/requestEvents/RequestEvents'
import Store from 'pages/store/Store'
import StoreItems from 'pages/store/StoreItems'
import StoreItemNew from './pages/store/StoreItemNew'
import StoreItemEdit from './pages/store/StoreItemEdit'
import Settings from './pages/settings/Settings'
import Export from './pages/export/Export'
import PrivateRoute from 'components/auth/PrivateRoute'
import 'ui-kit/styles/core.scss'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<NotFound />} path="*" />
        <Route element={<LandingLayout />} >
          <Route element={<Home />} path="/" />
          <Route element={<LandingRequestFrom />} path="/request-form" />
          <Route element={<Donate />} path="/donate" />
        </Route>
        <Route element={<AuthLayout />}>
          <Route element={<Login />} path="/admin/login" />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route element={<AdminLayout />}>
            <Route element={<Navigate to="/admin/requests" replace />} path="/admin" />
            <Route path="/admin">
              <Route element={<Users />} path="users" />
              <Route element={<UserNew />} path="users/new" />
              <Route element={<UserEdit />} path="users/:userId/edit" />
              <Route element={<Contacts />} path="contacts" />
              <Route element={<ContactNew />} path="contacts/new" />
              <Route element={<ContactEdit />} path="contacts/:contactId/edit" />
              <Route element={<Requests />} path="requests" />
              <Route element={<Request />} path="requests/:requestId" />
              <Route element={<Store />} path="store" />
              <Route element={<StoreItems />} path="store-items" />
              <Route element={<StoreItemNew />} path="store-items/new" />
              <Route element={<StoreItemEdit />} path="store-items/:storeItemId/edit" />
              <Route element={<Export />} path="export" />
              <Route element={<Settings />} path="settings" />
              <Route element={<RequestEvents />} path="request-events" />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
