import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../store/selectors/auth'

export const useCurrentUser = () => {
  const currentUser = useSelector(selectCurrentUser)
  return (
    {
      currentUser,
      isSuperadmin: currentUser?.role === 'superadmin',
      isAdmin: currentUser?.role === 'admin',
    }
  )
}
