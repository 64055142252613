{/* eslint-disable react/no-array-index-key */}
import React, { Fragment } from 'react'
import PT from 'prop-types'
import { Link } from 'react-router-dom'

const TextWithLink = ({ to, regex, children }) => {
  const renderTextWithLinks = () => {
    const matches = children.match(regex) || []

   return (
     children.split(regex).map((part, i) => (
       <Fragment key={i}>
         {part}
         {matches[i] && (
           <Link style={{ display: 'inline' }} to={to.replace(':match', matches[i].substring(1))}>
             {matches[i]}
           </Link>
         )}
       </Fragment>
     ))
   )
  }

  return <>{renderTextWithLinks()}</>
}

TextWithLink.propTypes = {
  to: PT.string.isRequired,
  regex: PT.instanceOf(RegExp).isRequired,
  children: PT.string.isRequired,
}

export default TextWithLink
