import {
  APPLY_USER_SIGN_IN,
  APPLY_USER_SIGN_OUT,
} from '../reducers/auth'

const applyUserSignIn = (userAuth, currentUser) => ({
  type: APPLY_USER_SIGN_IN,
  payload: { userAuth, currentUser },
})

const applyUserSignOut = () => ({
  type: APPLY_USER_SIGN_OUT,
})

export { applyUserSignIn, applyUserSignOut }
