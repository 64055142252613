import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { camelCase, filter } from 'lodash'
import { useQueryParams } from 'hooks/useQueryParams'
import { formatDateTime } from 'common/utils/datetime'
import { REQUEST_STATUSES } from 'common/constants/requestStatuses'
import { REQUEST_STATUS_COLOR } from 'common/constants/requestStatusColor'
import { getPaginationData } from 'common/utils/getPaginationData'
import { RequestsQuery, RequestsCountsQuery } from 'queries/requests.gql'
import {
  Table,
  Pagination,
  Text,
  Tabs,
  Badge,
  Icon,
  SortDropdown,
} from 'ui-kit'
import PageHeader from 'components/pageHeader/PageHeader'
import SearchInput from 'components/SearchInput'

const Requests = () => {
  const { t } = useTranslation()
  const { queryParams, setQueryParams } = useQueryParams()
  const navigate = useNavigate()

  const sort = queryParams.sort
  const page = parseInt(queryParams.page) || 1
  const perPage = parseInt(queryParams.perPage) || 10
  const search = queryParams.search
  const status = queryParams.status || 'initial'
  const queryVariables = {
    sort,
    page,
    perPage,
    search,
    status,
  }

  const { data, loading } = useQuery(RequestsQuery, { variables: queryVariables })
  const { data: countsData, loading: countsLoading } = useQuery(RequestsCountsQuery)
  const requests = data?.requests?.data || []
  const counts = countsData?.requestsCounts || {}
  const paginationData = getPaginationData(data, 'requests')

  const tabsData = () => (
    REQUEST_STATUSES.map(requestStatus => (
      {
        label: t(`requestsStatus.${requestStatus}`),
        handler: () => handleStatusTabChange(requestStatus),
        active: requestStatus === status,
        badge: renderTabBadge(requestStatus),
      }
    ))
  )

  const renderTabBadge = (requestStatus) => {
    if (countsLoading) return <Icon name="loading" />

    return renderStatusBadge(requestStatus, counts[camelCase(requestStatus)])
  }

  const renderStatusBadge = (requestStatus, label) => {
    return (
      <Badge
        color={REQUEST_STATUS_COLOR[requestStatus]}
      >
        {label}
      </Badge>
    )
  }

  const decorateTitles = () => (
    [
      { name: 'id', label: <Text uppercase>{t('id')}</Text>, sorting: true },
      { name: 'status', label: <Text uppercase>{t('status')}</Text> },
      { name: 'assignee', label: <Text uppercase>{t('assignee')}</Text> },
      { name: 'requester', label: <Text capitalize>{t('requester')}</Text> },
      { name: 'phone_number', label: <Text capitalize>{t('phoneNumber')}</Text> },
      { name: 'created_at', label: <Text capitalize>{t('createdAt')}</Text>, sorting: true },
      { name: 'updated_at', label: <Text capitalize>{t('updatedAt')}</Text>, sorting: true },
      { name: 'pick_up_time', label: <Text capitalize>{t('requestFieldLabel.pickUpTime')}</Text>, sorting: true },
    ]
  )

  const sortOptions = () => filter(decorateTitles(), (o) => !!o.sorting)

  const decorateData = () => (
    requests.map((request) => (
      {
        id: request.id,
        cells: [
          { name: 'id', content: request.id },
          { name: 'status', content: renderStatusBadge(request.status, t(`requestsStatus.${request.status}`)) },
          { name: 'assignee', content: request.assignee?.fullName },
          { name: 'requester', content: request.fullName },
          { name: 'phoneNumber', content: request.formattedPhoneNumber },
          { name: 'createdAt', content: formatDateTime(request.createdAt) },
          { name: 'updatedAt', content: formatDateTime(request.updatedAt) },
          { name: 'pickUpTime', content: request.pickUpTime ? formatDateTime(request.pickUpTime) : '-' },
        ],
        onClick: () => navigate(`${request.id}`),
      }
    ))
  )

  const handleStatusTabChange = (newStatus) => {
    setQueryParams({ status: newStatus, page: 1 })
  }

  return (
    <>
      <PageHeader label={t('requests')} />
      <SearchInput extraParams={{ status: 'all' }} key="search" />
      <SortDropdown className="my-3" options={sortOptions()} />
      <Tabs data={tabsData()} />
      <Table data={decorateData()} loading={loading} titles={decorateTitles()} />
      <Pagination
        {...paginationData}
        loading={loading}
        withPerPage
        withResults
      />
    </>
  )
}

export default Requests
