import { Toaster, toasterProps, toasterTypes } from '../components/toaster'

export const useToaster = (timeout = 5000) => {
  const showToaster = (props) => {
    Toaster.show(
      toasterProps({ ...props, timeout }),
    )
  }
  return { showToaster, toasterTypes }
}
