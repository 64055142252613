import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from 'ui-kit'
import PageHeader from 'components/pageHeader/PageHeader'
import ExportRequestsForm from './components/ExportRequestsForm'

const Export = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageHeader label={t('export')} />
      <Flex column>
        <h3>{t('exportPage.requestsHeading')}:</h3>
        <ExportRequestsForm />
      </Flex>
    </>
  )
}

export default Export
