import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import Sizes from '../../sizes'

import styles from './Margin.module.scss'

const Margin = ({
  top,
  bottom,
  left,
  right,
  all,
  children,
  className,
  fullwidth,
  style,
}) => {
  const classes = cx(className, {
    ['w-100']: fullwidth,

    [styles.top]: top || all,
    [styles.topXSmall]: top === Sizes.XSMALL,
    [styles.topSmall]: top === Sizes.SMALL,
    [styles.topLarge]: top === Sizes.LARGE,
    [styles.topXLarge]: top === Sizes.XLARGE,
    [styles.topXXLarge]: top === Sizes.XXLARGE,
    [styles.topXXXLarge]: top === Sizes.XXXLARGE,

    [styles.bottom]: bottom || all,
    [styles.bottomXSmall]: bottom === Sizes.XSMALL,
    [styles.bottomSmall]: bottom === Sizes.SMALL,
    [styles.bottomLarge]: bottom === Sizes.LARGE,
    [styles.bottomXLarge]: bottom === Sizes.XLARGE,
    [styles.bottomXXLarge]: bottom === Sizes.XXLARGE,
    [styles.bottomXXXLarge]: bottom === Sizes.XXXLARGE,

    [styles.left]: left || all,
    [styles.leftXSmall]: left === Sizes.XSMALL,
    [styles.leftSmall]: left === Sizes.SMALL,
    [styles.leftLarge]: left === Sizes.LARGE,
    [styles.leftXLarge]: left === Sizes.XLARGE,
    [styles.leftXXLarge]: left === Sizes.XXLARGE,

    [styles.right]: right || all,
    [styles.rightXSmall]: right === Sizes.XSMALL,
    [styles.rightSmall]: right === Sizes.SMALL,
    [styles.rightLarge]: right === Sizes.LARGE,
    [styles.rightXLarge]: right === Sizes.XLARGE,
    [styles.rightXXLarge]: right === Sizes.XXLARGE,
  })

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  )
}

Margin.propTypes = {
  top: PT.oneOfType([PT.string, PT.bool]),
  bottom: PT.oneOfType([PT.string, PT.bool]),
  left: PT.oneOfType([PT.string, PT.bool]),
  right: PT.oneOfType([PT.string, PT.bool]),
  all: PT.oneOfType([PT.string, PT.bool]),
  className: PT.string,
  children: PT.oneOfType([PT.element, PT.array]),
  fullwidth: PT.bool,
  style: PT.shape({}),
}

Margin.defaultProps = {
  top: false,
  bottom: false,
  left: false,
  right: false,
  all: false,
  fullwidth: false,
  className: '',
  children: [],
  style: {},
}

export default Margin
