import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { pickBy } from 'lodash'
import { Flex, Text } from 'ui-kit'
import { useCurrentUser } from 'hooks/useCurrentUser'
import ContactForm from './components/ContactForm'
import { useToaster } from 'hooks/useToaster'
import { CreateContactMutation } from 'queries/contacts.gql'

const ContactNew = () => {
  const { t } = useTranslation()
  const { showToaster, toasterTypes } = useToaster()
  const { isSuperadmin, isAdmin } = useCurrentUser()
  const navigate = useNavigate()
  const { contactId } = useParams()

  useEffect(() => {
    if (!isSuperadmin && !isAdmin) {
      navigate('/admin/requests')
    }
  }, [contactId])

  const [createContact, { loading: isSubmitting }] = useMutation(CreateContactMutation, {
    onCompleted: (data) => {
      if (data.createContact.entity) {
        navigate('/admin/contacts')
        showToaster({
          content: <Text>{t('contactToasts.contactSuccessfullyCreated')}</Text>,
          type: toasterTypes.SUCCESS,
        })
      } else if (data.createContact.errors?.length) {
        showToaster({
          content: (<Flex column>
            {data.createContact.errors.map(({ message }) => <Text key={message}>{message}</Text>)}
          </Flex>),
          type: toasterTypes.DANGER,
        })
      }
    },
  })

  const handleSubmit = async (values) => {
    const variables = {
      data: { ...pickBy(values) },
    }
    await createContact({ variables })
  }

  return (
    <>
      <h1>{t('createContact')}</h1>
      <ContactForm
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
      />
    </>
  )
}

export default ContactNew
