import React from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Section, Flex, Text } from 'ui-kit'
import ProjectInfo from '../components/ProjectInfo'
import FacebookLink from '../components/socialLinks/FacebookLink'
import InstagramLink from '../components/socialLinks/InstagramLink'
import LinkedinLink from '../components/socialLinks/LinkedinLink'
import {
  peopleAssistancePhotos,
  humanitarianAidPhotos,
  militaryAssistancePhotos,
  otherProjects,
} from '../constants/ourProjectsPhotos'
import styles from './OurProjects.module.scss'

const OurProjects = ({ observer }) => {
  const { t } = useTranslation()

  return (
    <Section className={styles.ourProjects} id="our-projects" observer={observer}>
      <h2>{t('landing.sectionsTitle.ourProjects')}</h2>
      <Flex gap="2rem" style={{ marginBottom: '3rem' }} column>
        <ProjectInfo
          photos={peopleAssistancePhotos}
          text={t('landing.sectionsText.ourProjects.1.text')}
          title={t('landing.sectionsText.ourProjects.1.title')}
        />
        <ProjectInfo
          photos={humanitarianAidPhotos}
          text={t('landing.sectionsText.ourProjects.2.text')}
          title={t('landing.sectionsText.ourProjects.2.title')}
          reversed
        />
        <ProjectInfo
          photos={militaryAssistancePhotos}
          text={t('landing.sectionsText.ourProjects.3.text')}
          title={t('landing.sectionsText.ourProjects.3.title')}
        />
        <ProjectInfo
          photos={otherProjects}
          text={t('landing.sectionsText.ourProjects.4.text')}
          title={t('landing.sectionsText.ourProjects.4.title')}
          reversed
        />
      </Flex>
      <div className={styles.socialMediaContainer}>
        <Text
          className={styles.socialMediaTitle}
          landingText
          semiBold
        >
          {t('landing.sectionsText.ourProjects.linksText')}
        </Text>
        <div className={styles.socialMediaLinks}>
          <FacebookLink className="me-3" />
          <InstagramLink className="me-3" />
          <LinkedinLink />
        </div>
      </div>
    </Section>
  )
}

OurProjects.propTypes = {
  observer: PT.func.isRequired,
}

export default OurProjects
