export const BOLD = 'bold'
export const NORMAL = 'normal'
export const SEMI = 'semi'

export const Weights = {
  BOLD,
  NORMAL,
  SEMI,
}

export default Weights
