/* eslint-disable max-len, react/no-unknown-property */
import React from 'react'
import PT from 'prop-types'

const EUflagIcon = ({ className, style }) => (
  <svg
    className={className}
    style={style}
    version="1.1"
    viewBox="0 0 640 480"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <g id="d">
        <g id="b">
          <path d="m0-1-.3 1 .5.1z" id="a"/>
          <use transform="scale(-1 1)" xlinkHref="#a"/>
        </g>
        <g id="c">
          <use transform="rotate(72)" xlinkHref="#b"/>
          <use transform="rotate(144)" xlinkHref="#b"/>
        </g>
        <use transform="scale(-1 1)" xlinkHref="#c"/>
      </g>
    </defs>
    <path d="M0 0h640v480H0z" fill="#039"/>
    <g fill="#fc0" transform="translate(320 242.3) scale(23.7037)">
      <use height="100%" width="100%" xlinkHref="#d" y="-6"/>
      <use height="100%" width="100%" xlinkHref="#d" y="6"/>
      <g id="e">
        <use height="100%" width="100%" x="-6" xlinkHref="#d"/>
        <use height="100%" transform="rotate(-144 -2.3 -2.1)" width="100%" xlinkHref="#d"/>
        <use height="100%" transform="rotate(144 -2.1 -2.3)" width="100%" xlinkHref="#d"/>
        <use height="100%" transform="rotate(72 -4.7 -2)" width="100%" xlinkHref="#d"/>
        <use height="100%" transform="rotate(72 -5 .5)" width="100%" xlinkHref="#d"/>
      </g>
      <use height="100%" transform="scale(-1 1)" width="100%" xlinkHref="#e"/>
    </g>
  </svg>
)

EUflagIcon.propTypes = {
  className: PT.string,
  style: PT.shape({}),
}

EUflagIcon.defaultProps = {
  className: '',
  style: {},
}

export default EUflagIcon
