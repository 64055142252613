import React from 'react'
import cx from 'classnames'
import PT from 'prop-types'
import { createPortal } from 'react-dom'
import {
  Button,
  Container,
  Sizes,
  Colors,
} from '../../index'
import styles from './Modal.module.scss'

const Modal = ({
  isOpen,
  onClose,
  children,
  style,
  className,
  overlayClick,
  width,
  showCloseButton,
}) => {
  const handleOverlayClick = (e) => {
    e.preventDefault()
    if (!overlayClick) return null

    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  const renderCloseButton = () => {
    if (!showCloseButton) return null

    return (
      <Button
        className={styles.closeButton}
        color={Colors.LIGHT}
        iconName="x"
        onClick={onClose}
        size={Sizes.SMALL}
      />
    )
  }

  if (!isOpen) return null

  return createPortal(
    <div
      className={styles.modal}
      onClick={handleOverlayClick}
      style={style}
    >
      <Container
        className={cx(styles.modalContent, className)}
        padding={Sizes.NORMAL}
        style={{ width }}
      >
        {renderCloseButton()}
        {children}
      </Container>
    </div>,
    document.body,
  )
}

Modal.propTypes = {
  children: PT.node.isRequired,
  width: PT.string,
  isOpen: PT.bool,
  onClose: PT.func,
  className: PT.string,
  overlayClick: PT.bool,
  showCloseButton: PT.bool,
  style: PT.shape({}),
}

Modal.defaultProps = {
  isOpen: false,
  overlayClick: true,
  showCloseButton: false,
  style: {},
}

export default Modal
