export const ourPartners = [
  {
    alt: 'world central kitchen',
    src: 'https://assets.armiyavolonteriv.com/production/partners/world_central_kitchen.png',
  },
  {
    alt: 'art foods',
    src: 'https://assets.armiyavolonteriv.com/production/partners/art_foods.png',
  },
  {
    alt: 'gala foods',
    src: 'https://assets.armiyavolonteriv.com/production/partners/gf.png',
  },
  {
    alt: 'echoes of destruction',
    src: 'https://assets.armiyavolonteriv.com/production/partners/echoes_of_destruction.png',
  },
  {
    alt: 'good will',
    src: 'https://assets.armiyavolonteriv.com/production/partners/good_will.png',
  },
  {
    alt: 'omriyana kraina',
    src: 'https://assets.armiyavolonteriv.com/production/partners/omr_kraina.png',
  },
]
