import React from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { camelCase } from 'lodash'
import { useQueryParams } from 'hooks/useQueryParams'
import { makeSortString, parseSortString } from 'common/utils/sorting'
import {
  Dropdown,
  Button,
  Colors,
  Text,
} from '../../index'
import styles from './SortDropDown.module.scss'

const SortDropdown = ({ className, options }) => {
  const { t } = useTranslation()
  const { queryParams, setQueryParams } = useQueryParams()
  const sort = queryParams.sort || ''

  const handleSort = (name) => {
    const sortString = makeSortString(sort, name)
    setQueryParams({ sort: sortString })
  }

  const decorateOptions = () => (
    options.map(({ label, name }) => (
      {
        key: name,
        title: label,
        name,
        handler: () => handleSort(name),
      }
    ))
  )

  const renderButtonLabel = () => {
    if (!sort) {
      return (
        {
          label: t('sorting'),
          iconName: 'adjustments',
        }
      )
    }

    const [currBy, currDir] = parseSortString(sort)

    return (
      {
        label: t(camelCase(currBy)),
        iconName: currDir === 'asc' ? 'chevronUp' : 'chevronDown',
      }
    )
  }

  return (
    <Dropdown options={decorateOptions()}>
      <Button
        className={cx(styles.sortDropdown, className)}
        color={Colors.PRIMARY}
        iconName={renderButtonLabel().iconName}
        label={<Text capitalize>{renderButtonLabel().label}</Text>}
        outline
      />
    </Dropdown>
  )
}

SortDropdown.propTypes = {
  className: PT.string,
  options: PT.arrayOf(PT.shape({
    label: PT.oneOfType([PT.string, PT.element]),
    name: PT.string,
  })).isRequired,
}

SortDropdown.defaultProps = {
  className: '',
}

export default SortDropdown
