import React, { useState } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import LinkButton from 'pages/landing/components/LinkButton'
import { Flex, Icon } from 'ui-kit'
import Navigation from './Navigation'
import LangSwitcher from './LangSwitcher'
import MobileSidebar from './MobileSidebar'
import styles from './Header.module.scss'

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { t } = useTranslation()

  const menuBtnClasses = cx(styles.hamburger, 'ms-1', {
    [styles.active]: isMenuOpen,
  })

  const handleMobileNavClick = () => {
    setIsMenuOpen(false)
  }

  return (
    <>
      <header className={styles.header}>
        <div className={styles.mobileHeaderContainer}>
          <Flex alignItems="center" justifyContent="between">
            <a aria-label="logo link. navigate to top" className={styles.logo} href="/#our-mission">
              <Icon name="logo" />
            </a>
            <Flex alignItems="center" justifyContent="between">
              <LinkButton className={styles.linkButton} label={t('landing.donateButton')} to="/donate" />
            </Flex>
            <Flex alignItems="center" justifyContent="start">
              <LangSwitcher />
              <button aria-label="menu button" className={menuBtnClasses} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <span className={styles.bar} />
                <span className={styles.bar} />
                <span className={styles.bar} />
              </button>
            </Flex>
          </Flex>
        </div>
        <div className={styles.desktopHeaderContainer}>
          <Navigation />
          <Flex alignItems="center" gap="2rem" justifyContent="between">
            <LangSwitcher />
            <LinkButton className={styles.linkButton} label={t('landing.donateButton')} to="/donate" />
          </Flex>
        </div>
      </header>
      <MobileSidebar isOpened={isMenuOpen} onNavItemClick={handleMobileNavClick} />
    </>
  )
}

export default Header
