import React from 'react'
import PT from 'prop-types'
import Flex from '../spacing/Flex'
import styles from './InputWrapper.module.scss'

const InputWrapper = ({ children, id, label, tag, error, required, margin }) => {
  const errorMessage =
    error !== ('' || true) ? error : 'This field is required'

  const renderLabel = () => {
    if (!label) return null

    const Element = tag

    return (
      <Element className={styles.label} htmlFor={id}>
        {label}{required && <span className={styles.required} style={{ marginLeft: '4px' }}>*</span>}
      </Element>
    )
  }

  return (
    <Flex className={margin} column>
      {renderLabel()}
      {children}
      {error && <span className={styles.error}>{errorMessage}</span>}
    </Flex>
  )
}

InputWrapper.propTypes = {
  children: PT.node.isRequired,
  tag: PT.string,
  className: PT.string,
  error: PT.oneOfType([PT.string, PT.bool]),
  required: PT.bool,
  id: PT.string.isRequired,
  label: PT.string,
  margin: PT.string,
}

InputWrapper.defaultProps = {
  tag: 'label',
  className: '',
  error: '',
  required: false,
  label: '',
  margin: 'mb-4',
}

export default InputWrapper
