import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import classes from './ContainerFluid.module.scss'

const ContainerFluid = ({ children, className, style }) => {
  const classNames = cx(className, classes.containerFluid)

  return (
    <div className={classNames} style={style}>
      {children}
    </div>
  )
}

ContainerFluid.propTypes = {
  children: PT.node.isRequired,
  className: PT.string,
  style: PT.shape({}),
}

ContainerFluid.defaultProps = {
  className: '',
  style: {},
}

export default ContainerFluid
