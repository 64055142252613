import React, { useState } from 'react'
import PT from 'prop-types'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { TextInput } from 'ui-kit'
import { useQueryParams } from '../hooks/useQueryParams'
import { Flex, Icon, Sizes, Colors } from 'ui-kit'

const SearchInput = ({ extraParams }) => {
  const { t } = useTranslation()
  const { queryParams, setQueryParams } = useQueryParams()
  const search = queryParams.search
  const [searchQuery, setSearchQuery] = useState(search)

  const debouncedSearch = React.useRef(
    debounce((searchQuery, extraParams) => {
      setQueryParams({ ...extraParams, search: searchQuery, page: 1 })
    }, 1000),
  ).current

  const handleChange = (e) => {
    setSearchQuery(e.target.value)
    debouncedSearch(e.target.value, extraParams)
  }

  const handleClear = () => {
    setSearchQuery('')
    setQueryParams({ ...extraParams, search: '', page: 1 })
  }

  const renderCross = () => {
    if (!searchQuery) return null

    return (
      <Icon
        color={Colors.DANGER}
        name="x"
        onClick={handleClear}
        size={Sizes.LARGE}
      />
    )
  }

  return (
    <Flex alignItems="center" className="mb-2">
      <TextInput
        id="search"
        key="search"
        margin="m-0"
        name="search"
        onChange={handleChange}
        placeholder={t('search')}
        style={{ width: '15rem' }}
        value={searchQuery}
      />
      {renderCross()}
    </Flex>
  )
}

SearchInput.propTypes = {
  extraParams: PT.shape({}),
}

SearchInput.defaultProps = {
  extraParams: {},
}

export default SearchInput
