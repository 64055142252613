import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import Sizes from '../../sizes'

import styles from './Spacer.module.scss'

const Spacer = ({ vertical, horizontal, line, className, style }) => {
  const classes = cx(
    styles.spacer,
    {
      [styles.vertical]: vertical,
      [styles.verticalXSmall]: vertical === Sizes.XSMALL,
      [styles.verticalSmall]: vertical === Sizes.SMALL,
      [styles.verticalNormal]: vertical === Sizes.NORMAL,
      [styles.verticalLarge]: vertical === Sizes.LARGE,
      [styles.verticalXLarge]: vertical === Sizes.XLARGE,
      [styles.verticalXXLarge]: vertical === Sizes.XXLARGE,
      [styles.verticalBothSides]: vertical && line,

      [styles.horizontal]: horizontal,
      [styles.horizontalXSmall]: horizontal === Sizes.XSMALL,
      [styles.horizontalSmall]: horizontal === Sizes.SMALL,
      [styles.horizontalNormal]: horizontal === Sizes.NORMAL,
      [styles.horizontalLarge]: horizontal === Sizes.LARGE,
      [styles.horizontalXLarge]: horizontal === Sizes.XLARGE,
      [styles.horizontalXXLarge]: horizontal === Sizes.XXLARGE,
      [styles.horizontalBothSides]: horizontal && line,
    },
    className,
  )

  return (
    <div className={classes} style={style}>
      <div className={styles.spacerContent}>
        {line && <div className={styles.line} />}
      </div>
    </div>
  )
}


Spacer.propTypes = {
  vertical: PT.oneOfType([PT.string, PT.bool]),
  horizontal: PT.oneOfType([PT.string, PT.bool]),
  line: PT.bool,
  className: PT.string,
  style: PT.shape({}),
}

Spacer.defaultProps = {
  vertical: false,
  horizontal: false,
  line: false,
  className: '',
  style: {},
}

export default Spacer
