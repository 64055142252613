import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from '@apollo/client'
import { pickBy } from 'lodash'
import { Flex, Text, LoadingSpinner } from 'ui-kit'
import ContactForm from './components/ContactForm'
import { useToaster } from 'hooks/useToaster'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { ContactQuery, UpdateContactMutation } from 'queries/contacts.gql'

const ContactEdit = () => {
  const { t } = useTranslation()
  const { isSuperadmin, isAdmin } = useCurrentUser()
  const { showToaster, toasterTypes } = useToaster()
  const navigate = useNavigate()
  const { contactId } = useParams()

  useEffect(() => {
    if (!isSuperadmin && !isAdmin) {
      navigate('/admin/requests')
    }
  }, [contactId])

  const { data, loading } = useQuery(ContactQuery, {
    variables: { id: contactId },
  })

  const [updateContact, { loading: isSubmitting }] = useMutation(UpdateContactMutation, {
    onCompleted: (data) => {
      if (data.updateContact.entity) {
        navigate('/admin/contacts')
        showToaster({
          content: <Text>{t('contactToasts.contactSuccessfullyUpdated')}</Text>,
          type: toasterTypes.SUCCESS,
        })
      } else if (data.updateContact.errors?.length) {
        showToaster({
          content: (<Flex column>
            {data.updateContact.errors.map(({ message }) => <Text key={message}>{message}</Text>)}
          </Flex>),
          type: toasterTypes.DANGER,
        })
      }
    },
  })

  const contact = data?.contact || {}

  const handleSubmit = async (values) => {
    const variables = {
      id: contactId,
      data: { ...pickBy(values) },
    }
    await updateContact({ variables })
  }

  if (loading) return <LoadingSpinner marginTop />

  return (
    <>
      <h1>{t('updateContact')}</h1>
      <ContactForm
        contact={contact}
        isLoading={loading}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
      />
    </>
  )
}

ContactEdit.propTypes = {}

ContactEdit.defaultProps = {}

export default ContactEdit
