{/* eslint-disable react/no-array-index-key */}
import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import { LoadingSpinner } from '../../index'
import styles from './List.module.scss'

const List = ({ data, loading, className, style }) => {
  const renderItems = () => {
    if (loading && !data) {
      return (
        <li
          className="list-group-item d-flex justify-content-center align-items-center"
        >
          <LoadingSpinner />
        </li>
      )
    } else {
      return (
        data.map((item, idx) => (
          <li
            className="list-group-item d-flex justify-content-between align-items-center"
            key={idx}
          >
            {item}
          </li>
        ))
      )
    }
  }
  return (
    <ul className={cx('list-group', styles.list, className)} style={style}>
      {renderItems()}
    </ul>
  )
}

List.propTypes = {
  data: PT.arrayOf(PT.oneOfType([PT.element, PT.string])),
  loading: PT.bool,
  className: PT.string,
  style: PT.shape({}),
}

List.defaultProps = {
  data: [],
  loading: false,
  className: '',
  style: {},
}

export default List
