import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import { InputWrapper } from 'ui-kit'
import styles from './SwitchInput.module.scss'

const SwitchInput = ({
  name,
  label,
  labelWrapper,
  checked,
  noWrapper,
  required,
  error,
  onChange,
  onBlur,
  disabled,
  className,
}) => {
  const classes = cx(styles.slider, className, {
    [styles.disabled]: disabled,
  })

  const renderSwitch = () => (
    <div className={styles.switch}>
      <label className={styles.label}>
        {label}
        <input
          checked={checked}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          type="checkbox"
        />
        <span className={classes} />
      </label>
    </div>
  )

  if (noWrapper) {
    return renderSwitch()
  }

  return (
    <InputWrapper error={error} id={name} label={labelWrapper} required={required}>
      {renderSwitch()}
    </InputWrapper>
  )
}

SwitchInput.propTypes = {
  name: PT.string.isRequired,
  error: PT.string,
  required: PT.bool,
  label: PT.string,
  labelWrapper: PT.string,
  onChange: PT.func,
  onBlur: PT.func,
  noWrapper: PT.bool,
  className: PT.string,
  checked: PT.bool,
  disabled: PT.bool,
}

SwitchInput.defaultProps = {
  style: {},
}

export default SwitchInput
