import React from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { useQueryParams } from 'hooks/useQueryParams'
import { formatDate } from 'common/utils/datetime'
import { USER_STATUSES } from 'common/constants/userStatuses'
import { getPaginationData } from 'common/utils/getPaginationData'
import { UsersQuery, ArchiveUserMutation, RestoreUserMutation } from 'queries/users.gql'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { useToaster } from 'hooks/useToaster'
import {
  Table,
  Flex,
  Button,
  Colors,
  Icon,
  Sizes,
  Tabs,
  Pagination,
  Text,
  ConfirmModal,
} from 'ui-kit'
import PageHeader from 'components/pageHeader/PageHeader'
import SearchInput from 'components/SearchInput'

const Users = () => {
  const { currentUser, isSuperadmin, isAdmin } = useCurrentUser()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { queryParams, setQueryParams } = useQueryParams()
  const { showToaster, toasterTypes } = useToaster()

  const sort = queryParams.sort
  const page = parseInt(queryParams.page) || 1
  const search = queryParams.search
  const scope = queryParams.scope || 'active'
  const queryVariables = {
    sort,
    page,
    search,
    scope,
  }

  const { data, loading } = useQuery(UsersQuery, { variables: queryVariables })
  const [archive] = useMutation(ArchiveUserMutation, {
    refetchQueries: [UsersQuery],
  })
  const [restore] = useMutation(RestoreUserMutation, {
    refetchQueries: [UsersQuery],
  })

  const users = data?.users?.data || []
  const paginationData = getPaginationData(data, 'users')

  const tabsData = () => (
    USER_STATUSES.map(userStatus => (
      {
        label: t(`userStatus.${userStatus}`),
        handler: () => handleStatusTabChange(userStatus),
        active: userStatus === scope,
      }
    ))
  )

  const handleStatusTabChange = (newScope) => {
    setQueryParams({ scope: newScope, page: 1 })
  }

  const handleArchive = async (id) => {
    const variables = { id }
    await archive({ variables })
    showToaster({
      content: <Text>{t('userToasts.userSuccessfullyArchived')}</Text>,
      type: toasterTypes.SUCCESS,
      action: {
        onClick: () => { handleRestore(id) },
        text: t('undo'),
      },
    })
  }

  const handleRestore = async (id) => {
    const variables = { id }
    await restore({ variables })
    showToaster({
      content: <Text>{t('userToasts.userSuccessfullyRestored')}</Text>,
      type: toasterTypes.SUCCESS,
      action: {
        onClick: () => { handleArchive(id) },
        text: t('undo'),
      },
    })
  }

  const userActions = (id, role) => {
    const allowEdit = id === currentUser.id || isSuperadmin || isAdmin
    const allowArchive = (isSuperadmin || isAdmin) && role !== 'superadmin' && role !== 'admin'
    if (scope === 'active') {
      return (
        <>
          <Flex className={cx('w-100', 'mobileActions')}>
            {allowEdit && <Button
              className="me-1"
              color={Colors.WARNING}
              iconName="pencil"
              label={t('edit')}
              onClick={() => navigate(`${id}/edit`)}
              fullwidth
            />}
            {allowArchive &&
              <ConfirmModal
                buttonLabel={t('archive')}
                onConfirm={() => handleArchive(id)}
                title={t('confirmModal.archiveUser')}
                  >
                {(handleClick) => (
                  <Button
                    className="ms-1"
                    color={Colors.DANGER}
                    iconName="trash"
                    label={t('archive')}
                    onClick={handleClick}
                    fullwidth
                  />
                )}
              </ConfirmModal>
            }
          </Flex>
          <Flex className={cx('desktopActions')}>
            {allowEdit && <Icon
              className="me-4"
              color={Colors.WARNING}
              name="pencil"
              onClick={() => navigate(`${id}/edit`)}
              size={Sizes.LARGE}
            />}
            {allowArchive ? <ConfirmModal
              buttonLabel={t('archive')}
              onConfirm={() => handleArchive(id)}
              title={t('confirmModal.archiveUser')}
                  >
              {(handleClick) => (
                <Icon
                  color={Colors.DANGER}
                  name="trash"
                  onClick={handleClick}
                  size={Sizes.LARGE}
            />
            )}
            </ConfirmModal>
             : <div style={{ width: '24px' }} />}
          </Flex>
        </>
      )
    }

    if (scope === 'archived') {
      return (
        <>
          <Flex className={cx('w-50', 'mobileActions')}>
            <Button
              className="me-1"
              color={Colors.SUCCESS}
              iconName="refresh"
              label={t('restore')}
              onClick={() => handleRestore(id)}
              fullwidth
          />
          </Flex>
          <Flex className={cx('desktopActions')}>
            <Icon
              className="me-4"
              color={Colors.SUCCESS}
              name="refresh"
              onClick={() => handleRestore(id)}
              size={Sizes.LARGE}
        />
          </Flex>
        </>

    )
  }
  }

  const decorateTitles = () => (
    [
      { name: 'id', label: <Text uppercase>{t('id')}</Text>, sorting: true },
      { name: 'email', label: <Text capitalize>{t('email')}</Text>, sorting: true },
      { name: 'first_name', label: <Text capitalize>{t('name')}</Text>, sorting: true },
      { name: 'phone_number', label: <Text capitalize>{t('phoneNumber')}</Text> },
      { name: 'role', label: <Text capitalize>{t('role')}</Text>, sorting: true },
      { name: 'createdAt', label: <Text capitalize>{t('createdAt')}</Text> },
      { name: 'actions', label: '' },
    ]
  )

  const decorateData = () => (
    users.map((user) => (
      {
        id: user.id,
        cells: [
          { name: 'id', content: user.id },
          { name: 'email', content: user.email },
          { name: 'firstName', content: user.fullName },
          { name: 'phoneNumber', content: user.formattedPhoneNumber },
          { name: 'role', content: t(`userRole.${user.role}`) },
          { name: 'createdAt', content: formatDate(user.createdAt) },
          { name: 'actions', content: userActions(user.id, user.role) },
        ],
      }
    ))
  )

  const headerActions = () => {
    return (
      [
        <Button
          color={Colors.PRIMARY}
          iconName="plus"
          key="create-user"
          label={<Text capitalize>{t('addNew')}</Text>}
          onClick={() => navigate('new')}
        />,
      ]
    )
  }

  return (
    <>
      <PageHeader actions={headerActions()} label={t('team')} />
      <SearchInput key="search" />
      {(isSuperadmin || isAdmin) && <Tabs data={tabsData()} />}
      <Table data={decorateData()} loading={loading} titles={decorateTitles()} />
      <Pagination
        {...paginationData}
        loading={loading}
        withResults
      />
    </>
  )
}

export default Users
