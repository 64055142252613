import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import InputWrapper from '../InputWrapper'
import { usePlacesWidget } from 'react-google-autocomplete'
import appConfig from '../../../../configs/appConfig'
import styles from './GoogleAutocompleteInput.module.scss'

const GoogleAutocompleteInput = ({
  id,
  error,
  required,
  placeholder,
  disabled,
  label,
  name,
  onChange,
  onBlur,
  // bounds,
  noWrapper,
  style,
}) => {
  const getLanguage = () => {
    const lang = localStorage.getItem('lang') || 'ua'
    if (lang === 'ua') {
      return 'uk'
    }

    return 'en'
  }

  const { ref } = usePlacesWidget({
    apiKey: appConfig.GOOGLE_PLACES_API_KEY,
    onPlaceSelected: ({ formatted_address }) => {
      onChange(formatted_address)
    },
    options: {
      types: ['address'],
      componentRestrictions: { country: 'ua' },
      // strictBounds: false,
      // bounds,
    },
    language: getLanguage(),
  })

  const renderInput = () => {
    return (
      <input
        className={
          cx(styles.placesInput, {
            [styles.error]: error,
            [styles.disabled]: disabled,
          })
        }
        name={name}
        onBlur={onBlur}
        onInput={({ target }) => { onChange(target.value) }}
        placeholder={placeholder}
        ref={ref}
        style={style}
      />
    )
  }

  if (noWrapper) {
    return (
      renderInput()
    )
  }

  return (
    <InputWrapper
      error={error}
      id={id}
      label={label}
      required={required}
    >
      {renderInput()}
    </InputWrapper>
  )
}

GoogleAutocompleteInput.propTypes = {
  id: PT.string.isRequired,
  name: PT.string.isRequired,
  bounds: PT.shape({}),
  onChange: PT.func,
  onBlur: PT.func,
  error: PT.string,
  required: PT.bool,
  placeholder: PT.string,
  label: PT.string,
  style: PT.shape({}),
  noWrapper: PT.bool,
  disabled: PT.bool,
}

GoogleAutocompleteInput.defaultProps = {
  onChange: null,
  onBlur: null,
  bounds: {},
  error: '',
  required: false,
  label: '',
  noWrapper: false,
  disabled: false,
  style: {},
}

export default GoogleAutocompleteInput
