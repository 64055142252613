import React from 'react'
import PT from 'prop-types'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { Text, Colors } from 'ui-kit'
import styles from './LinkButton.module.scss'

const LinkButton = ({ label, width, className, onClick, color, to, external }) => {
  const classes = cx(styles.linkButton, className, {
    [styles.primary]: color === Colors.PRIMARY,
    [styles.secondary]: color === Colors.SECONDARY,
  })

  if (external) {
    return (
      <a
        className={classes}
        href={to}
        onClick={onClick}
        rel="noreferrer"
        style={{ width }}
        target="_blank"
      >
        <Text semiBold>{label}</Text>
      </a>
    )
  }

  return (
    <Link className={classes} onClick={onClick} style={{ width }} to={to}>
      <Text>{label}</Text>
    </Link>
  )
}

LinkButton.propTypes = {
  label: PT.string.isRequired,
  color: PT.oneOf([Colors.PRIMARY, Colors.SECONDARY]),
  className: PT.string,
  onClick: PT.func,
  width: PT.string,
  to: PT.string.isRequired,
  external: PT.bool,
}

LinkButton.defaultProps = {
  color: Colors.SECONDARY,
  className: '',
  onClick: null,
  width: null,
  external: false,
}

export default LinkButton
