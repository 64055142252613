import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { LandingDataContext } from 'contexts'
import { useToaster } from 'hooks/useToaster'
import {
  Flex,
  Text,
  Section,
  Image,
  Colors,
} from 'ui-kit'
import LinkButton from '../components/LinkButton'
import styles from './OurMission.module.scss'

const OurMission = () => {
  const { t } = useTranslation()
  const { requestFormEnabled } = useContext(LandingDataContext)
  const { showToaster, toasterTypes } = useToaster(15000)

  const handleRequestFormLinkClick = (e) => {
    if (requestFormEnabled === false) {
      e.preventDefault()
      showToaster({
        content: (
          <>
            <Text color={Colors.LIGHT} paragraph>{t('landing.toast.requestFormDisabled.1')}</Text>
            <Text color={Colors.LIGHT} paragraph>{t('landing.toast.requestFormDisabled.2')}</Text>
            <Text color={Colors.LIGHT} paragraph>{t('landing.toast.requestFormDisabled.3')}</Text>
          </>
        ),
        type: toasterTypes.DANGER,
      })
    }
  }

  return (
    <Section id="our-mission" style={{ marginTop: '7rem' }} tag="article">
      <Flex alignItems="center" className={styles.ourMission} justifyContent="between">
        <Flex className={styles.text} justifyContent="start" column>
          <h2>{t('landing.sectionsTitle.ourMission')}</h2>
          <Text landingText paragraph>
            {t('landing.sectionsText.ourMission.1')}
          </Text>
          <Text landingText paragraph>
            {t('landing.sectionsText.ourMission.2')}
          </Text>
          <Text className="mb-5" landingText paragraph>
            {t('landing.sectionsText.ourMission.3')}
          </Text>
          <LinkButton
            className={styles.action}
            color={Colors.PRIMARY}
            label={t('landing.requestFormButton')}
            onClick={handleRequestFormLinkClick}
            to="/request-form"
          />
          <LinkButton className={styles.action} label={t('landing.donateButton')} to="/donate" />
        </Flex>
        <div className={styles.logo}>
          <Image alt="logo" src="https://assets.armiyavolonteriv.com/production/main/main_logo.png" />
        </div>
      </Flex>
    </Section>
  )
}

export default OurMission
