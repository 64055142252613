import React, { useEffect, useState } from 'react'
import PT from 'prop-types'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { range } from 'lodash'
import { useQueryParams } from 'hooks/useQueryParams'
import { Icon, Colors, Text } from '../../index'
import classes from './Pagination.module.scss'

const Pagination = ({
  totalCount,
  totalPages,
  className,
  alignment,
  from,
  to,
  withResults,
  withPerPage,
  loading,
}) => {
  const { t } = useTranslation()
  const { queryParams, setQueryParams } = useQueryParams()
  const currentPage = parseInt(queryParams.page) || 1
  const currentPerPage = parseInt(queryParams.perPage) || 10

  const handlePageChange = (pageNum) => {
    setQueryParams({ page: pageNum })
  }

  const handlePerPageChange = (pageNum) => {
    setQueryParams({ perPage: pageNum })
  }

  const containerClassnames = cx(
    classes.pagination, {
      [classes.paginationWithResults]: withResults,
      [classes.paginationAlignLeft]: !withResults && alignment === 'left',
      [classes.paginationAlignCenter]: !withResults && alignment === 'center',
      [classes.paginationAlignRight]: !withResults && alignment === 'right',
      [className]: !!className,
    },
  )

  const arrowButtonClassnames = (arrowSide, disabled) => cx(
    classes.arrowButton, {
      [classes.arrowButtonLeft]: arrowSide === 'left',
      [classes.arrowButtonRight]: arrowSide === 'right',
      [classes.disabled]: loading || disabled,
    },
  )

  const pageCellClassnames = (currentPage) => cx(
    classes.pageCell, {
      [classes.currentPage]: currentPage,
      [classes.disabled]: loading,
    },
  )

  const [displayedPages, setDisplayedPages] = useState(range(1,8))

  useEffect(() => {
    if (totalPages === 0 || totalPages === 1) {
      setDisplayedPages(range(1,0))
    } else if (currentPage <= 4) {
      const lastPageNumber = (totalPages > 8) ? 8 : totalPages
      setDisplayedPages(range(1, lastPageNumber + 1))
    } else if (currentPage > 4 && currentPage < (totalPages - 3)) {
      setDisplayedPages(range(currentPage - 3, currentPage + 5))
    } else {
      const firstPageNumber = (totalPages >= currentPage && totalPages >= 8) ? totalPages - 7 : 1
      setDisplayedPages(range(firstPageNumber, totalPages + 1))
    }
  }, [currentPage, totalPages])

  const renderResultsInfo = () => {
    if (!withResults) return null

    return(
      <Text paragraph>
        <Text capitalize>{t('pagination.showing')}</Text>
        <Text bold>{` ${from} `}</Text>
        <Text>{t('pagination.to')}</Text>
        <Text bold>{` ${to} `}</Text>
        <Text>{t('pagination.of')}</Text>
        <Text bold>{` ${totalCount} `}</Text>
        <Text>{t('pagination.results')}</Text>
      </Text>
    )
  }

  const renderPerPage = () => {
    if (!withPerPage) return null

    return (
      <div className={cx(classes.navContainer, 'my-3')}>
        {[10,25,50,100].map((perPageNumber) => (
          <div
            className={pageCellClassnames(currentPerPage === perPageNumber)}
            key={perPageNumber}
            onClick={() => handlePerPageChange(perPageNumber)}
          >
            {perPageNumber}
          </div>
        ))}
      </div>
    )
  }

  return(
    <div className={containerClassnames}>
      {renderResultsInfo()}
      {renderPerPage()}
      <div aria-label="Pagination" className={classes.navContainer}>
        <div
          className={arrowButtonClassnames('left', currentPage === 1)}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <Icon color={Colors.PRIMARY} name="chevronLeft" />
        </div>

        {displayedPages.map((pageNumber) => (
          <div
            className={pageCellClassnames(currentPage === pageNumber)}
            key={pageNumber}
            onClick={() => handlePageChange(pageNumber)}
          >
            {pageNumber}
          </div>
        ))}

        <div
          className={arrowButtonClassnames('right', currentPage === totalPages)}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <Icon color={Colors.PRIMARY} name="chevronRight" />
        </div>
      </div>
    </div>
  )
}

Pagination.propTypes = {
  alignment: PT.oneOf(['left', 'center', 'right']),
  className: PT.string,
  from: PT.number,
  loading: PT.bool,
  to: PT.number,
  totalPages: PT.number.isRequired,
  totalCount: PT.number.isRequired,
  withPerPage: PT.bool,
  withResults: PT.bool,
}

Pagination.defaultProps = {
  alignment: 'left',
  className: '',
  from: 0,
  loading: false,
  to: 0,
  withPerPage: false,
  withResults: false,
}

export default Pagination
