import React from 'react'
import { useTranslation } from 'react-i18next'
import { Section } from 'ui-kit'
import { ourPartners } from '../constants/ourPartners'
import PartnerLogo from '../components/PartnerLogo'
import styles from './OurPartners.module.scss'

const OurPartners = () => {
  const { t } = useTranslation()

  return (
    <Section id="our-partners">
      <h2>{t('landing.sectionsTitle.ourPartners')}</h2>
      <div className={styles.ourPartners}>
        {ourPartners.map(({ alt, src }) => (
          <PartnerLogo alt={alt} key={alt} src={src} />
        ))}
      </div>
    </Section>
  )
}

export default OurPartners
