import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import {
  Table as ReactTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from 'react-super-responsive-table'

import { useQueryParams } from 'hooks/useQueryParams'
import { parseSortString, makeSortString } from 'common/utils/sorting'

import {
  Colors,
  Sizes,
  Icon,
  Text,
  LoadingSpinner,
} from '../../index'

const Table = ({ titles, data, loading }) => {
  const { queryParams, setQueryParams } = useQueryParams()
  const sort = queryParams.sort || ''

  const renderSortIcon = (sorting, name) => {
    if (!sorting) return null

    const [currBy, currDir] = parseSortString(sort)

    if (currBy !== name) return null

    return (
      <Icon
        color={Colors.DARK}
        name={currDir === 'asc' ? 'chevronUp' : 'chevronDown'}
        size={Sizes.SMALL}
      />
    )
  }

  const handleSorting = (name, sorting) => {
    if (!sorting) return null

    const sortString = makeSortString(sort, name)
    setQueryParams({ sort: sortString })
  }

  const renderTitles = () => {
    return (
      <Tr>
        {titles.map(({ name, label, sorting }) => (
          <Th
            key={name}
            onClick={() => handleSorting(name, sorting)}
            style={{
              cursor: sorting ? 'pointer' : 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            <Text className="me-2">{label}</Text>
            {renderSortIcon(sorting, name)}
          </Th>
        ))}
      </Tr>
    )
  }

  const renderBody = () => {
    if (loading) {
      return (
        <Tr>
          <Td colSpan="7">
            <div className="my-5">
              <LoadingSpinner />
            </div>
          </Td>
        </Tr>
      )
    } else {
      return data.map(({ id, cells, onClick }) => (
        <Tr className={onClick ? 'cursor-pointer': ''} key={id} onClick={onClick}>
          {cells.map(({ name, content, width }) => (
            <Td className={cx({ ['action']: name === 'actions' })} key={name} style={{ width }}>
              {content}
            </Td>
          ))}
        </Tr>
      ))
    }
  }

  return (
    <ReactTable className="shadow rounded overflow-hidden">
      <Thead>
        {renderTitles()}
      </Thead>
      <Tbody>
        {renderBody()}
      </Tbody>
    </ReactTable>
  )
}

Table.propTypes = {
  titles: PT.arrayOf(PT.shape({
    name: PT.string,
    label: PT.oneOfType([PT.string, PT.element]),
    sorting: PT.bool,
  })).isRequired,
  data: PT.arrayOf(PT.shape({
    id: PT.string,
    cells: PT.arrayOf(PT.shape({
      name: PT.string,
      content: PT.oneOfType([PT.string, PT.element]),
      width: PT.string,
    })),
    onClick: PT.func,
  })),
  loading: PT.bool,
}

Table.defaultProps = {
  data: [],
  loading: false,
}

export default Table
