import React from 'react'
import PT from 'prop-types'
import DropdownMenu from 'rc-dropdown'
import { Flex, Text, Sizes, Colors, Icon } from '../../index'
import styles from './Dropdown.module.scss'
import 'rc-dropdown/assets/index.css'

const Dropdown = ({ options, children, placement, className, style }) => {

  const menu = <Flex className={styles.menu} column>{options.map(({ key, title, handler, icon }) => (
    <button
      className={styles.button}
      key={key}
      onClick={handler}
      type="button"
    >
      <Flex alignItems="center" justifyContent="start">
        {icon && <Icon className="me-2" color={Colors.DARK} name={icon} />}
        <Text size={Sizes.LARGE}>{title}</Text>
      </Flex>
    </button>
  ))}</Flex>


  return (
    <DropdownMenu
      animation="slide-up"
      className={className}
      overlay={menu}
      placement={placement}
      style={style}
      trigger={['click']}
    >
      {children}
    </DropdownMenu>
  )
}

Dropdown.propTypes = {
  children: PT.node.isRequired,
  options: PT.array.isRequired,
  placement: PT.oneOf([
    'topCenter',
    'bottomCenter',
    'topLeft',
    'topRight',
    'bottomLeft',
    'bottomRight',
  ]),
  className: PT.string,
  style: PT.shape({}),
}

Dropdown.defaultProps = {
  placement: 'bottomRight',
  className: '',
  style: {},
}

export default Dropdown
