import React from 'react'
import PT from 'prop-types'
import { Container, Flex } from 'ui-kit'
import LinkButton from './LinkButton'
import styles from './DonateCard.module.scss'

const DonateCard = ({ title, link, image, buttonLabel }) => {
  return (
    <Container className={styles.donateCard}>
      <img
        alt={title}
        className="mb-2"
        src={image}
      />
      <Flex grow={1} column>
        <h5 className="mb-3">{title}</h5>
        <LinkButton label={buttonLabel} to={link} external />
      </Flex>
    </Container>
  )
}

DonateCard.propTypes = {
  title: PT.string.isRequired,
  link: PT.string.isRequired,
  image: PT.string.isRequired,
  buttonLabel: PT.string.isRequired,
}

export default DonateCard
